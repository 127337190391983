import { Form, Input, message } from "antd";
import { FC, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { generateRandomDigits } from "../../../../../helpers";
import { DealsContext } from "../../../../app/context/DealsContext";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

interface AddColumnModalProps {
  onClose: () => void;
  setData: () => void;
  colAmnt: number;
}

export const AddColumnModal: FC<AddColumnModalProps> = ({ onClose, colAmnt, setData, saveUpd, deals }) => {
  const { addColumn } = useContext(DealsContext);
  const [form] = Form.useForm();

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then((values) => {
        const newGroup = {
          id: uuidv4(),
          title: values.name,
          order: colAmnt + 1,
          totalPrice: 0,
          deals: [],
        };
        setData([...deals, newGroup]);
        saveUpd([...deals, newGroup]);
        message.success("Added column");
        onClose();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  return (
    // <div style={{ position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", zIndex: 150000 }}>
    <Modal open={true} title={"Add Column"} onCancel={onClose} rootClassName={"add-column-modal"} style={{ zIndex: "99999999 !important" }}>
      <Form form={form} layout={"vertical"} requiredMark={false} className={"add-column-form"}>
        <Form.Item
          name={"name"}
          label={"Name"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={"Enter Text"} />
        </Form.Item>
      </Form>
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Add"} className={"confirm-buttons"} handleSubmit={handleSubmitBtnClick} onCancel={onClose} />
    </Modal>
    // </div>
  );
};
