import { Button, Divider, Flex, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import { TabsSwitch } from "src/components/ui";
import { InfoItem } from "../InfoItem";
import tz from "moment-timezone";
import DOMPurify from "dompurify";

export const LinkedInCardContent: FC<{ data: any }> = ({ data }) => {
  const [activeTab, setActiveTab] = useState("LinkedIn Feed");
  const [linkedinInfo, setLinkedinInfo] = useState(null);
  const [linkedinActivity, setLinkedinActivity] = useState(null);
  const localTimezone = tz?.tz?.guess();

  useEffect(() => {
    let setActTab = `LinkedIn Feed`;

    const linkedinInfo = data?.linkedinInfo;
    if (linkedinInfo?.first_name?.length) {
      setLinkedinInfo(linkedinInfo);
      setActTab = `LinkedIn Bio`;
    } else {
      setLinkedinInfo(null);
    }
    const linkedinActivity = data?.linkedinActivity;
    if (linkedinActivity?.length) {
      setLinkedinActivity(linkedinActivity);
      setActTab = `LinkedIn Feed`;
    } else {
      setLinkedinActivity(null);
    }
    setActiveTab(setActTab);
  }, [data]);
  // console.log(activeTab, "activeTab");
  return (
    <Flex vertical>
      {!!linkedinInfo && !!linkedinActivity && (
        <TabsSwitch
          firstTab={"LinkedIn Feed"}
          secondTab={"LinkedIn Bio"}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMediumSize
          style={{ width: "260px" }}
        />
      )}
      {activeTab === "LinkedIn Feed" ? (
        <Flex vertical style={{ height: 500, overflowY: "scroll", marginTop: 16 }}>
          {linkedinActivity?.length &&
            linkedinActivity?.map((item: any, index: number) => {
              // console.log(item.postText, "item.postText");
              return (
                <>
                  <Flex vertical gap={8} key={item.id}>
                    {item?.dateActHappenedSecUnix > 0 && (
                      <InfoItem
                        label={`Date ${item.type === "post" ? "Posted" : "Commented"}`}
                        value={tz.unix(Math.floor(item.dateActHappenedSecUnix))?.tz(localTimezone)?.format("YYYY-MM-DD h:mm:ss A z")}
                      />
                    )}{" "}
                    <InfoItem
                      label={"Action"}
                      value={
                        <>
                          {item.type === "post" ? "Post They Uploaded" : "Commented On A Post"}{" "}
                          {item.postUrl?.length ? (
                            <Button className={"link"} onClick={() => window.open(item.postUrl, "_blank", "noopener,noreferrer")}>
                              <span style={{ color: "black" }}>-</span> {item.type === "post" ? "Post" : "Comment"} link
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      }
                    />
                    {item?.commentOnPost?.length > 0 && <InfoItem label={`Comment Prospect Left On Post`} value={item.commentOnPost} />}
                    {item.type !== "post" ? (
                      <InfoItem label={"Person Who Posted"} value={item.postAuth === "themselves" ? `It's their own post` : item.postAuth} />
                    ) : (
                      ""
                    )}
                    <InfoItem
                      label={"Post Text"}
                      value={
                        item.postText
                        // <div>
                        //   {" "}
                        //   <div
                        //     dangerouslySetInnerHTML={{
                        //       __html: DOMPurify.sanitize(item.postText),
                        //     }}
                        //   />
                        // </div>
                      }
                    />
                  </Flex>
                  {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />}
                </>
              );
            })}
        </Flex>
      ) : (
        <Flex vertical gap={8} style={{ height: 500, overflowY: "scroll", marginTop: 16 }}>
          <InfoItem label={"Title"} value={linkedinInfo?.sub_title} />
          <InfoItem label={"Bio Summary"} value={linkedinInfo?.summary} />
          {!!linkedinInfo?.default?.length && <InfoItem label={"Location"} value={linkedinInfo?.default} />}
          <br /> <Divider style={{ margin: "12px 0" }} />
          <Typography.Paragraph style={{ color: "#2E2E2E", fontSize: 12 }}>List Of Job Positions</Typography.Paragraph>
          {/* <InfoItem label={"Company"} value={linkedinInfo?.company} />
          <InfoItem label={"Start Date"} value={linkedinInfo?.startDate} /> */}
          <InfoItem
            label={"Positions"}
            value={
              <>
                {linkedinInfo?.position_groups?.map((pg) => {
                  if (pg?.company?.name?.length) {
                    return (
                      <>
                        Company: {pg?.company?.name}
                        <br />
                        Job Title:{" "}
                        {pg?.profile_positions?.[0]?.title ? (
                          <>
                            {" "}
                            {pg?.profile_positions?.[0]?.title} <br />
                            Time In Position: {pg?.profile_positions?.[0]?.date?.start?.month || ""} {pg?.profile_positions?.[0]?.date?.start?.year || ""} -
                            {pg?.profile_positions?.[0]?.date?.end?.month || ""} {pg?.profile_positions?.[0]?.date?.end?.year || "Present"}
                          </>
                        ) : (
                          "-"
                        )}{" "}
                        {}
                        <br />
                        <br />
                      </>
                    );
                  }
                })}
              </>
            }
          />
        </Flex>
      )}
    </Flex>
  );
};
