/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React, { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from 'react-router-dom';
// import tz from 'moment-timezone';
// import { fetchStripeCustomer, fetchPlans } from 'store/stripe/asyncRequests';
// import { patchSettings, getTimezones } from 'store/settings/asyncRequests';
// import { stripeSelector } from 'store/stripe';
// import { getUser } from 'store/user/asyncRequests';
import tz from "moment-timezone";
import { setJwtIframeInHelper } from "src/store/helpers";
import { fullUserSelector, logout, userSelector } from "src/store/user";
// import Spinner from 'components/Spinner';
// import { ACCOUNT_STATUS, TUTORIAL, SUBSCRIPTION_INTERVAL } from 'config/constants';
// import { SIDEBAR_ROUTE_NAMES } from 'router/RoutesData';
// import { toCheckoutChecker } from 'config/util';
// import { subscriptionTypeSelector } from 'store/subscription-type';
// import { getAiSettings } from 'store/ai_sales_email_settings/asyncRequests';
// import { getDialer, stopMultiDialingActive, getTwilioVoiceToken } from 'store/dialer/asyncRequests';
import { AppDispatch } from "src/store";
import { getUser, gettimezones, initiateAndGetAllUserInfo, patchSettings, patchUser } from "src/store/user/asyncRequests";
import { Loader } from "../layout";
import { addOrReplaceSpotify } from "src/store/dialer/asyncRequests";
import { message } from "antd";
import { useLocation } from "react-router";

export function removeQueryFromUrl(key) {
  const url = new URL(window?.location?.href);
  url.searchParams.delete(key);
  window.history.pushState({}, "", url);
}
export function findLocation() {
  const pathname = window.location.pathname;
  const queryString = pathname.includes("/") ? pathname.split("/")[1] : "";

  const queryParams = useMemo(() => {
    // Check if the query string is empty
    if (!queryString) {
      return "";
    }

    return queryString;
  }, [queryString]);

  return queryParams;
}

export function useQueryParams() {
  const queryString = window.location.search;

  const queryParams = useMemo(() => {
    // Check if the query string is empty
    if (!queryString) {
      return "";
    }

    // Remove the "?" from the query string
    const queryParamsString = queryString.substring(1);

    return new URLSearchParams(queryParamsString);
  }, [queryString]);

  return queryParams;
}

interface AuthRoutesProps {
  children: ReactNode;
}

// we don't redirect these paths if jwt is not there. exception is blank path (we check if we have a cookie for a calendar since when guests connect their cal they go back to empty path and we send them to that cal)
const whitelistedPaths = ["login", "signup", "cal", "emailconfirmed", "unsubscribe", "do_not_sell_my_info", "unsubscribe", "verifyemail"];

const AuthRoutes: FC<AuthRoutesProps> = ({ children }) => {
  // console.log(childrenArray);
  const dispatch = useDispatch<AppDispatch>();
  // const { pathname } = useLocation();
  const loc = findLocation()?.toLowerCase();
  const timezone = tz.tz.guess();
  const query = useQueryParams();
  // const { pathname, search } = useLocation();
  // console.log(loc,query,'timezonetimezone2');

  // MEETING LINK INTEGRATIONS
  // useEffect(() => {
  // if (loc.match('zoom/')) {
  //   if (search?.match('/?error')) {
  //     let value = query.get('error');
  //     if (!value || value === 'undefined') {
  //       value = 'Failed Zoom authorization';
  //     }
  //     window.location.href = `/meeting-links?connPlatformError=zoom`;
  //     return;
  //   }
  //   window.location.href = `/meeting-links?connPlatformInfo=zoom`;
  //   const data = JSON.parse(pathname.replace('/zoom/', ''));
  //   dispatch(zoomIntegrate(data));
  // }
  // if (pathname.match('teams')) {
  //   if (pathname.includes('noaccess')) {
  //      window.location.href = `/meeting-links?connPlatformError=teamnoaccess`;
  //     return;
  //   }
  //   if (pathname.includes('teamserror')) {
  //    window.location.href = `/meeting-links?connPlatformError=teamserror`;
  //     return;
  //   }
  //   if (pathname.includes('teamssuccess')) {
  //    window.location.href = `/meeting-links?connPlatformInfo=teams`;
  //   }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // MEETING LINK INTEGRATIONS

  // console.log(loc, "loc")
  // console.log(query, "query")
  // const timezone = tz.tz.guess();
  const { user, loading, error, timezones } = useSelector(fullUserSelector);
  const { id, marked_as_registered, new_account, is_show_tutorial } = useSelector(userSelector);

  useEffect(() => {
    if (!marked_as_registered && id) {
      dispatch(patchUser({ marked_as_registered: true }));
    }
  }, [id]);

  useEffect(() => {
    if (new_account) {
      // axios.post()
      dispatch(
        patchSettings({
          timezone,
        })
      );
    }
  }, [new_account]);
  if (loc !== "cal" && loc !== "EmailConfirmed" && loc !== "unsubscribe") {
    // console.log(loc, "locaddedintercom");
    if (user?.email) {
      // ignore-ts
      (window as any).Intercom("boot", {
        app_id: "eodlisfw",
        email: user.email,
      });
    } else {
      // ignore-ts
      (window as any).Intercom("boot", {
        app_id: "eodlisfw",
      });
    }
  } else {
    // console.log(loc, "locaddedintercom removed");
    (window as any).Intercom("shutdown");
  }

  const [jwtIframe, setJwtIframe] = useState("");
  const [gotJwtFromUrl, setGotJwtFromUrl] = useState(false);
  const isIframe = window.self !== window.top;
  useEffect(() => {
    if (isIframe) {
      window.addEventListener("message", async (event) => {
        // Check the origin if necessary, here assuming it's from a trusted parent

        if (event?.data?.type === "TOKEN_RESPONSE") {
          setJwtIframe(event?.data?.token);
        }
      });
      window.parent.postMessage({ type: "REQUEST_TOKEN", tokenName: "token" }, "*");
    }
  }, []);

  useEffect(() => {
    if (typeof query !== "string") {
      if (query?.get("spotifyConnected")) {
        dispatch(addOrReplaceSpotify({ spotifyId: query?.get("spotifyConnected") }));
        removeQueryFromUrl("spotifyConnected");

        message.destroy();
        message.success("Successfully Connected Your Spotify");
      }
    }
  }, [query]);

  useEffect(() => {
    let iframe: boolean | string | null = false,
      jwt;
    if (typeof query !== "string") {
      iframe = query?.get("iframe");
      jwt = query?.get("jwt");
      if (jwt) {
        setGotJwtFromUrl(true);
      }
    }
    if (!jwt) {
      jwt = jwtIframe || localStorage.getItem("token");
    }

    if (!jwt && isIframe) {
      return;
    }

    // console.log(jwt, "jwt", isIframe)
    if (jwt) {
      if (!isIframe) {
        localStorage.setItem("token", jwt);
      } else {
        dispatch(setJwtIframeInHelper(jwt));
      }
      if (iframe === "true") {
        window.location.href = `https://marketing.deal.ai/apps/app/meetz?appTokenName=token&appTokenValue=${jwt}`;
      }

      // dispatch(getUser());
      // dispatch(initiateAndGetAllUserInfo());

      if (loc === "login" || loc === "signup") {
        window.location.href = `/dashboard`;
      }
    } else if (!whitelistedPaths.includes(loc)) {
      window.location.href = `/login`;
    }
    if (!timezones?.length) {
      dispatch(gettimezones());
    }
    // dispatch(getAiSettings());
    // dispatch(getDialer());
    // dispatch(fetchPlans());
    // dispatch(stopMultiDialingActive());
    // eslint-disable-next-line
  }, [jwtIframe, loc]);

  // if (loc === '/') {
  useEffect(() => {
    if (gotJwtFromUrl && !isIframe && loc !== "cal" && loc !== "EmailConfirmed") {
      // window.history.pushState({}, "", "/dashboard");
      window.location.href = "/dashboard";
    }
  }, [gotJwtFromUrl]);
  useEffect(() => {
    if (loc && !user?.id && !whitelistedPaths.includes(loc)) {
      dispatch(getUser());
      dispatch(initiateAndGetAllUserInfo({ tz: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone }));
    }
  }, [loc]);

  useEffect(() => {
    // if (error) {
    //   return;
    // }
    if (error && loc !== "cal" && loc !== "EmailConfirmed") {
      // KEEP ON!!!!!
      console.log(error, "errorerror");
      console.log("Unhandled promise rejection2:", error);
      dispatch(logout({}));
      window.location.href = "/login";
    }
    // eslint-disable-next-line
  }, [error]);

  return <>{children}</>;
};

export default AuthRoutes;
