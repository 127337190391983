import React, { FC, useEffect, useState } from "react";
import { CreateCampaignMethodSelect } from "src/components/pages/campaigns/ui/CreateCampaignModal/ui/CreateCampaignMethodSelect";
import { DashboardCard } from "../ui/DashboardCard";
import { ActionsDataCard, ActivateCampaignList, ErrorCampaignList } from "./ActionsDataCard";
import "./index.less";
import { Loader } from "src/components/ui";
import { useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import manWithSkateboard from "src/shared/images/manWithSkateboard.webp";
import { manWithLaptop } from "src/shared/images";

interface ActionsToTakeCardProps {
  outreachCampaignsCnt?: any;
}

export const ActionsToTakeCard: FC<ActionsToTakeCardProps> = ({ outreachCampaignsCnt }) => {
  // const isLoading = outreachCampaignsCnt?.length;
  // const isLoading = outreachCampaignsCnt?.length;
  const { outreachCampaignsList } = useSelector(outreachCampaignSelector);
  const [isLoading, setLoading] = useState(true);
  const [hasCampaigns, setHasCampaigns] = useState(false);
  const [hasActCampaigns, setHasActCampaigns] = useState(false);
  const [hasErrCampaigns, setHasErrCampaigns] = useState(false);

  // TODOF later add dialer actions they need to take.

  // TODON check for errors in their campaigns otherwise an image + add image from not reg for SMTP

  useEffect(() => {
    setLoading(!outreachCampaignsCnt?.length);
    if (outreachCampaignsCnt?.length === 2) {
      setHasCampaigns(!!outreachCampaignsCnt[0]?.value);
      setHasActCampaigns(!!outreachCampaignsCnt[1]?.value);
    } else {
      setHasCampaigns(false);
      setHasActCampaigns(false);
    }
  }, [outreachCampaignsCnt]);

  useEffect(() => {
    let hasError = false;
    (outreachCampaignsList || [])?.forEach((cmp) => {
      // console.log(cmp, "CMPCMP");
      if (!hasError && !!cmp?.overview?.isActive && cmp?.emailDisconnectedData?.length) {
        hasError = true;
      }
    });
    setHasErrCampaigns(hasError);
  }, [outreachCampaignsList]);

  return (
    <DashboardCard
      title={"Actions To Take:"}
      subtitle={
        isLoading
          ? "Checking what you need to do now..."
          : !hasCampaigns
          ? "Create an outreach campaign"
          : !hasActCampaigns
          ? ""
          : hasErrCampaigns
          ? "One of your campaigns needs to have the email reconnected"
          : "No action to take today, your campaigns are running smoothly"
      }
      customClassName={"actions-to-take-wrapper"}
    >
      {isLoading ? (
        <div style={{ height: "100px" }}>
          {" "}
          <Loader size="large" style={{ marginTop: "50px" }} />
        </div>
      ) : !hasCampaigns ? (
        <CreateCampaignMethodSelect isModalContent={false} />
      ) : !hasActCampaigns ? (
        <ActivateCampaignList />
      ) : hasErrCampaigns ? (
        <ErrorCampaignList />
      ) : (
        <div style={{ margin: "auto" }}>
          <img src={manWithSkateboard} alt={"No actions"} width={120} />
        </div>
      )}
      {/* activate campaign or create campaign */}
    </DashboardCard>
  );
};
