import { Avatar, Button, Dropdown, Empty, Flex, Image, Input, MenuProps, Progress, Row, Typography } from "antd";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SvgIcon } from "../../shared/icons";
import { ConfirmButtons, CustomSelect, Loader } from "../ui";
import { debounce } from "lodash";
import noData from "src/shared/images/noData.png";
// import { AssistantSettingsDrawer } from "./AssistantSettingsDrawer"
import { DialerModal, NotificationDropdown, PlayerDropdown } from "./ui";
import { ViewModal } from "./ui/ViewModal";
import "./DefaultHeader.less";
import { CommonContext } from "../app/context/CommonContext";
import { ConnectSpotifyModal } from "../pages/phone-calls";
import { fullUserSelector, logout, setSkipTrial, userSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { ActivateForAccessModal } from "../pages/prospect-data";
import { changeIsSelItemTopSearch, openPopUp, outreachPhonesSelector, resetProsSearchTopSearch } from "src/store/outreach-phones";
import { manualDialerProsSearchTopSearch } from "src/store/outreach-phones/asyncRequests";
import { getFilteredLeadsRst } from "src/store/outreach-campaigns/asyncRequests";
import { setDealToOpenId } from "src/store/outreach-campaigns";
import { ProspectPopup } from "./ProspectPopup";

const initialCreditData = {
  prospect: {
    left: 209,
    total: 250,
  },
  emails: {
    left: 242,
    total: 250,
  },
  isTrial: false,
};

export const DefaultHeader: FC = () => {
  const { loadingProsTopSearch, searchedProsTopSearch, basicPopupInfo } = useSelector(outreachPhonesSelector);

  const { currentView } = useContext(CommonContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [openDialerModal, setOpenDialerModal] = useState(false);
  const [isViewModalOpened, setIsViewModalOpened] = useState(false);
  const [creditsData, setCreditsData] = useState(initialCreditData);
  let {
    first_name,
    icon,
    split_between_prosp_to_personalize,
    new_prospectdata_or_personalized_email_permonth,
    ai_personalized_emails_left,
    prospect_data_credits,
    new_payment_status,
    trial_credits,
  } = useSelector(userSelector);
  const { phone_numbers } = useSelector(outreachPhonesSelector);
  let { openActivateModalTrialSkip } = useSelector(fullUserSelector);
  // console.log(openActivateModalTrialSkip, "openActivateModalTrialSkip");
  const closeSkipModal = () => {
    dispatch(setSkipTrial(false));
  };
  const [openMan, setOpenMan] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const isTrial = new_payment_status === "trial";
  if (isTrial) {
    const credUsedAi = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - ai_personalized_emails_left;
    ai_personalized_emails_left = trial_credits - credUsedAi;
    if (ai_personalized_emails_left < 0) {
      ai_personalized_emails_left = 0;
    }
    const credUsed = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - prospect_data_credits;
    prospect_data_credits = trial_credits - credUsed;
    if (prospect_data_credits < 0) {
      prospect_data_credits = 0;
    }
    // keep this at end
    new_prospectdata_or_personalized_email_permonth = trial_credits * 2;
  }
  const [value, setValue] = useState("None"); // needs none to render the search if nothing was selected
  const [emptyToLoad, setEmptyToLoad] = useState(true);
  useEffect(() => {
    if (loadingProsTopSearch && searchQuery?.trim()?.length) {
      setEmptyToLoad(false);
    }
    if (!searchQuery?.trim()?.length) {
      setEmptyToLoad(true);
    }
  }, [loadingProsTopSearch, searchQuery]);
  const debouncedSearch = useCallback(
    debounce((search) => {
      if (search?.trim()?.length) {
        // console.log("manualDialerProsSearchTopSearch", search);
        dispatch(manualDialerProsSearchTopSearch({ search }));
      }
    }, 1500),
    []
  );

  // console.log(searchedProsTopSearch, "searchedProsTopSearch");
  const computedOptions = useMemo(() => {
    if (!searchQuery?.trim()?.length) return [];

    let options: any[] = [];

    if (loadingProsTopSearch) {
      options.push({
        label: (
          <Flex key={999}>
            <div style={{ marginRight: "20px" }}> Loading</div> <Loader size="small" />
          </Flex>
        ),
        value: "_",
      });
    }

    if (searchedProsTopSearch?.length) {
      options = [
        ...options,
        ...searchedProsTopSearch
          .filter(Boolean)
          .slice(0, 5)
          .map((item, i) =>
            item?.campaign_name?.length
              ? {
                  label: `{{ignore_start}} <div><div style={{ fontWeight: "600", fontSize: "16px", color: "#333"}}><b>Outreach campaign</b><br /> Name:  {{ignore_end}} ${
                    item.campaign_name || ""
                  } ${item.last_name || ""} {{ignore_start}} </div> 
              </div> {{ignore_end}} `,
                  value: `cm:${item?.id}`,
                }
              : item?.email?.length
              ? {
                  label: `{{ignore_start}} <div><b>Prospect</b><br /><div style={{ fontWeight: "600", fontSize: "16px", color: "#333"}}>Name:  {{ignore_end}} ${
                    item.first_name || ""
                  } ${item.last_name || ""} {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}>  {{ignore_end}}  ${
                    !!item.email ? ` {{ignore_start}} Email: {{ignore_end}} ${item.email}` : ""
                  } {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}> {{ignore_end}} ${
                    !!item.company_name ? ` {{ignore_start}} Company: {{ignore_end}} ${item.company_name}` : ""
                  } {{ignore_start}} </div><div style={{ fontSize: "14px", color: "#555" }}> {{ignore_end}} ${
                    !!item.title ? ` {{ignore_start}} Job Title: {{ignore_end}} ${item.title}` : ""
                  } {{ignore_start}} </div>
              <div style={{ fontSize: "14px", color: "#555" }}>
              Phone numbers available: ${!item?.mobile_phone && !item?.direct_phone && !item?.business_number ? `None` : ""}${
                    !!item?.mobile_phone ? `Mobile ` : ""
                  }${!!item?.direct_phone ? (!!item?.mobile_phone ? `+ Direct ` : `Direct `) : ""}${
                    !!item?.business_number ? (!!item?.direct_phone || !!item?.mobile_phone ? `+ Business ` : `Business`) : ""
                  }
              </div>
              </div> {{ignore_end}} `,
                  value: `pr:${item?.id}`,
                }
              : {
                  label: `{{ignore_start}} <div><b>Deals</b><br /><div style={{ fontWeight: "600", fontSize: "16px", color: "#333"}}>Name:  {{ignore_end}} ${
                    item.title || ""
                  }  {{ignore_start}}   
              </div>
              </div> {{ignore_end}} `,
                  value: `dl:${item?.id}`,
                }
          ),
      ];
    }

    return options;
  }, [searchQuery, loadingProsTopSearch, searchedProsTopSearch]);
  const navigate = useNavigate();
  const handleChange = (valueToUpd) => {
    // console.log(e, "handleChangehandleChange");
    // const value = e.target.value;
    // setSelectedPhone(null);
    let val = valueToUpd?.[valueToUpd?.length - 1];
    if (val === "_") {
      val = "None";
      dispatch(changeIsSelItemTopSearch({}));
    } else if (val?.length && val !== "None") {
      setIsDropdownOpen(false);
      setOpenMan(false);
      if (val.startsWith("pr")) {
        val = val.substring(3);
        // console.log(val, "valval");
        const selectedProspect = searchedProsTopSearch?.find((pros) => pros?.id === +val);

        // console.log(
        //   selectedProspect,
        //   "valval,selectedProspecthttps://www.loom.com/share/ac22921121cc475c9bff6c2e8cbb3eef?sid=116ac316-ae2f-43ff-9314-4ff2031159c6"
        // );

        if (selectedProspect) {
          dispatch(openPopUp(selectedProspect));
        }
      } else if (val.startsWith("dl")) {
        val = val.substring(3);
        dispatch(setDealToOpenId(val));
        navigate(`/deals`);
        // console.log("Deal selected:", val);
      } else if (val.startsWith("cm")) {
        val = val.substring(3);
        dispatch(
          getFilteredLeadsRst({
            uploadName: "All",
            recentlyUpdated: false,
            includeInCampaign: false,
            includeContactedAlready: false,
            page: 1,
            pageChange: false,
            selectedCampaignId: null,
          })
        );
        navigate(`/campaign/${btoa(`${+val + 12000}`)}`);
        // console.log("Campaign selected:", val);
      }

      // handleDropdownVisibility(false);
      // dispatch(changeIsSelItemTopSearch(searchedProsTopSearch?.find((pros) => pros?.id === val) || {}));

      setValue(val);

      // debouncedSearch(value);
    }
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleSearch = (value) => {
    // console.log(value, "handleSearch1");
    if (isDropdownOpen) {
      // console.log(value, "valuevalue1");
      setSearchQuery(value);
      debouncedSearch(value);
      if (!value?.trim()?.length) {
        dispatch(resetProsSearchTopSearch());
      }
    }
  };
  const handleDropdownVisibility = (open) => {
    setIsDropdownOpen(open);
    setOpenMan(open);
  };
  const showDialerModal = () => {
    setOpenDialerModal(true);
  };

  const closeDialerModal = () => {
    setOpenDialerModal(false);
  };

  const showViewModal = () => {
    setIsViewModalOpened(true);
  };
  const closeViewModal = () => {
    setIsViewModalOpened(false);
  };
  const closeProsPopup = () => {
    dispatch(openPopUp({}));
  };
  const suffix = (
    <Row style={{ gap: 4 }}>
      <SvgIcon type={"searchSuffixIcon"} />
      <SvgIcon type={"searchSuffixIconK"} />
    </Row>
  );
  const dispatch = useDispatch<AppDispatch>();

  const items: MenuProps["items"] = [
    // {
    //   label: (
    //     <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
    //       <Typography.Paragraph className={"profile-menu-title"}>Prospect Data Credits</Typography.Paragraph>
    //       <Progress
    //         // there is business logic for a future feature that might come in that explains why percent is complex equation
    //         percent={((prospect_data_credits || 0) / ((1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth)) * 100}
    //         strokeColor={"#01AEEF"}
    //         trailColor={"#EDFCFD"}
    //         showInfo={false}
    //         size={{ width: 210 }}
    //       />
    //       <Flex vertical={true} gap={4}>
    //         <Typography.Paragraph className={"profile-menu-value"}>
    //           {isTrial && `Trial `}Prospect Data Left: {prospect_data_credits || 0}
    //         </Typography.Paragraph>
    //         <Typography.Paragraph className={"profile-menu-value"}>
    //           Total Prospect Data: {(1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth}
    //         </Typography.Paragraph>
    //       </Flex>
    //     </Flex>
    //   ),
    //   key: "0",
    //   style: { pointerEvents: "none" },
    // },
    {
      style: { pointerEvents: "none" },
      label: (
        <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
          <Typography.Paragraph className={"profile-menu-title"}>AI Personalized Emails</Typography.Paragraph>
          <Progress
            percent={
              ((ai_personalized_emails_left || 0) / ((1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth)) * 100
            }
            // percent={((creditsData.emails.total - creditsData.emails.left) * 100) / creditsData.emails.total}
            strokeColor={"#01AEEF"}
            trailColor={"#EDFCFD"}
            showInfo={false}
            size={{ width: 210 }}
          />
          <Flex vertical={true} gap={4}>
            <Typography.Paragraph className={"profile-menu-value"}>
              {isTrial && `Trial `}AI Emails Left: {ai_personalized_emails_left || 0}
            </Typography.Paragraph>
            <Typography.Paragraph className={"profile-menu-value"}>
              Total AI Emails: {(1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth}
            </Typography.Paragraph>
          </Flex>
        </Flex>
      ),
      key: "1",
    },

    !!isTrial && {
      // style: { pointerEvents: "none" },
      label: (
        <Flex vertical>
          {" "}
          {isTrial && (
            <ConfirmButtons
              rightButtonTitle={"Skip trial"}
              handleSubmit={() => dispatch(setSkipTrial(true))}
              className={"skip-trial-wrapper"}
              style={{ margin: "0 0 20px 0" }}
            />
          )}
        </Flex>
      ),
      key: "2",
    },
    // {
    //   label: (
    //     <Flex
    //       align={"center"}
    //       gap={8}
    //       className={"profile-item-wrapper"}
    //       onClick={() => setIsAssistantSettingsDrawerOpened(true)}
    //     >
    //       <SvgIcon type={"settings"} />
    //       <Typography.Paragraph className={"profile-menu-title"}>Assistant Settings</Typography.Paragraph>
    //     </Flex>
    //   ),
    //   key: "2",
    // },
    // { TODOF
    //   label: (
    //     <Flex
    //       align={"center"}
    //       gap={8}
    //       className={"profile-item-wrapper"}
    //       onClick={showViewModal}
    //     >
    //       <SvgIcon type={"view"} width={20} height={20} />
    //       <Typography.Paragraph className={"profile-menu-title"}>View: {currentView}</Typography.Paragraph>
    //     </Flex>
    //   ),
    //   key: "2",
    // },

    {
      label: (
        <Link to={"/settings"}>
          <Flex align={"center"} gap={8} className={"profile-item-wrapper"}>
            <SvgIcon type={"settings"} width={20} height={20} />
            <Typography.Paragraph className={"profile-menu-title"}>Settings</Typography.Paragraph>
          </Flex>
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Flex
          align={"center"}
          gap={8}
          className={"profile-item-wrapper"}
          onClick={() => {
            dispatch(logout({}));
            window.location.href = "/login";
          }}
        >
          <SvgIcon type={"undo"} width={20} height={20} />
          <Typography.Paragraph className={"profile-menu-title"}>Logout</Typography.Paragraph>
        </Flex>
      ),
      key: "5",
    },
  ]?.filter(Boolean);

  return (
    <React.Fragment>
      <Row className={"header-wrapper"} style={{ padding: "20px 40px" }} justify={"space-between"} wrap={false}>
        <div />
        <CustomSelect
          topSearch
          openMan={openMan}
          setOpenMan={setOpenMan}
          placeholder={"Search for name, title, or company..."}
          notFoundContent={
            <Empty
              image={<Image preview={false} src={noData} alt={"No data"} width={100} height={100} />}
              imageStyle={{
                height: 120, // Ensure the image height is consistent with its actual height
                marginTop: "10px",
              }}
              description={
                <span style={{ marginTop: "10px" }}>
                  {!searchQuery?.trim()?.length || emptyToLoad ? "Search prospect, campaign, or deal" : "Nothing found"}
                </span>
              } // Add margin to separate text from the image
            />
          }
          // disabled={activatePhoneCall}
          options={computedOptions}
          placement={"bottomLeft"}
          tagRender={(d) => !isDropdownOpen && <>{searchQuery}</>}
          onChange={handleChange}
          // filterOption={(input, option) => true}
          value={value}
          showSearch
          isSearchSelect
          mode={"multiple"}
          onSearchInComponentToo={handleSearch}
          searchValue={searchQuery}
          forceShowAllOptionsAsOptionsArePrefiltered
          onDropdownVisibleChange={handleDropdownVisibility}
          // disabled
          style={{ width: "350px", marginBottom: "10px" }}
        />
        {/* <Input.Search
          className={"search-input"}
          placeholder={"Search..."}
          allowClear
          prefix={<SvgIcon type={"search"} />}
          // suffix={suffix}
          enterButton={false}
          variant={"borderless"}
        /> */}
        <Row align={"middle"} wrap={false} style={{ gap: 16 }}>
          {!!phone_numbers?.filter((num) => num.is_active)?.length && <SvgIcon type={"phone"} onClick={showDialerModal} style={{ cursor: "pointer" }} />}
          <PlayerDropdown />
          {/* <NotificationDropdown /> */}
          <Row align={"middle"} wrap={false} style={{ gap: 8 }}>
            <Dropdown menu={{ items }} trigger={["click"]} rootClassName={"profile-dropdown"} onOpenChange={(open) => setDropdownOpen(open)}>
              <a onClick={(e) => e.preventDefault()}>
                <Flex align={"center"} gap={8}>
                  <Flex className={"header-avatar-wrapper"} style={{ width: currentView === "Personal" ? 40 : 66 }}>
                    <Avatar
                      className={"header-avatar"}
                      size={"large"}
                      src={icon}
                      // icon={currentView === "Team" ? false : currentView === "Personal" ? <SvgIcon type={"womanAvatar"} /> : <SvgIcon type={"manAvatar"} width={40} height={40} />}
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: currentView === "Team" ? "#FFF" : "#757A891A",
                        border: currentView === "Team" ? "1px solid #01AEEF" : "unset",
                      }}
                    >
                      {currentView === "Team" && "T"}
                    </Avatar>
                    {/*
                    <Avatar
                      className={"header-avatar"}
                      size={"large"}
                      icon={currentView === "Team" ? false : currentView === "Personal" ? <SvgIcon type={"womanAvatar"} /> : <SvgIcon type={"manAvatar"} width={40} height={40} />}
                      style={{ backgroundColor: currentView === "Team" ? "#FFF" : "#757A891A", border: currentView === "Team" ? "1px solid #01AEEF" : "unset"}}
                    >
                      {currentView === "Team" && "T"}
                    </Avatar>
                 
                  {currentView !== "Personal" && (
                      <Avatar className={"header-avatar"} size={"large"} icon={<SvgIcon type={"womanAvatar"} width={40} height={40} />} />
                    )} */}
                  </Flex>
                  <Row align={"middle"} style={{ gap: 6 }}>
                    <Typography.Paragraph className={"profile-username"}>{first_name || "Loading account..."}</Typography.Paragraph>
                    <SvgIcon type={"arrowHeader"} style={{ transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                  </Row>
                </Flex>
              </a>
            </Dropdown>
          </Row>
        </Row>
      </Row>
      <ProspectPopup open={basicPopupInfo?.id} onClose={closeProsPopup} info={basicPopupInfo} />
      {isViewModalOpened && <ViewModal onClose={closeViewModal} />}
      <DialerModal
        open={openDialerModal}
        onCancel={closeDialerModal}
        // prospectFromManDialer={}
      />
      <ActivateForAccessModal open={openActivateModalTrialSkip} onCancel={closeSkipModal} />
    </React.Fragment>
  );
};
