import { message, Button, Checkbox, Flex, Image, Input, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { ConfirmButtons, CustomLabel, CustomSelect, Loader, Modal, StatusTag, TextEditor } from "src/components/ui";
import { MinusOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import moment from "moment";
import "./index.less"; // Assuming you have a CSS file for custom styles
import { useDispatch, useSelector } from "react-redux";
import { getProsInfoPopup } from "src/store/outreach-phones/asyncRequests";
import noData from "src/shared/images/phoneNotification.svg";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { Plus, X } from "lucide-react";
import { SvgIcon } from "src/shared/icons";
// import { navigate } from "react-router";
import tz from "moment-timezone";
import { useNavigate } from "react-router";
import { DialerModal } from "../ui";
import { PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { sendEmail } from "src/store/outreach-emails/asyncRequests";
import { motion, AnimatePresence } from "framer-motion";
import { outreachEmailsSelector, resetEmailSending } from "src/store/outreach-emails";
import { ActivityComponent } from "./ActivityComponent";

interface ViewModalProps {
  onClose: () => void;
  info: any;
  open: boolean;
}

const LoadingImage = ({ empty }) => (
  <div style={{ textAlign: "center", padding: "20px" }}>
    <Image preview={false} src={noData} alt={"No data"} width={300} height={300} />
    <Flex align="center" justify="center">
      {!empty && <Loader />}
      <div style={{ marginLeft: !empty ? "20px" : 0 }}>{empty ? `No activity yet` : `Loading prospect's activity`}</div>
    </Flex>
  </div>
);

export const ProspectPopup: FC<ViewModalProps> = ({ open, onClose, info }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [emailToDraft, setEmailToDraft] = useState(false);
  const [canHaveReplyTo, setCanHaveReplyTo] = useState(false);
  const [replySubjectLine, setReplySubjectLine] = useState("");

  const [emailFrom, setEmailFrom] = useState([]);
  const [emailsData, setEmailsData] = useState([]);
  const [emailToSendFromId, setEmailToSendFromId] = useState(null);

  const [latestThreadIdToRespondTo, setLatestThreadIdToRespondTo] = useState(null);
  const [latestThreadEmailCanRespondWith, setLatestThreadEmailCanRespondWith] = useState(null);
  const [latestProspInCampId, setLatestProspInCampId] = useState(null);

  const [idToFetch, setIdToFetch] = useState(null);
  const [idHandledData, setIdHandledData] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [uniqCampaigns, setUniqCampaigns] = useState([]);
  const { advancedPopupInfo, advancedPopupInfoLoading, dataAll } = useSelector(outreachPhonesSelector);
  const { additionalEmails, emailSending } = useSelector(outreachEmailsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const toggleSubject = (body) => {
    setEmailSubject(body ? "ReplyTo" : "");
  };
  const handleSubjUpd = (body) => {
    setEmailSubject(body);
  };
  const handleBodyUpd = (body) => {
    setEmailBody(body);
  };
  // const [actCampIds, setActCampIds] = useState([]);

  useEffect(() => {
    // const isEmailCanRespondToo = latestThreadEmailCanRespondWith === emailsData?.find((item) => item?.value === emailToSendFromId)?.email;

    console.log(latestThreadIdToRespondTo, "isEmailCanRespondToo");

    // if (latestThreadIdToRespondTo && isEmailCanRespondToo) {
    if (latestThreadIdToRespondTo) {
      setCanHaveReplyTo(true);
      setEmailSubject("ReplyTo");
    } else {
      setCanHaveReplyTo(false);
      setEmailSubject("");
    }
  }, [latestThreadIdToRespondTo, emailToSendFromId, emailsData, latestThreadIdToRespondTo]);
  const handleChangeId = (value) => {
    setEmailToSendFromId(value);
  };
  useEffect(() => {
    if (emailsData?.length && emailFrom?.length) {
      // const emailFromFiltered = emailFrom.filter((email) => email?.length > 0);
      // const emailToSendFrom = emailsData?.find(
      //   (email) => !email?.disabled && emailFrom.some((fromEmail) => email?.email?.toLowerCase().trim() === fromEmail.toLowerCase().trim())
      // );
      const foundEmail = [...emailFrom]
        .reverse()
        // Map each fromEmail to the matching email object in emailsData
        .map((fromEmail) => emailsData?.find((e) => !e?.disabled && e?.email?.toLowerCase().trim() === fromEmail.toLowerCase().trim()))
        // The array from map() might have undefined entries; find the first "truthy" item
        .find(Boolean);
      // console.log(emailsData, emailFrom, "additionalEmailsadditionalEmails", emailToSendFrom);
      setEmailToSendFromId(foundEmail?.value);
    }
  }, [emailsData, emailFrom]);

  useEffect(() => {
    setEmailsData(
      [...(additionalEmails || [])]
        ?.sort((a, b) => {
          return a?.id - b?.id;
        })
        ?.map((email) => {
          const emailToDisplay = email?.smtp?.from?.length ? email?.smtp?.from?.toLowerCase() : email?.email_alias_emailofcal?.toLowerCase().trim();
          return {
            label: `${emailToDisplay} ${
              emailFrom.some((fromEmail) => emailToDisplay === fromEmail.toLowerCase().trim()) && additionalEmails?.length > 1
                ? `(Got email from this address)`
                : ""
            } ${!email?.connected ? "- Disconnected" : ""}`,
            value: email?.id,
            email: emailToDisplay,
            disabled: !email?.connected,
          };
        })
    );
  }, [additionalEmails, emailFrom]);

  const close = () => {
    setDisplayData(null); // element below takes care of this
    setUniqCampaigns([]);
    setIdToFetch(null);
    setIdHandledData(null);
    onClose();
  };
  // console.log(advancedPopupInfo, "advancedPopupInfo");
  const [dialerModal, setDialerModal] = useState(false);
  const [emails, setEmails] = useState({ to: [], cc: [] });

  const openDialerModal = () => {
    setDialerModal(true);
    setUniqCampaigns([]);
    setIdToFetch(null);
    setIdHandledData(null);
    setDisplayData(null);
  };

  const closeDialerModal = () => {
    setDialerModal(false);
  };

  const resetEmail = ({ outcome }: { outcome: "success" | "fail" }) => {
    setEmailToDraft(false);
    setIdHandledData(null);
    // setEmailSubject("");
    setEmailBody("");
    message.destroy();
    if (outcome === "success") {
      message.success(`Sent email`);
    } else {
      message.error(`Failed to send email, try to check that the email is still connected properly.`);
    }
    dispatch(resetEmailSending());
    setIdToFetch(info?.id);
    dispatch(getProsInfoPopup({ id: info.id }));
  };

  useEffect(() => {
    if (emailSending === "success") {
      resetEmail({ outcome: "success" });
    }
    if (emailSending === "fail") {
      resetEmail({ outcome: "fail" });
    }
  }, [emailSending]);

  useEffect(() => {
    if (info?.id && info?.id !== idToFetch && !dialerModal) {
      setIdToFetch(info?.id);
      dispatch(getProsInfoPopup({ id: info.id }));
    }
  }, [info?.id, dialerModal]);

  useEffect(() => {
    if (info?.id && info?.email?.length) {
      setEmails({ to: [info.email], cc: [] });
    }
  }, [info?.id]);

  useEffect(() => {
    if (advancedPopupInfo?.id && advancedPopupInfo?.id !== idHandledData && info?.id === advancedPopupInfo?.id) {
      setIdHandledData(info?.id);
      // Step 1: Combine all sales_analytics into one array, tagging each item with the campaign ID from its prospect
      let allEmailsFrom = [];
      let allPhonesFrom = [];
      let combinedAnalytics = [];
      let lastProspInCampid = null;
      for (let i = 0; i < advancedPopupInfo?.prospectsinsalesseries?.length; i++) {
        let series = advancedPopupInfo?.prospectsinsalesseries?.[i];
        if (series?.id) {
          lastProspInCampid = series?.id;
        }
        let campaignId = series.new_outreach_campaign ? series.new_outreach_campaign.id : null;

        if (series?.sales_analytics && Array.isArray(series.sales_analytics)) {
          for (let j = 0; j < series.sales_analytics.length; j++) {
            let item = { ...series.sales_analytics[j], myCampaignId: campaignId };
            // Add the campaign ID as a new property (or overwrite if you'd prefer)
            item.myCampaignId = campaignId;
            combinedAnalytics.push(item);
            // Check if email_from is doessnt need to be unique so that the latest FROM is in there and can select it
            if (item?.email_from && item?.email_action === "email_sent") {
              allEmailsFrom.push(item.email_from);
            }
            if (
              item?.dialer_number_from &&
              !allPhonesFrom.some((dialer_number_from) => dialer_number_from === item.dialer_number_from) &&
              item?.dialer_action === "outgoing_call"
            ) {
              allPhonesFrom.push(item.dialer_number_from);
            }
          }
        }
      }
      setLatestProspInCampId(lastProspInCampid);
      setEmailFrom(allEmailsFrom);

      const combineOpensAndClicksAnalytics = [];
      combinedAnalytics?.forEach((an) => {
        if (
          (an?.email_action === "email_opened" || an?.email_action === "email_clicked") &&
          ((an?.campaign_id && an?.step_id) || an?.is_manual_email_reply_id)
        ) {
          // const email_opened = an?.email_action === "email_opened" ? an : null
          // const email_clicked = an?.email_action === "email_clicked" ? an : null
          // const email_opened_clicked = {
          //   ...email_opened,
          //   email_clicked: email_clicked
          // }
          combineOpensAndClicksAnalytics.push({
            action: an?.email_action,
            campid: an?.campaign_id,
            stepid: an?.step_id,
            manualId: an?.is_manual_email_reply_id,
          });
        }
      });

      // Step 2: Sort combinedAnalytics from earliest to latest by unix_time_action_happened
      combinedAnalytics = combinedAnalytics.filter(
        (item) =>
          item?.email_action === "email_replied" ||
          item?.email_action === "email_sent" ||
          item?.dialer_action === "outgoing_call" ||
          item?.dialer_action === "incoming_call" ||
          item?.dialer_action === "sms_recieved"
      );

      combinedAnalytics.sort((a, b) => {
        return a?.unix_time_action_happened - b?.unix_time_action_happened;
      });
      let hasThreadToRespondToo = false;
      let latestThreadIdToRespondTo;
      let latestSucject;
      let latestEmailFrom;
      combinedAnalytics?.forEach((analy) => {
        if (analy?.email_thread_id_resp_to) {
          console.log(analy?.email_thread_id_resp_to, "email_thread_id_resp_to", analy);
          hasThreadToRespondToo = true;
          latestSucject = analy?.replied_email_subject || analy?.meetz_email_subject;
          latestThreadIdToRespondTo = analy?.email_thread_id_resp_to;
          //  the below is not the best logic as they might of responded to a different email  from before in the thread but for now it will do for 95% of cases + add later to the object from where the email that they responded too came from if needed
          // if (analy?.email_action === "email_sent") {
          //   latestEmailFrom = analy?.email_from;
          // }
        }
      });
      console.log(latestSucject, "latestSucject");
      // setCanHaveReplyTo(hasThreadToRespondToo);
      setReplySubjectLine(latestSucject);
      setLatestThreadIdToRespondTo(latestThreadIdToRespondTo);
      // setLatestThreadEmailCanRespondWith(latestEmailFrom);
      // setCanHaveReplyTo(true);
      // setLatestThreadIdToRespondTo(advancedPopupInfo?.prospectsinsalesseries?.[0]?.emails_thread_ids?.[0]);

      // Step 2.1: Add open and click counts to email_sent items
      combinedAnalytics.forEach((item) => {
        if (item.email_action === "email_sent") {
          const relatedOpensAndClicks = combineOpensAndClicksAnalytics.filter(
            (an) => (an.campid === item.campaign_id && an.stepid === item.step_id) || an?.manualId === item?.is_manual_email_reply_id
          );
          item.openedEmail = relatedOpensAndClicks.filter((an) => an.action === "email_opened").length;
          item.clickedEmail = relatedOpensAndClicks.filter((an) => an.action === "email_clicked").length;
        }
      });

      // Step 3: Build a separate array of all unique campaigns
      let uniqueCampaigns = [];
      let campaignSet = new Set();

      for (let i = 0; i < advancedPopupInfo?.prospectsinsalesseries?.length; i++) {
        let series = advancedPopupInfo?.prospectsinsalesseries?.[i];
        let cId = series?.new_outreach_campaign ? series?.new_outreach_campaign?.id : null;
        let cName = series?.new_outreach_campaign ? series?.new_outreach_campaign?.campaign_name : "No Name";

        if (!campaignSet.has(cId)) {
          campaignSet.add(cId);
          uniqueCampaigns.push(cId);
          // uniqueCampaigns.push({
          //   campaignId: cId,
          //   campaignName: cName,
          // });
        }
      }

      // Step 4: Show the output in the console
      // console.log("Sorted sales analytics (earliest to latest) with campaign ID:");
      console.log("Sorted sales analytics (earliest to latest) with campaign ID:", combinedAnalytics);
      setDisplayData(combinedAnalytics);
      setUniqCampaigns(uniqueCampaigns);
      console.log("\nAll unique campaigns:");
      console.log(uniqueCampaigns);
    }
    //  else {
    //   setDisplayData([]);
    // }
  }, [advancedPopupInfo]);

  const status = info?.marked_as_dnc_reason as
    | "smsin"
    | "unsub"
    | "emailin"
    | "markeddnc"
    | "bounced"
    | "climanuallysentemail"
    | "goodcalloutcome"
    | "badcalloutcome"
    | "suppressed"
    | "markedmanuallyasdonotcontact"
    | "connectedtodeal";

  let statusColor = "cyan";
  let displayStatus = "Ready";
  let displayStatusExpl = "Ready to be added into a campaign";
  switch (status) {
    case "smsin":
      displayStatus = "Got SMS";
      statusColor = "cyan";
      displayStatusExpl = "Won't continue to get your outreach campaigns. Prospect has replied with an SMS.";
      break;
    case "unsub":
      displayStatus = "Unsubscribed";
      statusColor = "orange";
      displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect has unsubscribed from your emails.`;
      break;
    case "emailin":
      displayStatus = "Email Reply";
      statusColor = "blue";
      displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect has replied with an email.`;
      break;
    case "markeddnc":
      displayStatus = "Marked DNC";
      statusColor = "yellow";
      displayStatusExpl = `Won't continue to get your outreach campaigns. You marked this prospect as Do Not Contact after a call`;
      break;
    case "bounced":
      displayStatus = "Bounced";
      statusColor = "red";
      displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect's email bounced.`;
      break;
    case "goodcalloutcome":
      displayStatus = "Good Call Outcome";
      statusColor = "green";
      displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the call as a good outcome.`;
      break;
    case "badcalloutcome":
      displayStatus = "Bad Call Outcome";
      statusColor = "volcano";
      displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the call as a bad outcome.`;
      break;
    case "suppressed":
      displayStatus = "Suppressed DNC List";
      statusColor = "magenta";
      displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the company or prospects email as DNC in your suppression list. You can find this in your account settings.`;
      break;
    case "markedmanuallyasdonotcontact":
      displayStatus = "CRM Marked DNC";
      statusColor = "lime";
      displayStatusExpl = `Won't continue to get your outreach campaigns. You marked this prospect as Do Not Contact in the Meetz CRM.`;
      break;
    case "climanuallysentemail":
      displayStatus = "Manual Email Sent";
      statusColor = "lime";
      displayStatusExpl = `Won't continue to get your outreach campaigns as you manually sent an email to them which automatically takes them out of the campaigns.`;
      break;
    case "connectedtodeal":
      displayStatus = "Connected to a deal";
      statusColor = "green";
      displayStatusExpl = `You connected this prospect to a deal in your "Deals" section. They will not continue to receive automated campaign steps, to avoid double outreach.`;
      break;
  }

  if (displayStatus === "Ready" && info?.prospectsinsalesseries?.length) {
    // check if in campaign and if active or not
    if (
      info?.prospectsinsalesseries?.some((sp) => {
        return sp?.new_outreach_campaign?.active && !sp?.is_finished;
      })
    ) {
      displayStatus = "In Campaign";
      statusColor = "blue";
      displayStatusExpl = `Prospect is currently in a campaign. They will continue to receive follow ups until the campaign is finished.`;
    } else {
      const prospectInActCampaignFin = info?.prospectsinsalesseries?.every((sp) => sp?.is_finished);
      if (prospectInActCampaignFin) {
        displayStatus = `Finished Campaign`;
        statusColor = "gold";
        displayStatusExpl = `Prospect is in a campaign and finished getting all the steps.`;
      } else {
        displayStatus = `Campaign Paused`;
        statusColor = "red";
        displayStatusExpl = `Prospect is currently in a campaign but not active. They will not receive any follow ups until the campaign is active again.`;
      }
    }
  }
  const [inputValue, setInputValue] = useState({ to: "", cc: "" });
  const [showInput, setShowInput] = useState({ to: false, cc: false });
  const [editingEmail, setEditingEmail] = useState({ type: null, index: null });

  /** Basic validity check for demonstration (no domain checks). */
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  /** Check if email already exists in either "to" or "cc". */
  const emailExists = (email) => emails?.to?.includes(email) || emails?.cc?.includes(email);

  // Press Enter → confirm
  const handleKeyDown = (e, field, index) => {
    if (e.key === "Enter") {
      console.log("isthistheissue2");
      e.preventDefault();
      confirmEmail(field, index);
    }
  };

  /** Confirm typed text (editing or new). */
  const confirmEmail = (field, index) => {
    const draft = inputValue[field].trim();
    if (!draft) return; // no empty

    // If editing an existing bubble
    if (editingEmail.type === field && editingEmail.index === index) {
      const oldEmail = emails[field][index];
      // If same email, skip checks
      if (draft === oldEmail) {
        endEditing(field);
        return;
      }
      // Demo: minimal checks
      if (!isValidEmail(draft)) {
        message.warning("Invalid email format!");
        return;
      }
      if (emailExists(draft)) {
        message.warning('This email is already in the "To" or "CC"');
        return;
      }
      // Replace old with new
      setEmails((prev) => {
        const updated = [...prev[field]];
        updated[index] = draft;
        return { ...prev, [field]: updated };
      });
      endEditing(field);
    } else {
      // We're adding a new email
      if (!isValidEmail(draft)) {
        message.warning("Invalid email format!");
        return;
      }
      if (emailExists(draft)) {
        message.warning('This email is already in the "To" or "CC"');
        return;
      }
      // Add new email
      setEmails((prev) => ({
        ...prev,
        [field]: [...prev[field], draft],
      }));
      // Clear input + close
      setInputValue((prev) => ({ ...prev, [field]: "" }));
      setShowInput((prev) => ({ ...prev, [field]: false }));
    }
  };

  /** Stop editing, revert state. */
  const endEditing = (field) => {
    setEditingEmail({ type: null, index: null });
    setInputValue((prev) => ({ ...prev, [field]: "" }));
  };

  /** Remove bubble from field. */
  const removeEmail = (field, index) => {
    setEmails((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  /** Click bubble → start editing that specific bubble. */
  const handleEmailClick = (field, index) => {
    setEditingEmail({ type: field, index });
    setInputValue((prev) => ({ ...prev, [field]: emails[field][index] }));
  };

  /** Clicking "+" → open new input, end any editing. */
  const handlePlusClick = (field) => {
    // If editing, end it without saving
    if (editingEmail.type) {
      endEditing(editingEmail.type);
    }
    // Show input for new
    setInputValue((prev) => ({ ...prev, [field]: "" }));
    setShowInput((prev) => ({ ...prev, [field]: true }));
  };

  /** Clicking container blank → show new input if not editing. */
  const handleContainerClick = (field) => {
    if (editingEmail.type) return; // if editing, ignore
    setShowInput((prev) => ({ ...prev, [field]: true }));
  };

  /** If new input is blurred with empty text, close. */
  const handleBlurNew = (field) => {
    console.log("isthistheissue1");
    if (!inputValue[field].trim()) {
      setShowInput((prev) => ({ ...prev, [field]: false }));
    }
  };

  /** If editing bubble is blurred. If there's typed text, do nothing → let user confirm with checkmark or Enter. */
  const handleBlurEdit = (field, index) => {
    console.log("isthistheissue3");
    const draft = inputValue[field].trim();
    if (!draft) {
      // If user empties the bubble, revert to old email
      endEditing(field);
    }
    // Otherwise, let them confirm with checkmark or fix
  };

  const scrollRef = useRef(null);
  const scrollEmail = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [displayData]);
  useEffect(() => {
    if (scrollEmail.current && emailToDraft) {
      scrollEmail.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [emailToDraft]);
  // useEffect(() => {
  //   if (scrollEmail.current) {
  //     scrollEmail.current.scrollTop = scrollEmail.current.scrollHeight;
  //   }
  // }, [emailToDraft]);

  const sendEmailNow = (body) => {
    message.info(`Sending`);
    setIdHandledData(null);
    dispatch(
      sendEmail({
        emailId: emailToSendFromId,
        subject: emailSubject?.trim() === "ReplyTo" ? replySubjectLine : emailSubject?.trim(),
        body: emailBody,
        replyToEmailId: emailSubject?.trim() === "ReplyTo" ? latestThreadIdToRespondTo : false,
        emailToSendTo: emails?.to || [],
        emailToSenCC: emails?.cc || [],
        prosInSalesId: latestProspInCampId,
      })
    );
  };

  const [cantSendReason, setCantSendReason] = useState(null);
  useEffect(() => {
    const countEmails = emails?.to?.length;
    if (!countEmails) {
      setCantSendReason(`Please add at least one "To" recipient`);
    } else if (!emailBody?.length) {
      setCantSendReason(`Please add an email body to send`);
    } else if (!emailSubject?.length) {
      setCantSendReason(`Please add a subject line`);
    } else if (!emailToSendFromId) {
      setCantSendReason(`Please select an email to send from`);
    } else if (emailSending) {
      setCantSendReason(`Email sending... `);
    } else {
      setCantSendReason(null);
    }
  }, [emails, emailBody, emailSubject, emailSending]);

  return (
    <>
      <DialerModal open={dialerModal} onCancel={closeDialerModal} prospectFromManDialer={info} />
      <Modal customClassName={"view-modal-popup"} open={!dialerModal && open} title={"Prospect"} onCancel={close} width={1000}>
        <div className="prospect-popup-container">
          <div
            style={{
              flex: 1,
              // height: "400px",
              // wordWrap: "break-word",
              // overflowWrap: "break-word",
              // whiteSpace: "pre-wrap",
              // maxWidth: "100%",
              overflowX: "hidden",
              // display: "block",
            }}
          >
            {" "}
            <h2 style={{ marginTop: 0 }}>Information:</h2>
            <div className="scrollable-section">
              <h2 style={{ marginBottom: "-2px", marginTop: 0 }}>
                Name: {info.first_name || ""} {info.last_name || ""}
              </h2>
              <p style={{ marginBottom: "-2px" }}>
                <strong>Email:</strong> {info.email || <MinusOutlined style={{ marginLeft: "3px" }} />}
              </p>
              <p style={{ marginBottom: "-2px" }}>
                <strong>Title:</strong> {info.title || <MinusOutlined style={{ marginLeft: "3px" }} />}
              </p>
              {!!info.company_name ? (
                <p style={{ marginBottom: "-2px" }}>
                  <strong>Company:</strong>{" "}
                  {info.company_url?.length > 5 ? (
                    <Button
                      className={"link"}
                      onClick={() => window.open("https://www." + info?.company_url.replace(/^(https?:\/\/)?(www\.)?/i, ""), "_blank", "noopener,noreferrer")}
                    >
                      {info.company_name || ""}
                    </Button>
                  ) : (
                    info.company_name || ""
                  )}
                  <br />
                  {/* <Button type="link" onClick={() => setSeeMore(!seeMore)} style={{ marginLeft: "0px", padding: 0, margin: 0 }}>
                {seeMore ? "See Less" : "See More"}
              </Button> */}
                </p>
              ) : (
                ""
              )}
              {seeMore && (
                <>
                  {info.direct_phone && (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Direct Phone:</strong> {info.direct_phone || <MinusOutlined style={{ marginLeft: "3px" }} />}
                    </p>
                  )}
                  {info.mobile_phone && (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Mobile Number:</strong> {info.mobile_phone || <MinusOutlined style={{ marginLeft: "3px" }} />}
                    </p>
                  )}
                  {info.business_number && (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Business Number:</strong> {info.business_number || <MinusOutlined style={{ marginLeft: "3px" }} />}
                    </p>
                  )}
                  {info.linkedin_profile_url ? (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>LinkedIn Profile:</strong>{" "}
                      <a href={`https://${info.linkedin_profile_url}`} target="_blank" rel="noopener noreferrer">
                        {info.linkedin_profile_url}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  {info.upload_name ? (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Upload Name:</strong> {info.upload_name}
                    </p>
                  ) : (
                    ""
                  )}
                  {info.date_created ? (
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Date Created:</strong> {moment(info.date_created).format("MMMM Do 'YY")}
                    </p>
                  ) : (
                    ""
                  )}{" "}
                  <p style={{ display: "flex" }}>
                    {" "}
                    <strong>Outreach status:</strong>
                    <Tooltip placement={"top"} title={displayStatusExpl}>
                      <div style={{ width: "fit-content", marginLeft: "10px" }}>
                        <StatusTag color={statusColor} value={displayStatus} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
                      </div>
                    </Tooltip>
                  </p>
                  {/* <h3 style={{ marginBottom: "-2px" }}>Campaign Information</h3> */}
                  {/* {info.prospectsinsalesseries.map((campaign, index) => (
                  <div key={index} style={{ marginBottom: "-2px" }}>
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Campaign Name:</strong> {campaign.new_outreach_campaign.campaign_name}
                    </p>
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Phone Number Used:</strong> {campaign.new_outreach_campaign.phone_number_using_now}
                    </p>
                    <p style={{ marginBottom: "-2px" }}>
                      <strong>Calls at the Same Time:</strong> {campaign.new_outreach_campaign.calls_at_the_same_time}
                    </p>
                  </div>
                ))} */}
                </>
              )}
            </div>
          </div>{" "}
          <div style={{ flex: 1, marginLeft: "50px" }}>
            {" "}
            <h2 style={{ marginTop: 0 }}>Activity:</h2>
            <ActivityComponent info={info} />
          </div>
        </div>
        {/* {!emailToDraft ? (
          ""
        ) : ( */}
        <Flex style={{ marginLeft: "5px", marginTop: "15px" }} justify="center" align="center">
          <Flex
            style={{
              flex: 1,
              marginBottom: "auto",
              marginRight: "auto",
            }}
          >
            <Button className={"link"} style={{ marginRight: "10px" }} onClick={openDialerModal}>
              Call prospect
            </Button>{" "}
            {latestProspInCampId ? (
              <>
                {" "}
                <div style={{ height: "fit-content", paddingBottom: "3px" }}> or </div>
                <Button className={"link"} style={{ marginLeft: "10px" }} onClick={() => setEmailToDraft(true)}>
                  Email prospect
                </Button>
              </>
            ) : (
              ""
            )}
          </Flex>
          <Flex
            style={{
              flex: 1,
              // width: "fit-content",
              marginLeft: "auto",
              marginRight: "5px",
            }}
            justify="center"
            align="center"
            ref={scrollEmail}
          >
            {emailToDraft ? (
              <div style={{ width: "90%", marginLeft: "5%" }}>
                <Flex justify="space-between" align="center">
                  <Button className={"link"} style={{ marginLeft: "auto" }} onClick={() => setEmailToDraft(false)}>
                    Close email
                  </Button>
                </Flex>
                <br />
                <div className="emailInputWrapper">
                  <label className="emailFieldLabel">From</label>{" "}
                  <CustomSelect
                    options={emailsData}
                    onChange={handleChangeId}
                    value={emailToSendFromId}
                    isSearchSelect={false}
                    style={{ width: "390px", marginTop: "5px", marginBottom: "5px" }}
                  />
                  {["to", "cc"].map((field) => (
                    <div key={field} className="emailFieldSection">
                      <div className="emailFieldHeader">
                        <label className="emailFieldLabel">{field}</label>
                        <Button type="text" icon={<PlusOutlined />} className="addEmailButton" onClick={() => handlePlusClick(field)} />
                      </div>
                      <div className="emailFieldContainer" onClick={() => handleContainerClick(field)}>
                        <AnimatePresence>
                          {emails[field].map((email, idx) => (
                            <motion.div
                              key={idx}
                              initial={{ opacity: 0, scale: 0.8, x: -10 }}
                              animate={{ opacity: 1, scale: 1, x: 0 }}
                              exit={{ opacity: 0, scale: 0.8, x: 10 }}
                              transition={{ duration: 0.2 }}
                              className="emailBubble"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEmailClick(field, idx);
                              }}
                            >
                              {editingEmail.type === field && editingEmail.index === idx ? (
                                <div className="emailEditBubble">
                                  <Input
                                    className="emailEditInput"
                                    autoFocus
                                    value={inputValue[field]}
                                    onChange={(e) =>
                                      setInputValue((prev) => ({
                                        ...prev,
                                        [field]: e.target.value,
                                      }))
                                    }
                                    onKeyDown={(e) => handleKeyDown(e, field, idx)}
                                    onBlur={() => handleBlurEdit(field, idx)}
                                  />
                                  {/* Checkmark → confirm changes */}
                                  <button
                                    className="emailConfirmButton"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      confirmEmail(field, idx);
                                    }}
                                  >
                                    <CheckOutlined />
                                  </button>
                                </div>
                              ) : (
                                <>
                                  {email}
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      removeEmail(field, idx);
                                    }}
                                    className="removeEmailButton"
                                  >
                                    <CloseOutlined style={{ fontSize: 12 }} />
                                  </button>
                                </>
                              )}
                            </motion.div>
                          ))}
                        </AnimatePresence>

                        {/* Show "new email" input if not editing & showInput is true */}
                        {showInput[field] && editingEmail.type === null && (
                          <div className="emailEditBubble">
                            <Input
                              className="emailTextInput"
                              autoFocus
                              placeholder={`Enter "${field}" email`}
                              value={inputValue[field]}
                              onChange={(e) =>
                                setInputValue((prev) => ({
                                  ...prev,
                                  [field]: e.target.value,
                                }))
                              }
                              onKeyDown={(e) => handleKeyDown(e, field, null)}
                              onBlur={() => handleBlurNew(field)}
                            />
                            {/* X on right in "Add" mode */}
                            <button
                              className="cancelEmailButton"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowInput((prev) => ({ ...prev, [field]: false }));
                                setInputValue((prev) => ({ ...prev, [field]: "" }));
                              }}
                            >
                              <CloseOutlined />
                            </button>
                            {/* Checkmark on right in "Add" mode */}
                            <button className="emailConfirmButton" onClick={() => confirmEmail(field, null)}>
                              <CheckOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {canHaveReplyTo ? (
                    <>
                      {" "}
                      <Checkbox checked={emailSubject?.trim() === "ReplyTo"} onChange={(e) => toggleSubject(e.target.checked)} style={{ marginTop: 8 }}>
                        Reply to previous email in thread
                      </Checkbox>
                      <br />
                    </>
                  ) : (
                    <></>
                  )}
                  <br />
                  {emailSubject?.trim() !== "ReplyTo" && (
                    <>
                      <React.Fragment>
                        <CustomLabel title={"Subject Line"} />
                        <Input value={emailSubject} onChange={(e) => handleSubjUpd(e.target.value)} />
                      </React.Fragment>
                      <br />
                      <br />
                    </>
                  )}
                  <CustomLabel title={"Email Body"} />
                  <Flex vertical style={{ marginBottom: 22 }}>
                    <TextEditor
                      content={emailBody}
                      // withVariables
                      onContentChange={(val) => handleBodyUpd(val)}
                    />
                  </Flex>{" "}
                  <div style={{ marginLeft: "auto", width: "fit-content" }}>
                    {/* <Button className={"primary"} onClick={sendEmailNow} disabledText disabled={cantSendReason?.length}>
                      Send email
                    </Button> */}
                    <ConfirmButtons
                      // onCancel={onCancel}
                      // leftButtonTitle={"Back"}
                      rightButtonTitle={"Send email"}
                      onClick={sendEmailNow}
                      disabled={cantSendReason?.length}
                      disabledExplanation={cantSendReason}
                    />
                  </div>
                  <br />
                  <div className="content-footnote">
                    Note: Sending a manual email above will remove the prospect from the campaign, stopping them from receiving further steps in the campaign
                    unless they are re-added back in.
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Flex>
        </Flex>
        {/* )} */}
      </Modal>
    </>
  );
};
