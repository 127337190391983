import React, { FC, useContext, useEffect, useState } from "react";
import { DealsContext } from "../../components/app/context/DealsContext";
import PageLayout from "../../components/layout/PageLayout";
import { Deals } from "../../components/pages/deals";
import { AddColumnModal } from "../../components/pages/deals/ui/AddColumnModal";
import { AddDealModal } from "../../components/pages/deals/ui/AddDealModal";
import { DeleteColumnModal } from "../../components/pages/deals/ui/DeleteColumnModal";
import { DeleteDealModal } from "../../components/pages/deals/ui/DeleteDealModal";
import { EditColumnModal } from "../../components/pages/deals/ui/EditColumnModal";
import { EditDealModal } from "../../components/pages/deals/ui/EditDealModal";
import { ConfirmButtons } from "../../components/ui";
import { useBreakpoints } from "../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, resetDealSavedUnix, updateDealsState } from "src/store/outreach-campaigns";
import { message } from "antd";
import { updateDealsBackend } from "src/store/outreach-campaigns/asyncRequests";

interface DealsPageProps {
  isTourStep?: boolean;
}

const DealsPage: FC<DealsPageProps> = ({ isTourStep }) => {
  const {
    isAddColumnModalOpened,
    openAddColumnModal,
    closeAddColumnModal,
    isEditColumnModalOpened,
    closeEditColumnModal,
    isDeleteColumnModalOpened,
    closeDeleteColumnModal,
    isAddDealModalOpened,
    closeAddDealModal,
    isEditDealModalOpened,
    closeEditDealModal,
    isDeleteDealModalOpened,
    closeDeleteDealModal,
  } = useContext(DealsContext);
  const { isDesktop } = useBreakpoints();
  const { savedUnix, savingNow, dealsGroup } = useSelector(outreachCampaignSelector);
  const [autoSaveText, setAutoSaveText] = React.useState("Auto saves your changes");

  const [data, setData] = useState(dealsGroup);

  const saveUpd = (updDeals) => {
    dispatch(updateDealsState({ updDeals }));
    dispatch(updateDealsBackend({ updDeals }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoSaveText === "Auto saves your changes") {
        setAutoSaveText("");
      }
    }, 15000);

    return () => clearTimeout(timer);
  }, [autoSaveText]);
  const dispatch = useDispatch();
  useEffect(() => {
    const updateAutoSaveText = () => {
      if (savedUnix === "failed") {
        message.error("Couldn't save. Please check your internet and try again");
        dispatch(resetDealSavedUnix());
      } else if (savedUnix) {
        const now = Math.floor(Date.now() / 1000);
        const diff = now - Math.floor(savedUnix / 1000);
        // console.log(diff, "diffdiff", savedUnix, now);
        if (diff < 8) {
          setAutoSaveText("Saved a few seconds ago");
        } else if (diff < 60) {
          setAutoSaveText("Saved less than a minute ago");
        } else if (diff < 300) {
          const minutes = Math.floor(diff / 60);
          setAutoSaveText(`Saved ${minutes} minute${minutes > 1 ? "s" : ""} ago`);
        } else if (diff < 86400) {
          const hours = Math.floor(diff / 3600);
          setAutoSaveText(`Saved ${hours} hour${hours > 1 ? "s" : ""} ago`);
        } else {
          const days = Math.floor(diff / 86400);
          setAutoSaveText(`Saved over ${days} day${days > 1 ? "s" : ""} ago`);
        }
      }
    };

    updateAutoSaveText();
    const interval = setInterval(() => {
      updateAutoSaveText(); // Recheck every 3 seconds
    }, 3000);

    return () => clearInterval(interval);
  }, [savedUnix]);

  return isDesktop ? (
    <>
      <PageLayout
        pageTitle={"Deals"}
        rightHeaderContent={
          <ConfirmButtons
            rightButtonTitle={"Add Column"}
            width={"fit-content"}
            style={{ gap: 18 }}
            handleSubmit={openAddColumnModal}
            leftButtonTitle={<span style={{ marginRight: "20px", color: "#888" }}>{savingNow ? "Saving now..." : autoSaveText}</span>}
            leftButtonNoClick
          />
        }
        isTableContent={false}
      >
        {" "}
        {!isTourStep && (
          <>
            {isAddColumnModalOpened && <AddColumnModal onClose={closeAddColumnModal} setData={setData} colAmnt={data?.length} saveUpd={saveUpd} deals={data} />}
            {/* {isEditColumnModalOpened && <EditColumnModal onClose={closeEditColumnModal} />} */}
            {/* {isDeleteColumnModalOpened && <DeleteColumnModal onClose={closeDeleteColumnModal} />} */}
            {/* {isAddDealModalOpened && <AddDealModal onClose={closeAddDealModal} />} */}
            {/* {isEditDealModalOpened && <EditDealModal onClose={closeEditDealModal} />} */}
            {/* {isDeleteDealModalOpened && <DeleteDealModal onClose={closeDeleteDealModal} />} */}
          </>
        )}
        {/* <div style={{ marginLeft: "auto", marginRight: "20px", width: "fit-content", color: "#888", paddingBottom: "10px", marginTop: "-10px" }}> */}
        {/* Auto saves your changes{" "} */}
        {/* </div> */}
        <Deals isTourStep={isTourStep} dealsGroup={dealsGroup} data={data} setData={setData} />
      </PageLayout>
    </>
  ) : (
    <GoToDesktopModal />
  );
};

export default DealsPage;
