/* eslint-disable react/jsx-fragments */
import { Checkbox, CollapseProps, Flex, Form, Input, Typography } from "antd";
import React, { FC, useState } from "react";
import { Collapse, ConfirmButtons, CustomLabel, Loader, Modal, TextEditor } from "src/components/ui";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, replaceStepWithNewEmailVariants } from "src/store/outreach-campaigns";

type ApproveAiVariantsModalProps = {
  open: boolean;
  onBack: VoidFunction;
  onCancel: VoidFunction;
  formData: any;
  setFormData: React.Dispatch<any>;
};

export const ApproveAiVariantsModal: FC<ApproveAiVariantsModalProps> = ({ open, onBack, onCancel, formData, setFormData }) => {
  const [loading, setLoading] = useState(false);
  const isReply = formData?.additionally;

  const { newlyGeneratedTemplatedEmails, loadingNewlyGeneratedTemplatedEmails } = useSelector(outreachCampaignSelector);

  // console.log(newlyGeneratedTemplatedEmails, "newlyGeneratedTemplatedEmails");

  const backModal = () => {
    onBack();
    //   setFormData( {})
  };
  const closeModal = () => {
    onCancel();
    //   setFormData( {})
  };

  const dispatch = useDispatch();

  const handleFormSubmit = () => {
    dispatch(replaceStepWithNewEmailVariants());
    closeModal();
  };

  const items: CollapseProps["items"] = newlyGeneratedTemplatedEmails?.length
    ? newlyGeneratedTemplatedEmails?.map((email, i) => {
        return {
          key: i,
          label: `Email Variation ${String.fromCharCode(65 + i)}`,
          children: (
            <>
              {/* <Checkbox checked={email.subject?.trim()==='ReplyTo'}  style={{pointerEvents:'none'}}  >Reply to previous steps email</Checkbox> */}
              {}
              {email.subject?.trim() === "ReplyTo" ? (
                <>
                  Replies to previous steps email
                  <br />
                  <br />
                </>
              ) : (
                <>
                  <React.Fragment>
                    <CustomLabel title={"Subject Line"} />
                    <Input value={email.subject} style={{ pointerEvents: "none" }} />
                  </React.Fragment>
                  <br />
                  <br />
                </>
              )}

              <CustomLabel title={"Email Body"} />
              <TextEditor content={email.body} readOnly />
            </>
          ),
        };
      })
    : [];

  const { Text } = Typography;
  return (
    <Modal customClassName={"approve-modal-wrapper"} width={530} open={open} title={"Approve AI A/B Testing Variations"} onCancel={closeModal}>
      {loadingNewlyGeneratedTemplatedEmails ? (
        <Flex vertical className={"loader-wrapper"} justify={"center"}>
          <Loader tip={"Loading... Optimizing and Generating New Templates"} />
          <br />
        </Flex>
      ) : (
        <>
          {newlyGeneratedTemplatedEmails?.length ? (
            <Collapse items={items} />
          ) : (
            `Something went wrong, no variations we're made please retry after checking your internet connection.`
          )}
        </>
      )}
      <br />
      <ConfirmButtons
        rightButtonTitle={"Replace"}
        leftButtonTitle={"Back"}
        onCancel={backModal}
        handleSubmit={handleFormSubmit}
        disabled={loadingNewlyGeneratedTemplatedEmails || !newlyGeneratedTemplatedEmails?.length}
      />
      <br />
      <Text className={"content-footnote"}>
        *If you approve it will replace this steps A/B email variations with the above templates, you can edit the above once you click "Replace"
      </Text>
    </Modal>
  );
};
