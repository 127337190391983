import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHandler } from "../helpers/apiHandler";

export const getProsInfoPopup = createAsyncThunk("dialer/getProsInfoPopup", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/getProsInfoPopup",
    method: "post",
    body,
  });
  return res;
});

export const manualDialerProsSearchTopSearch = createAsyncThunk(
  "dialer/manualDialerProsSearchTopSearch",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers;
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/searchForContact",
      method: "post",
      body: { ...body, topSearch: true },
    });
    return res;
  }
);

export const downloadPhoneAnalytics = createAsyncThunk("aiSales/downloadPhoneAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: "/aiSales/downloadPhoneAnalytics",
    method: "post",
    body,
    downloadFileName: `${body?.nameOfExportFile}.xlsx`,
  });

  return res;
});

export const manualDialerProsSearch = createAsyncThunk("dialer/manualDialerProsSearch", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/searchForContact",
    method: "post",
    body,
  });
  return res;
});

export const getAllPhoneStats = createAsyncThunk("dialer/getAllPhoneStats", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/getAllPhoneStats",
    method: "post",
    body,
  });

  return res;
});

export const getPhoneGraphAnalytics = createAsyncThunk("dialer/getPhoneGraphAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/getPhoneGraphAnalytics",
    method: "post",
    body,
  });

  return res;
});

export const getPhoneAnalytics = createAsyncThunk("dialer/getPhoneAnalytics", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/getPhoneAnalytics",
    method: "post",
    body,
  });

  return res;
});

export const remNumFromTwillio = createAsyncThunk("dialer/remNumFromTwillio", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/remNumFromTwillio",
    method: "delete",
    body,
  });

  return res;
});

export const updateCampaignsDialers = createAsyncThunk("aiSales/updateCampaignsDialers", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/aiSales/updateCampaignsDialers",
    method: "post",
    body: {},
    // longTimer: true,
  });
  return res;
});

export const searchPhonesAvail = createAsyncThunk("dialer/updatePhoneSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/updatePhoneSettings",
    method: "post",
    body,
  });

  return res;
});

export const addFreeNum = createAsyncThunk("stripe/addFreeNum", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/stripe/addFreeNum",
    method: "post",
    body,
  });
  // console.log(res, "phone res 1");
  if (res.code || res.error) {
    // const errorPayload = new CustomError('123', res.code || null);
    throw res;
  }

  return res;
});

export const getAddressInformation = createAsyncThunk("dialer/getAddressInformation", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/getAddressInformation",
    method: "post",
    body,
  });
  // console.log(res, "addressComplete3");
  return res;
});

export const checkAddress = createAsyncThunk("dialer/checkAddress", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/checkAddress",
    method: "post",
    body,
  });
  return res;
});

export const listPhoneNums = createAsyncThunk("dialer/listPhoneNums", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  // console.log(body, "bodybody");
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/listPhoneNums",
    method: "post",
    body,
  });
  return res;
});

export const updatePhoneSettings = createAsyncThunk("dialer/updatePhoneSettings", async (body: any, { getState }: { getState: any }) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: "/dialer/updatePhoneSettings",
    method: "post",
    body,
  });

  return res;
});
