import { Tabs, TabsProps, Typography } from "antd";
import React, { FC } from "react";
import { DashboardCard } from "../ui/DashboardCard";
import { ActivitiesEmptyData } from "./ActivitiesEmptyData";
import { HotProspectsTab } from "./HotProspectsTab";
import { RecentInteractionTab } from "./RecentInteractionTab";
import "./index.less";
import { Analytics } from "./Analytics";
interface ActivitiesCardProps {
  data?: any;
}

export const ActivitiesCard: FC<ActivitiesCardProps> = ({ data }) => {
  //   const items: TabsProps["items"] = [
  //     {
  //       key: "1",
  //       label: "Recent Interactions",
  //       children: data ? <RecentInteractionTab data={data} /> : <ActivitiesEmptyData />,
  //     },
  //     {
  //       key: "2",
  //       label: "Hot Prospects",
  //       children: data ? <HotProspectsTab data={data} /> : <ActivitiesEmptyData />,
  //     },
  //   ];

  return (
    <DashboardCard customClassName={"activities-wrapper"}>
      {/* <Tabs defaultActiveKey={"1"} items={items} /> */}
      <Typography.Title level={3}>More analytics</Typography.Title>
      <Analytics />
    </DashboardCard>
  );
};
