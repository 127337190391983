/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
import { Button, Flex, Tooltip, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import PageLayout from "src/components/layout/PageLayout";
import { PageTitle, StatisticsBar, TabsSwitch } from "src/components/ui";

import Confetti from "react-confetti";
// import { campaignData } from "../campaignsData"
import { EmailsTab, OverviewTab, PhoneTab } from "./ui";
import { phoneCallsStatisticData } from "src/components/pages/phone-calls/info";
import { statisticEmailsData } from "src/components/pages/emails-data/info";
import { decode as atob, encode as btoa } from "base-64";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, resetFirstNewCamp, setCampLookingAtNowId, setOpenCampSettings } from "src/store/outreach-campaigns";
import "./index.less";
import { getCampaignDetails, getCampInfo, getEachStepsAnalytics } from "src/store/outreach-campaigns/asyncRequests";
import { AppDispatch } from "src/store";
import { userIsPayingSelector, userIsTrialSelector, userSelector } from "src/store/user";
import { SvgIcon } from "src/shared/icons";
import { GeneralSettingsModal } from "./ui/GeneralSettingsModal";
import { Loader } from "src/components/layout";

export const fixOutreachForFrontEnd = (campToFix) => {
  const feFixedOutreachSteps: any[] = [];
  campToFix?.campaign?.campaign_steps?.length &&
    campToFix?.campaign?.campaign_steps?.forEach((step) => {
      if (step?.step_type === "email") {
        const indexOfSameStep = feFixedOutreachSteps?.findIndex((fixStep) => fixStep?.stepNum === step?.step_number);
        if (indexOfSameStep >= 0) {
          const copEmStep = { ...feFixedOutreachSteps[indexOfSameStep] };
          console.log(copEmStep?.body, "step?.delay_from_last_step_minutes2");
          copEmStep.total_email_analytic_sent = (copEmStep.total_email_analytic_sent || 0) + (step?.email_analytic_sent || 0);
          copEmStep.total_email_analytic_clicked = (copEmStep.total_email_analytic_clicked || 0) + (step?.email_analytic_clicked || 0);
          copEmStep.total_email_analytic_pending = (copEmStep.total_email_analytic_pending || 0) + (step?.email_analytic_pending || 0);
          copEmStep.total_email_analytic_bounced = (copEmStep.total_email_analytic_bounced || 0) + (step?.email_analytic_bounced || 0);
          copEmStep.total_email_analytic_opened = (copEmStep.total_email_analytic_opened || 0) + (step?.email_analytic_opened || 0);
          copEmStep.total_email_analytic_forwarded = (copEmStep.total_email_analytic_forwarded || 0) + (step?.email_analytic_forwarded || 0);
          copEmStep.total_email_analytic_replied = (copEmStep.total_email_analytic_replied || 0) + (step?.email_analytic_replied || 0);
          copEmStep.total_email_analytic_booked = (copEmStep.total_email_analytic_booked || 0) + (step?.email_analytic_booked || 0);
          copEmStep.total_skipped_step_count = (copEmStep.total_skipped_step_count || 0) + (step?.skipped_step_count || 0);

          copEmStep.emailVariantsArray.push({
            id: step?.id,
            email_analytic_sent: step?.email_analytic_sent || 0,
            email_analytic_pending: step?.email_analytic_pending || 0,
            email_analytic_clicked: step?.email_analytic_clicked || 0,
            email_analytic_bounced: step?.email_analytic_bounced || 0,
            email_analytic_opened: step?.email_analytic_opened || 0,
            email_analytic_forwarded: step?.total_email_analytic_forwarded || 0,
            email_analytic_replied: step?.email_analytic_replied || 0,
            email_analytic_booked: step?.email_analytic_booked || 0,
            skipped_step_count: step?.skipped_step_count || 0,
            subject: step?.subject,
            body: step?.body,
            variantNum: step?.variant_number,
          });
          copEmStep.emailVariantsArray.sort((a, b) => a.variantNum - b.variantNum);

          feFixedOutreachSteps[indexOfSameStep] = copEmStep;
        } else {
          // console.log(step?.delay_from_last_step_minutes, "step?.delay_from_last_step_minutes");
          feFixedOutreachSteps.push({
            id: step?.id, // this is just really used for an indicator more than anything else in front end. if first variant is deleted so it will refresh in backend with a dif id and no issue will arise. BE uses this to just find if its a variant email step. in that case it will roll through all and update befroe deletions happen or anything. also deletiong will say delete all/ true false
            stepNum: step?.step_number,

            type: "email",
            autoStart: !!step?.auto_start,
            delayFromLatestStep: step?.delay_from_last_step_minutes || "immediate",

            total_email_analytic_sent: step?.email_analytic_sent || 0,
            total_email_analytic_pending: step?.email_analytic_pending || 0,
            total_email_analytic_clicked: step?.email_analytic_clicked || 0,
            total_email_analytic_bounced: step?.email_analytic_bounced || 0,
            total_email_analytic_opened: step?.email_analytic_opened || 0,
            total_email_analytic_forwarded: step?.total_email_analytic_forwarded || 0,
            total_email_analytic_replied: step?.email_analytic_replied || 0,
            total_email_analytic_booked: step?.email_analytic_booked || 0,
            total_skipped_step_count: step?.skipped_step_count || 0,

            emailVariantsArray: [
              {
                id: step?.id,
                email_analytic_sent: step?.email_analytic_sent || 0,
                email_analytic_pending: step?.email_analytic_pending || 0,
                email_analytic_clicked: step?.email_analytic_clicked || 0,
                email_analytic_bounced: step?.email_analytic_bounced || 0,
                email_analytic_opened: step?.email_analytic_opened || 0,
                email_analytic_forwarded: step?.total_email_analytic_forwarded || 0,
                email_analytic_replied: step?.email_analytic_replied || 0,
                email_analytic_booked: step?.email_analytic_booked || 0,
                skipped_step_count: step?.skipped_step_count || 0,
                subject: step?.subject,
                body: step?.body,
                variantNum: step?.variant_number,
              },
            ],
          });
        }
      } else {
        if (step?.step_type === "aiemail") {
          // console.log(step, "debugstep");
          feFixedOutreachSteps.push({
            id: step?.id,
            stepNum: step?.step_number,

            autoStart: !!step?.auto_start,

            type: "aiemail",

            delayFromLatestStep: step?.delay_from_last_step_minutes || "immediate",

            backupEmailDb: { subject: step?.subject || "", body: step?.body || "" },
            total_email_analytic_sent: step?.email_analytic_sent || 0,
            total_email_analytic_clicked: step?.email_analytic_clicked || 0,
            total_email_analytic_pending: step?.email_analytic_pending || 0,
            total_email_analytic_bounced: step?.email_analytic_bounced || 0,
            total_email_analytic_opened: step?.email_analytic_opened || 0,
            total_email_analytic_forwarded: step?.email_analytic_forwarded || 0,
            total_email_analytic_replied: step?.email_analytic_replied || 0,
            total_email_analytic_booked: step?.email_analytic_booked || 0,
            total_skipped_step_count: step?.skipped_step_count || 0,
          });
        } else {
          feFixedOutreachSteps.push({
            id: step?.id,

            autoStart: !!step?.auto_start,

            type: "phonecall",
            stepNum: step?.step_number,

            delayFromLatestStep: step?.delay_from_last_step_minutes || "immediate",

            phone_analytic_calls_attempted: step?.phone_analytic_calls_attempted,
            phone_analytic_calls_incoming: step?.phone_analytic_calls_incoming,
            phone_analytic_sms_out: step?.phone_analytic_sms_out,
            phone_analytic_sms_in: step?.phone_analytic_sms_in,
            phone_analytic_with_good_status: step?.phone_analytic_with_good_status,
            phone_analytic_with_bad_status: step?.phone_analytic_with_bad_status,
            skipped_step_count: step?.skipped_step_count,

            total_skipped_step_count: step?.skipped_step_count || 0,
          });
        }
      }
    });

  const feFixedAndSorted = feFixedOutreachSteps
    ?.sort((a, b) => a.stepNum - b.stepNum)
    // ?.map((step) => ({...step,delayFromLatestStep:step?.delayFromLatestStep||'immediate'}))
    ?.map((origStep, i) => (i ? origStep : { ...origStep, notUpdatedComp: true }));
  // console.log(feFixedAndSorted, "feFixedAndSorted");
  return feFixedAndSorted; // notupdatedcomp in first index triggers update and additional formatting

  // campToFix?.stepsAnalytics?.length && campToFix?.stepsAnalytics?.forEach((stepAnalytics)=>{

  // })
  //   const stepsAnalytics = campToFix?.stepsAnalytics
  //   for (let key in stepsAnalytics) {
  // if (stepsAnalytics?.hasOwnProperty(key)) {
  //   console.log(`123321Key: ${key}` , stepsAnalytics);
  //   console.log(`123321Value: ${JSON.stringify(stepsAnalytics[key])}`);

  // Perform any additional actions here
  // For example, updating a value
  // stepsAnalytics[key].prospectNotReadyYetCount = 0;
  // stepsAnalytics[key].prospectReadyStepCount = 0;
  // }
  // }
};

export const CampaignDetails: FC = () => {
  const { id } = useParams();
  const payingClient = useSelector(userIsPayingSelector);
  const trialClient = useSelector(userIsTrialSelector);
  const navigate = useNavigate();
  const {
    outreachCampaignsList,
    campLookingAtNowId,
    firstNewCamp,
    campaignDisplaying,
    loadingCampLookingAt,
    loadingGetEachStepsAnalytics,
    selCampInfo,
    selCampInfoLoading,
  } = useSelector(outreachCampaignSelector);
  const selectedCampaignId = (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000;
  const selectedCampaign =
    selCampInfo?.overview?.campId === selectedCampaignId
      ? selCampInfo
      : (outreachCampaignsList || [])?.find((item) => +item?.overview?.campId === selectedCampaignId);
  console.log(selCampInfo, "selCampInfo", selectedCampaign);
  useEffect(() => {
    if (outreachCampaignsList?.length && !selectedCampaign && !selCampInfoLoading) {
      dispatch(getCampInfo({ selectedCampaignId }));
    }
  }, [outreachCampaignsList]);
  // console.log(outreachCampaignsList,selectedCampaignId,'selectedCampaignId',selectedCampaign);

  const dispatch = useDispatch<AppDispatch>();
  const [confetti, setconfetti] = useState(false);
  const [cirCls, setCirCls] = useState("circleConfCls");
  const [popoup, setPopUp] = useState(true);
  useEffect(() => {
    if (firstNewCamp && selectedCampaign?.overview?.campId) {
      setconfetti(true);
      dispatch(resetFirstNewCamp());
      setTimeout(() => setCirCls("circleConfClsFd"), 6000);
      setTimeout(() => setPopUp(false), 7000);
    }
  }, [firstNewCamp, selectedCampaign]);

  useEffect(() => {
    if (campLookingAtNowId !== selectedCampaignId) {
      dispatch(setCampLookingAtNowId(selectedCampaignId));
    }
  }, [selectedCampaignId]);

  const [activeTab, setActiveTab] = useState("Overview");
  const [gotRefreshedData, setGotRefreshedData] = useState(false);
  // const campRecord =
  //   selCampInfo?.overview?.campId === campaignDisplaying?.campaign?.id
  //     ? selCampInfo
  //     :  (outreachCampaignsList || [])?.find((oc) => oc?.overview?.campId === campaignDisplaying?.campaign?.id);

  const refreshanalytics = () => {
    setGotRefreshedData(false);
    dispatch(getEachStepsAnalytics({ campId: campaignDisplaying?.campaign?.id }));
  };
  const bottomHeaderContent = (
    <>
      {/* <TabsSwitch firstTab={"Overview"} secondTab={"Emails"} thirdTab={"Phone"} activeTab={activeTab} setActiveTab={setActiveTab}/> TODONN bring back later */}
      <div />
      {payingClient || trialClient ? (
        <Flex style={{ width: "fit-content", justifyContent: "space-between", alignItems: "center" }}>
          {" "}
          <Button className="link" style={{ marginRight: "20px" }} onClick={() => (!loadingGetEachStepsAnalytics ? refreshanalytics() : undefined)}>
            {loadingGetEachStepsAnalytics ? <Loader size={"small"} /> : <SvgIcon type={"refresh"} width={12} height={12} />}
            Refresh analytics
          </Button>
          <Button className={"primary"} onClick={() => dispatch(setOpenCampSettings(true))}>
            {!selectedCampaign?.overview?.isActive ? `Activate campaign` : `Campaign Settings`}
          </Button>
        </Flex>
      ) : (
        <Tooltip placement={"top"} title={<>Upgrade account to activate the personalized outreach sequence </>}>
          <Button className={"primary"} disabled>
            {`Activate campaign`}
          </Button>
        </Tooltip>
      )}
    </>
  );

  const phoneStatistic = <StatisticsBar uniqId={1} data={phoneCallsStatisticData} />;
  const emailsStatistic = <StatisticsBar uniqId={2} data={statisticEmailsData} />;

  const handleCloseSetMod = () => {
    dispatch(setOpenCampSettings(false));
  };

  const [frmtdSeq, setFrmtdSeq] = useState([]);
  // const [, setLoading] = useState(false);

  const client = useSelector(userSelector);
  // const payingClient = client?.new_payment_status!=='churned' && client?.new_subscription_plans

  useEffect(() => {
    if (campLookingAtNowId && (!campaignDisplaying?.campaign?.id || campaignDisplaying?.campaign?.id !== campLookingAtNowId)) {
      dispatch(getCampaignDetails({ campaignId: campLookingAtNowId }));
    }
    // if(campaignDisplaying?.campaign?.id && (!frmtdSeq?.id || frmtdSeq?.id!==campaignDisplaying?.campaign?.id)){
    if (campaignDisplaying?.campaign?.id && (campaignDisplaying?.campaign?.id === campLookingAtNowId || !gotRefreshedData)) {
      setGotRefreshedData(true);
      setFrmtdSeq(fixOutreachForFrontEnd(campaignDisplaying) || []);
    } else {
      setFrmtdSeq([]);
    }
  }, [campLookingAtNowId, campaignDisplaying, loadingGetEachStepsAnalytics]);

  return (
    <PageLayout
      pageTitle={
        selectedCampaign && (
          <PageTitle
            title={selectedCampaign?.overview?.campName}
            onClick={() => navigate("/campaigns")}
            status={selectedCampaign?.overview?.isActive}
            hasAction
          />
        )
      }
      rightHeaderContent={bottomHeaderContent}
      statistic={activeTab === "Phone" ? phoneStatistic : activeTab === "Emails" ? emailsStatistic : null}
      isTableContent={activeTab !== "Overview"}
    >
      {
        // for first time campaigns
        confetti && (
          <>
            {" "}
            <Confetti recycle={false} style={{ zIndex: 9999, position: "fixed", top: 0, left: 0 }} />
            {popoup && (
              <div className={cirCls}>
                <section className="headerConfCls">
                  <div className="title-wrapperConfCls">
                    <h1 className="sweet-titleConfCls">
                      <span data-text="Meetz AI">Meetz AI</span>
                      <span data-text="Campaign">Campaign</span>
                    </h1>
                    <span className="top-titleConfCls">You Just Made Your First</span>
                    <span className="bottom-titleConfCls">Congrats! Now let's get you clients!</span>
                  </div>
                </section>
              </div>
            )}
          </>
        )
      }
      {(() => {
        switch (activeTab) {
          case "Emails":
            return <EmailsTab loading={loadingCampLookingAt} />;
          case "Phone":
            return <PhoneTab loading={loadingCampLookingAt} />;
          default:
            return <OverviewTab frmtdSeq={frmtdSeq} loading={loadingCampLookingAt} payingClient={payingClient || trialClient} />;
        }
      })()}
      {/* <GeneralSettingsModal open={openSettingsModal} onClose={handleCloseSetMod} /> */}
      {/* openSettingsModal */}
    </PageLayout>
  );
};
