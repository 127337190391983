import { Typography } from "antd";
import { FC, useContext } from "react";
import { DealsContext } from "../../../../app/context/DealsContext";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

interface DeleteDealModalProps {
  onClose: () => void;
  deleteThisDeal: () => void;
}

export const DeleteDealModal: FC<DeleteDealModalProps> = ({ deleteThisDeal, onClose }) => {
  // const { deleteDeal } = useContext(DealsContext)

  const handleSubmitBtnClick = () => {
    deleteThisDeal();
    // onClose()
  };

  return (
    <Modal
      open={true}
      title={"Are you sure you want to delete this deal?"}
      onCancel={onClose}
      rootClassName={"delete-deal-modal"}
      style={{ zIndex: 30000 }}
      // onDropdownClick={(event) => {
      //   event.stopPropagation();
      // }}
    >
      <Typography.Paragraph className={"description"}>This action will permanently remove the deal, and can't be undone.</Typography.Paragraph>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  );
};
