import { Divider, Flex, Row, Tooltip, Typography } from "antd";
import { FC, useState } from "react";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachEmailsSelector } from "src/store/outreach-emails";
import Loader from "../Loader";
import { getUnifiedInboxAll } from "src/store/outreach-emails/asyncRequests";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { getPhoneAnalytics } from "src/store/outreach-phones/asyncRequests";

export type StatisticItem = {
  id: number;
  value: number;
  title: string;
};

interface StatisticProps {
  campaignId: "all" | number;
  dataType: "email" | "phone" | "campaignOverview";
  uniqId?: number;
  activeId;
  setActiveId;
}
//  TODOF add dates like last 30 days this will make it a quicker load
export const StatisticsBar: FC<StatisticProps> = ({ campaignId, dataType, uniqId, activeId, setActiveId }) => {
  const { outreachCampaignsCnt } = useSelector(outreachCampaignSelector);
  const { analyticsBarAll, analyticsBarCampaign, dataAll, dataCamp, analyticDates } = useSelector(outreachEmailsSelector);
  const { analyticsBarAll: dialerAnalyticsBarAll, dataAll: phoneAll, dataCamp: phoneCamp, phoneLoading } = useSelector(outreachPhonesSelector);

  // if (dataType === "campaignOverview") {
  // }
  const dispatch = useDispatch();
  const data =
    dataType === "email"
      ? campaignId === "all"
        ? [...analyticsBarAll]
        : campaignId === analyticsBarCampaign?.id
        ? [...analyticsBarCampaign]
        : []
      : dataType === "campaignOverview"
      ? outreachCampaignsCnt
      : [...dialerAnalyticsBarAll];

  const handleEmailButtonClick = (analyticToGet: "email_sent" | "email_opened" | "email_clicked" | "likely_forwarded" | "email_replied" | "meeting_booked") => {
    if (analyticToGet === activeId) {
      return;
    }
    dispatch(
      getUnifiedInboxAll({
        analyticToGet,
        page: 1,
        dateRange: [],
        campaignId,
        start: analyticDates?.[0] || null,
        end: analyticDates?.[1] || null,
      })
    );
    //  call backedn to get it
    setActiveId(analyticToGet);
    //  add logic external to here to handle the data
  };

  const handlePhoneButtonClick = (analyticToGet: "email_sent" | "email_opened" | "email_clicked" | "likely_forwarded" | "email_replied" | "meeting_booked") => {
    if (analyticToGet === activeId) {
      return;
    }
    dispatch(getPhoneAnalytics({ analyticToGet, page: 1, dateRange: [], campaignId }));
    //  call backedn to get it
    setActiveId(analyticToGet);
    //  add logic external to here to handle the data
  };

  // email 'sends' 'opens' 'clicks' 'forwarded' 'replies' 'booked'

  // console.log(data, "analyticsBarAll");
  // data first one should be all email or all phoen TODO

  const loadingEmailResults =
    dataType === "email" ? (campaignId === "all" ? !dataAll?.campaignId : !dataCamp?.campaignId) : dataType === "phone" ? phoneLoading : false;
  // phoneAll;
  // phoneCamp;
  return (
    <Row key={uniqId} className={"statistics-wrapper"} justify={"space-around"} align={"middle"} wrap={false}>
      {data?.length
        ? data.map((item, index) => {
            return (
              <>
                <Row
                  key={`${index}`}
                  className={`statistics-btn ${activeId && item.id === activeId ? "active" : ""}`}
                  align={"middle"}
                  style={{ pointerEvents: loadingEmailResults || dataType === "campaignOverview" ? "none" : "auto" }}
                  onClick={() => {
                    if (dataType === "email") {
                      handleEmailButtonClick(item.id);
                    }
                    if (dataType === "phone") {
                      handlePhoneButtonClick(item.id);
                    }
                  }}
                >
                  {" "}
                  <Tooltip placement={"top"} title={item?.explanation}>
                    <Typography.Paragraph className={"statistics-value"}>{item.value || 0}</Typography.Paragraph>{" "}
                  </Tooltip>
                  {/* <div> */}
                  {/* </div> */}
                  <Typography.Paragraph className={"statistics-title"} style={{ display: "flex" }}>
                    {item.title}
                    {loadingEmailResults && item.id === activeId && <Loader style={{ marginLeft: "12px", backgroundColor: "red" }} size="small" />}
                  </Typography.Paragraph>
                  <Flex>
                    {item?.extrVal1 && (
                      <Tooltip placement={"bottom"} title={item?.extrVal1Explanation}>
                        {" "}
                        <Typography.Paragraph className={"statistics-value-percentage"}>{item?.extrVal1}</Typography.Paragraph>
                      </Tooltip>
                    )}
                    {item?.extraVal2 && (
                      <Tooltip placement={"bottom"} title={item?.extrVal2Explanation}>
                        {" "}
                        <Typography.Paragraph className={"statistics-value-percentage"}>{item?.extraVal2}</Typography.Paragraph>
                      </Tooltip>
                    )}
                    {item?.extrVal3 && (
                      <Tooltip placement={"bottom"} title={item?.extrVal3Explanation}>
                        {" "}
                        <Typography.Paragraph className={"statistics-value-percentage"}>{item?.extrVal3}</Typography.Paragraph>
                      </Tooltip>
                    )}
                    {item?.extraVal4 && (
                      <Tooltip placement={"bottom"} title={item?.extrVal4Explanation}>
                        {" "}
                        <Typography.Paragraph className={"statistics-value-percentage"}>{item?.extraVal4}</Typography.Paragraph>
                      </Tooltip>
                    )}
                  </Flex>
                </Row>
                {data.length !== index + 1 ? <Divider type={"vertical"} key={`${item.title}-${index}`} style={{ height: 56 }} /> : null}
              </>
            );
          })
        : (dataType === "email"
            ? [
                { id: "email_sent", title: "Total Sends", value: "loading", explanation: `Total campaign email sends` },
                { id: "email_opened", title: "Total Opens", value: "loading", explanation: `Total number of prospect email opens, excluding your own` },
                { id: "email_clicked", title: "Total Clicks", value: "loading", explanation: `Total clicks on links in your email` },
                { id: "likely_forwarded", title: "Likely Forwarded", value: "loading", explanation: `Unique contacts that likely forwarded your email` },
                { id: "email_replied", title: "Total Replies", value: "loading", explanation: `Total email replies` },
                { id: "meeting_booked", title: "Booked Meetings", value: "loading", explanation: `Total booked meetings with Meetz links` },
              ]
            : dataType === "campaignOverview"
            ? [
                {
                  title: "Active Campaigns",
                  // value: 0,
                },
                {
                  title: "Total Campaigns",
                  // value: 0,
                },
              ]
            : [
                {
                  id: "phone_out",
                  title: "Outgoing Calls",
                  value: "loading",
                  explanation: `Total number of outgoing calls made`,
                  extrVal1: `0 Conv.`,
                  extrVal1Explanation: `Number of answered calls that led to a conversation (At least 15 seconds).`,
                  extraVal2: `0% CR`,
                  extrVal2Explanation: `Percentage of conversations out of the total attempted calls.`,
                  extrVal3: `0 UC`,
                  extrVal3Explanation: `Unique prospects called, counting only the number of different prospects called.`,
                  extraVal4: `0% ACP`,
                  extrVal4Explanation: `Average number of calls per prospect.`,
                },
                {
                  id: "phone_in",
                  title: "Incoming Calls",
                  value: "loading",
                  explanation: `Total number of incoming calls received`,
                  extrVal1: `0 UC`,
                  extrVal1Explanation: `Unique prospects who called, only counts how many prospects called. Not how many times they called.`,
                },

                { id: "good_call", title: "Good Calls", value: "loading", explanation: `Total number of calls with a positive outcome` },
                { id: "bad_call", title: "Bad Calls", value: "loading", explanation: `Total number of calls with a negative outcome` },
              ]
          ).map((item, index) => {
            return (
              <>
                <Row
                  key={`${index}`}
                  className={`statistics-btn ${item.id === activeId ? "active" : ""}`}
                  align={"middle"}
                  onClick={() => {
                    if (dataType === "email") {
                      handleEmailButtonClick(item.id);
                    }
                  }}
                >
                  {" "}
                  <Tooltip placement={"top"} title={item?.explanation}>
                    <Typography.Paragraph className={"statistics-value"} style={{ height: "30px", paddingTop: "10px" }}>
                      {<Loader />}
                    </Typography.Paragraph>{" "}
                  </Tooltip>
                  <Typography.Paragraph className={"statistics-title"}>{item.title}</Typography.Paragraph>
                  {/* <Flex> */}
                  {/* {item?.extraVal1 && (
                      <Tooltip placement={"bottom"} title={item?.extraVal1Explanation}>
                        {" "}
                        <Typography.Paragraph className={"statistics-value-percentage"}>{item?.extraVal1}</Typography.Paragraph>
                      </Tooltip>
                    )} */}
                  {/* <Typography.Paragraph className={"statistics-value-percentage"}>3.1% PFS</Typography.Paragraph> */}
                  {/* </Flex> */}
                </Row>
                {6 !== index + 1 ? <Divider type={"vertical"} key={`${item.title}-${index}`} style={{ height: 56 }} /> : null}
              </>
            );
          })}
    </Row>
  );
};
// TODOf add order by and also ability to select prospects
