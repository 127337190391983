/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Col, CollapseProps, Flex, Input, List, Menu, Radio, Row, Select, Space, Switch, Tooltip, Typography, message } from "antd";
import { decode as atob, encode as btoa } from "base-64";
import Sider from "antd/es/layout/Sider";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, ConfirmButtons, CustomLabel, CustomSelect, Modal, TextEditor } from "src/components/ui";
import { AppDispatch } from "src/store";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { generateOneAiEmail, getCampInfo, getFilteredLeadsRst, updateCampaignSettings } from "src/store/outreach-campaigns/asyncRequests";
import {
  CheckCircleOutlined,
  MailOutlined,
  AudioOutlined,
  PhoneOutlined,
  FileOutlined,
  ProfileOutlined,
  AppstoreOutlined,
  SafetyOutlined,
  HddOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { SvgIcon } from "src/shared/icons";
import { useNavigate, useParams } from "react-router";
import { ManageEmailDrawer } from "src/components/pages/emails-data";
import { outreachEmailsSelector } from "src/store/outreach-emails";
import dayjs from "dayjs";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { AddNumberDrawer, AddNumberModal } from "src/components/pages/phone-calls";

// type ViewAiEmailModalProps = {
//   open: boolean
//   isAuto: boolean
//   onCancel: VoidFunction
//   prospectId:number
// }

const { Text } = Typography;
const { Option } = Select;
export const CampApproveModal = ({ open, onCancel, tabAppModal, emailStepsOptions, setTabAppModal, phoneStepsOptions }) => {
  console.log(emailStepsOptions, "emailStepsOptions", phoneStepsOptions);
  const [prospects, setProspects] = useState(true);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState();
  const [useLinkedin, setUseLinkedin] = useState();
  const [useCompany, setUseCompany] = useState();
  const [isActive, setIsActive] = useState(false);
  const [useAiVoice, setUseAiVoice] = useState(false);
  const emailOptions = ["email1@example.com", "email2@example.com", "email3@example.com"];
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClsDrawer = () => {
    setOpenDrawer(false);
  };
  const handleEmailChange = (selected) => {
    // console.log(selected, "selected");
    setSelectedEmails(selected);
  };
  const handlePhoneChange = (selected) => {
    // console.log(selected, "selected");
    setSelectedPhone(selected);
  };
  const handlePersLinkedinChange = (selected) => {
    // console.log(selected, "selected");
    setUseLinkedin(selected);
  };
  const handlePersCompChange = (selected) => {
    // console.log(selected, "selected");
    setUseCompany(selected);
  };
  const handleChangeAiVoiceRecognition = (value) => {
    setUseAiVoice(value);
  };
  const handleCheckboxChange = (e) => {
    setIsActive(e);
  };
  // tabAppModal or app setUp
  const closeModal = () => {
    onCancel();
  };

  const handleFormSubmit = () => {
    console.log("Created");
    onCancel();
  };

  const [pros, setPros] = useState({});

  const { campaignDisplaying, eachStepsAnalytics, loadingGenAiForPros, outreachCampaignsList, selCampInfo, selCampInfoLoading } =
    useSelector(outreachCampaignSelector);

  const amntToCallAtSameTimeOptions = [
    {
      label: "1 prospect at a time",
      value: 1,
    },
    {
      label: "2 prospects",
      value: 2,
    },
    {
      label: "3 prospects",
      value: 3,
    },
    {
      label: "4 prospects",
      value: 4,
    },
    {
      label: "5 prospects",
      value: 5,
    },
    {
      label: "10 prospects",
      value: 10,
    },
    {
      label: "15 prospects",
      value: 15,
    },
    {
      label: "20 prospects",
      value: 20,
    },
  ];
  const phoneTypes = [
    {
      label: "Company number",
      value: "business_number",
    },
    {
      label: "Mobile number",
      value: "mobile_phone",
    },
    {
      label: "Direct number",
      value: "direct_phone",
    },
  ];

  // console.log(eachStepsAnalytics, "eachStepsAnalytics");

  //   useEffect(() => {
  //     let found = false;

  //   for (const key in eachStepsAnalytics) {
  //     // console.log(key,'etsaojkfn',eachStepsAnalytics);
  //     if (eachStepsAnalytics[key] && (eachStepsAnalytics[key]?.prospectReadyStepCount || eachStepsAnalytics[key]?.prospectNotReadyYetCount)) {
  //       found= true
  //       // for (let i = 0; i < eachStepsAnalytics[key].firstTenProsReady.length; i++) {
  //       //   // console.log(`12312312312Found: ${eachStepsAnalytics[key][i]}`);
  //       //   const pros = eachStepsAnalytics[key].firstTenProsReady[i]
  //       // // console.log(pros,'12312312312');
  //       //   // if(pros.id===prospectId){
  //       //     setPros(pros)
  //       //   found = true;
  //       //   break;
  //       // // }
  //       // }
  //     }
  //   if (found) {
  //     break;
  //   }
  // }
  // setProspects(found)
  // }, [eachStepsAnalytics]);

  const emitems: CollapseProps["items"] = [
    {
      key: "1",
      label: "Why are my emails not being sent right now?",
      children: (
        <>
          1. Check that this campaign is active
          <br />
          2.
        </>
      ),
    },
    //   {
    //     key: "2",
    //     label: "Can I send emails from this window?",
    //     children:
    //       <>No, you can't send emails from this window. {isAuto? `Meetz will automatically send out the email when it's the prospects turn to get it. Meetz sends out emails slowly to ensure you don't get into the spam folder, click on "Manage Emails" if you want to increase or decrease daily limits.` :`You need to approve this step in order for the Ai Personalized Emails to be generated and sent out.` }
    //     </>
    //   },
    //   {
    //     key: "3",
    //     label: "How to change the output?",
    //     children:
    //       <>Ai personalized emails are generated on an individual level based on a few different sources including your prospects LinkedIn activity. If you want to change the style of the Ai personalized email click on "Edit Sequence" and edit the Ai output.
    //     </>
    //   },
    //   {
    //     key: "4",
    //     label: "What if I want an email template that doesn't change?",
    //     children:
    //       <>That's what the Templated Email steps are for. 🙂<br /><br />In that case go to "Edit Sequence" remove the Ai Personalized Email step and add the Templated Email.
    //     </>
    //   },
    //   {
    //     key: "5",
    //     label: "What if I run out of Ai Personalized Emails mid campaign?",
    //     children:
    //       <>There's a backup templated email that will be sent out instead of th
    //     </>
    //   },
  ];

  const [priority, setPriority] = useState();

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };

  const dispatch = useDispatch<AppDispatch>();

  // const genAiEmail = () => {
  //   // message.info('sdafdkaslfn')
  // dispatch(generateOneAiEmail({prosId:prospectId}))
  // }

  const [selectedKey, setSelectedKey] = useState("1");
  const handleMenuClick = (e) => {
    setTabAppModal(e.key);
  };
  const { additionalEmails } = useSelector(outreachEmailsSelector);
  const [emailsData, setEmailsData] = useState<any>([]);
  useEffect(() => {
    // console.log(additionalEmails, "additionalEmails");
    setEmailsData(
      [...additionalEmails]
        ?.sort((a, b) => {
          return a?.id - b?.id;
        })
        ?.filter((em) => em.connected)
        ?.map((em) => (em?.smtp?.from?.length ? em?.smtp?.from?.toLowerCase() : em?.email_alias_emailofcal?.toLowerCase()))
    );
  }, [additionalEmails]);
  const { phone_numbers } = useSelector(outreachPhonesSelector);
  const [phonesData, setPhonesData] = useState<any>([]);
  // const test = ["+19855204080"];
  useEffect(() => {
    // console.log(phonesData, "phonesData");
    const actPhoneNums = [...phone_numbers]
      ?.sort((a, b) => {
        return a?.id - b?.id;
      })
      ?.filter((num) => num.is_active)
      ?.map((ph) => ({
        label: `${ph?.phone_number} ${
          selectedCampaign?.phone_numbers_used?.includes(ph?.phone_number) && phonesData?.length > 1 ? `Used in this camp. before` : ""
        }`,
        value: ph?.phone_number,
      }));

    setPhonesData(actPhoneNums);
  }, [phone_numbers]);

  const tagRender = () => {
    return (
      <span>
        {selectedEmails.length} email{selectedEmails.length !== 1 ? "s" : ""} selected
      </span>
    );
  };
  const tagRenderTypes = () => {
    return (
      <span>
        {phoneTypesToCall.length} type{phoneTypesToCall.length !== 1 ? "s" : ""} selected
      </span>
    );
  };

  const { id } = useParams();
  const selectedCampaignId = (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000;
  const selectedCampaign =
    selCampInfo?.overview?.campId === selectedCampaignId
      ? selCampInfo
      : (outreachCampaignsList || [])?.find((item) => +item?.overview?.campId === selectedCampaignId);
  console.log(selCampInfo, "selCampInfo", selectedCampaign);
  useEffect(() => {
    if (outreachCampaignsList?.length && !selectedCampaign && !selCampInfoLoading) {
      dispatch(getCampInfo({ selectedCampaignId }));
    }
  }, [outreachCampaignsList]);

  // console.log(selectedCampaign, "selectedCampaign");
  const [selectedNewPriority, setSelectedNewPriority] = useState("");
  const handlePriorityCampChange = (value) => {
    setSelectedNewPriority(value);
  };
  const [phoneTypesToCall, setPhoneTypesToCall] = useState([]);
  const handlePhoneTypesToCall = (value) => {
    setPhoneTypesToCall(value);
  };

  const [amntToCallAtSameTime, setAmntToCallAtSameTime] = useState();
  const handleAmntToCallChange = (value) => {
    setAmntToCallAtSameTime(value);
  };

  const isCampActive = selectedCampaign?.overview?.isActive;
  // const [selectedKey, setSelectedKey] = useState('1');
  const [isDisabledCamp, setIsDisabledCamp] = useState(false);
  const [priorCampName, setPriorCampName] = useState(null);
  const [thisisPriorCamp, setThisisPriorCamp] = useState(false);

  const [schedAvail, setSchedAvail] = useState<any>([]);
  const [emSched, setEmSchedTimezone] = useState<any>(null);

  const { additionalEmailsSched, additionalEmailsTimezone } = useSelector(outreachEmailsSelector);
  useEffect(() => {
    if (additionalEmailsTimezone?.length) {
      // console.log(additionalEmailsTimezone, "additionalEmailsTimezone");
      setEmSchedTimezone(additionalEmailsTimezone);
    }
  }, [additionalEmailsTimezone]);
  useEffect(() => {
    setSchedAvail(
      Object.fromEntries(
        Object.entries(additionalEmailsSched).map(([key, value]) => [
          key,
          value.map((timerange) => [dayjs(timerange.start, "HH:mm"), dayjs(timerange.end, "HH:mm")]),
        ])
      )
    );
    // setSchedAvail(Object.fromEntries(Object.entries(additionalEmailsSched).map(([key, value]) => [key, value.map(timerange => ([timerange.start, timerange.end] ))])))
  }, [additionalEmailsSched]);

  const [isWithinSchedule, setIsWithinSchedule] = useState(false);
  // console.log(isWithinSchedule, "isWithinSchedule2");
  useEffect(() => {
    const isCurrentTimeInSchedule = (schedAvail) => {
      const currentTime = new Date();
      const defaultTimezone = emSched || Intl.DateTimeFormat().resolvedOptions().timeZone;
      // console.log(defaultTimezone, "def");
      const dayOfWeek = currentTime.toLocaleString("en-US", { weekday: "long", timeZone: defaultTimezone }).toLowerCase();

      if (schedAvail[dayOfWeek]) {
        const daySchedule = schedAvail[dayOfWeek];
        return daySchedule.some(([start, end]) => {
          const startTime = new Date(start);
          const endTime = new Date(end);
          const currentLocalTime = currentTime.toLocaleString("en-US", { timeZone: defaultTimezone });

          return new Date(startTime) <= new Date(currentLocalTime) && new Date(currentLocalTime) <= new Date(endTime);
        });
      }
      return false;
    };

    setIsWithinSchedule(isCurrentTimeInSchedule(schedAvail));
  }, [schedAvail, emSched, open]);

  const navigate = useNavigate();
  // console.log({ schedAvail, emSched }, "email schedule");

  useEffect(() => {
    let prioritizedCampName = null;
    (outreachCampaignsList || [])?.forEach((camp) => {
      if (camp?.overview?.prioritizeThisCamp) {
        prioritizedCampName = camp?.overview?.campName;
        // console.log(camp,'selectedCampaignIdselectedCampaignId',selectedCampaignId);
        if (camp?.overview?.campId === selectedCampaignId) {
          setThisisPriorCamp(true);
        }
      }
    });
    setPriorCampName(prioritizedCampName);
  }, [outreachCampaignsList, selectedCampaignId]);
  // console.log(emailStepsOptions, "emailStepsOptions");
  useEffect(() => {
    const canBeActive = (selectedEmails?.length || emailStepsOptions?.length <= 1) && prospects;
    if (canBeActive && selectedCampaign?.overview?.isActive) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    setIsDisabledCamp(!canBeActive);
  }, [prospects, selectedEmails, selectedCampaign]);

  useEffect(() => {
    if (emailsData?.length && open) {
      // make sure is loaded

      const origActive = !!selectedCampaign?.overview?.isActive;
      const emailUsed = selectedCampaign?.overview?.emailsToSendWith || [];

      const availEmailsSelected = emailsData?.filter((emAvail) => emailUsed?.some((em) => em?.toLowerCase() === emAvail));

      const canBeActive = (selectedEmails?.length || emailStepsOptions?.length <= 1) && prospects;
      if (!canBeActive && origActive) {
        setIsActive(false);
      } else {
        setIsActive(origActive);
      }
      if (availEmailsSelected?.length) {
        console.log(availEmailsSelected, "availEmailsSelected");
        setSelectedEmails(availEmailsSelected);
      }

      setIsDisabledCamp(!canBeActive);
      // first set emails. then set the active otherwise it will trigger logic to change without client wanting the change
    }
  }, [selectedCampaign, open, emailsData]);

  useEffect(() => {
    console.log(selectedCampaign?.overview, "selectedCampaign?.overview?.phone_types_to_call");
    // other logic to add values
    setProspects(!!selectedCampaign?.overview?.prospectsInCamp);
    setPriority(selectedCampaign?.overview?.prioritizeFollowUp ? "followUps" : "newEmail");
    setUseAiVoice(selectedCampaign?.overview?.on_human_answer);
    setSelectedNewPriority(selectedCampaign?.overview?.prioritizeThisCamp ? "Prioritize" : "notPrioritized");
    setAmntToCallAtSameTime(selectedCampaign?.overview?.calls_at_the_same_time);
    setPhoneTypesToCall(selectedCampaign?.overview?.phone_types_to_call);
    setSelectedPhone(selectedCampaign?.overview?.phone_number_using_now);
    setUseLinkedin(selectedCampaign?.overview?.scrape_linkedin);
    setUseCompany(selectedCampaign?.overview?.scrape_company);
  }, [selectedCampaign, open]);

  const saveSettings = () => {
    dispatch(
      updateCampaignSettings({
        selEmailsInCamp: selectedEmails,
        isActive,
        turnOnPriority: selectedNewPriority === "Prioritize",
        priority,
        selectedCampaignId,
        amntToCallAtSameTime,
        selectedPhone,
        useLinkedin,
        useCompany,
        useAiVoice,
        phoneTypesToCall,
      })
    );
    // selectedNewPriority===Prioritize not always loaded so thats why we check if to change it. it cant be turned to false for now, can add  that later but dont see point 1 campaign should always be prioritized
    message.info("Updating");
    onCancel();
  };

  const [isAddNumberDrawerOpen, setIsAddNumberModalOpen] = useState(false);
  const showAddNumberModal = () => {
    setIsAddNumberModalOpen(true);
  };
  const handleAddNumberModalClose = () => {
    setIsAddNumberModalOpen(false);
  };

  return (
    <Modal
      width={880}
      open={open}
      title={<>Campaign Settings - {selectedCampaign?.overview?.isActive ? "Active Campaign" : <span style={{ color: `#FAAD14` }}>Inactive Campaign</span>}</>}
      onCancel={closeModal}
    >
      {/* <hr style={{height:'.5px',color:'lightgrey'}}/> */}
      <Flex style={{ borderTop: "1px solid #eaeaea", height: 300, alignItems: "flex-start" }}>
        {/* <Sider width={300} className="site-layout-background"> */}

        {/* <div  style={{ height: '100%', borderRight: 0,marginRight:'auto',backgroundColor:'red' }} > */}
        <div style={{ borderRight: "1px solid #eaeaea", height: "100%" }}>
          <Menu
            mode="inline"
            selectedKeys={[tabAppModal]}
            onClick={handleMenuClick}
            style={{ height: "100%", borderRight: 0, alignSelf: "flex-start", padding: 0, width: "fit-content", marginRight: "20px" }}
          >
            <Menu.Item key="app" icon={<CheckCircleOutlined />} style={{ paddingLeft: 3 }}>
              Activate Campaign
            </Menu.Item>{" "}
            <Menu.Item key="personalization" icon={<FileSearchOutlined />} style={{ paddingLeft: 3 }}>
              Personalization Settings
            </Menu.Item>
            {emailStepsOptions?.length > 1 && (
              <Menu.Item key="emSetUp" icon={<MailOutlined />} style={{ paddingLeft: 3 }}>
                Campaign's Email Settings
              </Menu.Item>
            )}
            {phoneStepsOptions?.length > 1 && (
              <Menu.Item key="phoneSteps" icon={<PhoneOutlined />} style={{ paddingLeft: 3 }}>
                Campaign's Dialer Settings
              </Menu.Item>
            )}
          </Menu>
        </div>
        {/* </div> */}
        <div
          // direction="vertical"
          // size={12}
          style={{
            padding: "0 10px 10px 20px",
            // border: '1px solid #eaeaea',
            // borderRadius: '8px',
            // marginBottom: '12px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            direction: "column",

            // flexGrow:1
          }}
        >
          {tabAppModal === "app" && (
            <div style={{ width: "100%" }}>
              <p>Needed in order to activate campaign</p>
              {/* <ol>
                  s
                </ol> */}
              <List style={{ width: "100%" }}>
                <List.Item>
                  {" "}
                  <Flex justify={"center"}>
                    {" "}
                    <div> 1.</div>
                    <div style={{ color: prospects ? "#464A57" : "#FAAD14", marginLeft: "10px", display: "flex", alignItems: "center", height: "fit-content" }}>
                      {!prospects && <SvgIcon type={"warning"} style={{ width: "14px", height: "14px", marginRight: "7px" }} />}Prospects in campaign{" "}
                      {!!prospects && <SvgIcon type={"checked"} style={{ marginLeft: "7px" }} />}
                    </div>
                    <Button
                      className="link"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        dispatch(
                          getFilteredLeadsRst({
                            uploadName: "All",
                            recentlyUpdated: false,
                            includeInCampaign: false,
                            includeContactedAlready: false,
                            page: 1,
                            pageChange: false,
                            selectedCampaignId: null,
                          })
                        );
                        navigate(`/campaign/${id}/prospects`);
                      }}
                    >
                      {" "}
                      Add prospects
                    </Button>
                  </Flex>
                  {/* TODOF later add addprospects table */}
                </List.Item>
                {/* <List.Item>
                  <Flex style={{ width: "100%" }}>
                    {" "}
                    2.
                    <div style={{ color: prospects ? "#000" : "#FAAD14", marginLeft: "7px", display: "flex", alignItems: "center", height: "fit-content" }}>
                      {!prospects && <SvgIcon type={"warning"} style={{ width: "14px", height: "14px", marginRight: "7px" }} />}Campaign steps are set up
                      properly {!!prospects && <SvgIcon type={"checked"} style={{ marginLeft: "7px" }} />}
                    </div>
                    <Button
                      className="link"
                      style={{ marginLeft: "auto" }}
                      onClick={() => {
                        dispatch(
                          getFilteredLeadsRst({
                            uploadName: "All",
                            recentlyUpdated: false,
                            includeInCampaign: false,
                            includeContactedAlready: false,
                            page: 1,
                            pageChange: false,
                            selectedCampaignId: null,
                          })
                        );
                        navigate(`/campaign/${id}/prospects`);
                      }}
                    >
                      {" "}
                      Add prospects
                    </Button>
                  </Flex>
                </List.Item> */}
                {emailStepsOptions?.length > 1 && (
                  <List.Item>
                    {/* <Text style={{ color: selectedEmails.length > 0 ? '#000' : 'red' }}> */}
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      2.{" "}
                      <Flex justify={"center"} style={{ color: selectedEmails.length > 0 ? "#464A57" : "#FAAD14", marginLeft: "5px" }}>
                        <Flex justify={"center"} align={"center"} style={{ height: "fit-content" }}>
                          {" "}
                          {!selectedEmails.length && <SvgIcon type={"warning"} style={{ width: "14px", height: "14px", marginRight: "7px" }} />}{" "}
                          <div style={{ height: "fit-content" }}> Your emails to send from</div>{" "}
                          {!!selectedEmails.length && <SvgIcon type={"checked"} style={{ marginLeft: "7px" }} />}
                        </Flex>
                      </Flex>
                    </div>
                    {/* </Text> */}
                    <CustomSelect
                      mode="multiple"
                      style={{ width: "250px", marginLeft: "auto" }}
                      placeholder="Select emails"
                      onChange={handleEmailChange}
                      options={emailsData}
                      tagRender={tagRender}
                      value={selectedEmails}
                      maxTagCount={0}
                      isSearchSelect={false}
                    />
                    <Button className="link" style={{ marginLeft: "8px" }} onClick={showDrawer}>
                      {" "}
                      Add emails
                    </Button>

                    {/* {emailOptions.map(email => (
            <Option key={email} value={email}>{email}</Option>
          ))}
        </CustomSelect> */}
                  </List.Item>
                )}
                <List.Item>
                  {/* <Text  > */}
                  {/* </Text> */}
                  <Flex style={{ justifyContent: "flex-start", alignItems: "flex-start" }}>
                    <div> {emailStepsOptions?.length > 1 ? 3 : 2}.</div>{" "}
                    <Switch
                      checked={isActive}
                      disabled={isDisabledCamp}
                      onChange={handleCheckboxChange}
                      style={{ marginLeft: "7px" }}
                      // checkedChildren="Activate Campaign" // Text shown when switch is on
                      // unCheckedChildren="Deactivate Campaign" // Text shown when switch is off
                    />
                    {/* <p>{isActive ? "Switch is ON" : "Switch is OFF"}</p> */}
                    {/* <Checkbox checked={isActive} disabled={isDisabledCamp} onChange={handleCheckboxChange} style={{ marginLeft: "7px", maxHeight: "20px" }}> */}
                    {/* { isActive ?  `Activated campaign` :  `Paused campaign` } {!!isDisabledCamp && '- Missing from above' } */}
                    <div style={{ marginLeft: "10px", height: "5px" }}>
                      {isActive ? (
                        <Flex justify={"center"} align={"center"} style={{ height: "fit-content" }}>
                          {" "}
                          <div style={{ height: "fit-content" }}> Activate campaign toggle</div> <SvgIcon type={"checked"} style={{ marginLeft: "7px" }} />
                        </Flex>
                      ) : (
                        <>
                          {" "}
                          <span style={{ color: "#FAAD14" }}>
                            Activate campaign toggle off - If left like this will{" "}
                            {selectedCampaign?.overview?.isActive ? "pause the campaign" : "keep the campaign paused ⛔"}{" "}
                          </span>
                        </>
                      )}
                      {!!isDisabledCamp && (
                        <>
                          {" "}
                          <br /> *Please fix the required fields above to activate the campaign.
                        </>
                      )}
                    </div>
                    {/* </Checkbox> */}
                  </Flex>
                </List.Item>
              </List>
            </div>
          )}
          {tabAppModal === "emSetUp" && (
            <div>
              <Flex>
                {" "}
                <p>Advanced Campaigns Email Settings</p>
                <Button className="link" onClick={showDrawer} style={{ marginLeft: "auto", marginTop: "13px" }}>
                  Edit Email Addresses
                </Button>
              </Flex>
              <List style={{ width: "100%", height: "275px", paddingRight: "10px", overflowY: "scroll" }}>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px", textAlign: "center" }}>
                    <Row justify="center" align="middle">
                      1.{" "}
                      <Col style={{ marginLeft: "15px" }}>
                        <Radio.Group onChange={handlePriorityChange} value={priority}>
                          <Radio value="followUps">
                            <Text>Prioritize Follow Ups</Text>
                          </Radio>
                          <Radio value="newEmail" style={{ marginLeft: "20px" }}>
                            <Text>Prioritize New Emails</Text>
                          </Radio>
                        </Radio.Group>
                      </Col>
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            Select whether to prioritize follow ups over sending out new emails to prospects that didn't get an email yet.
                            <br />
                            <br />
                            Recommended to keep it on follow ups so that your prospects steps will be on time.
                            <br />
                            <br /> Prioritizing new emails is used if you want to reach out to as many of your prospects as fast as possible with wider delays.
                            <br />
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                      </Tooltip>
                    </Row>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px" }}>
                    <Flex>
                      <div style={{ marginRight: "15px", marginTop: "5px" }}> 2.</div>
                      <div>
                        {" "}
                        Prospects in this campaign are{thisisPriorCamp ? "" : " not currently"} prioritized for emails{" "}
                        <Tooltip
                          placement={"top"}
                          title={
                            <>
                              Emails are by default first sent to your older campaigns, if you want to change this default you can prioritize any campaign.
                              <br />
                              <br />
                              Meetz will continue sending out emails to other campaigns if the max email addresses "sends per day" is not reached.
                              <br />
                              <br />* Only 1 campaign can be prioritized at a time
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                        </Tooltip>
                      </div>
                    </Flex>
                    {/* {thisisPriorCamp ? ``:  */}
                    <Flex style={{ marginLeft: "25px", marginTop: "15px" }}>
                      <div style={{ fontSize: "12px", paddingRight: "25px" }}>
                        {thisisPriorCamp ? (
                          `This campaign is being prioritized, Meetz will start with this campaign before sending to your other campaigns`
                        ) : priorCampName?.length ? (
                          <>Current Prioritized Campaign: {priorCampName}</>
                        ) : (
                          "Currently emails are evenly distributed across your campaigns by prioritizing older campaigns"
                        )}
                      </div>
                      <div style={{ marginRight: "15px" }}>
                        <Select style={{ width: "250px" }} placeholder="Select Priority" onChange={handlePriorityCampChange} value={selectedNewPriority}>
                          <Option value="Prioritize">Prioritize this campaign</Option>
                          <Option value="notPrioritized">Do not prioritize this campaign</Option>
                        </Select>
                      </div>
                    </Flex>
                    {/* }  */}
                  </div>
                </List.Item>
                {/* <List.Item>
       <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
          3. <Flex style={{marginLeft:'15px' }}>{!selectedEmails.length && <SvgIcon type={"warning"} style={{ width:'14px',height:'14px',marginRight:'7px',marginTop:'4px'}}/>} Emails to use in campaign</Flex></div>
         </Text> 
        <CustomSelect
          mode="multiple"
          style={{ width: '250px',marginLeft:'5px' }}
          placeholder="Select emails"
          onChange={handleEmailChange}
          options={emailsData}
          tagRender={tagRender}
          value={selectedEmails}
          maxTagCount={0}  
          isSearchSelect={false}
        /><Button className="link" style={{marginLeft:'8px'}} onClick={showDrawer}>  Add more</Button>
    </List.Item> */}
                <List.Item>
                  Emails are{isWithinSchedule ? "" : <span style={{ color: "#FAAD14", fontWeight: 600 }}> not</span>} scheduled to be sent right now{" "}
                  <Tooltip
                    placement={"top"}
                    title={
                      <>
                        At this time Meetz is {isWithinSchedule ? "in" : "out of"} your set working hours, if you want to edit your email sending schedule click
                        on the above "Edit Email Addresses" button and go to the "Email Sending Settings" tab and edit the sending schedule.
                      </>
                    }
                  >
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                  </Tooltip>
                </List.Item>
              </List>

              {/* FAQ:<br />
      <Collapse items={emitems}/> */}
            </div>
          )}
          {tabAppModal === "personalization" && (
            <div>
              <Flex>
                {" "}
                <p>How to personalize the outreach with Meetz?</p>
              </Flex>
              <List style={{ width: "100%", height: "275px", paddingRight: "10px", overflowY: "scroll" }}>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px", display: "flex", width: "100%" }}>
                    {/* <Row justify="center" align="middle"> */}
                    1. {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}> */}
                    {/* <Flex justify={"center"}> */}
                    <Flex justify={"center"} align={"center"} style={{ color: "#464A57", marginLeft: "5px", height: "23px" }}>
                      {" "}
                      {/* <div> */} {/* <div style={{ paddingBottom: "210px" }}> */} Personalize with your prospects LinkedIn profile{" "}
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            {" "}
                            Recommended to use: Meetz will get the prospects LinkedIn profile to check relevant posts or bio information to connect with your
                            value proposition. Then it will display that information when they answer + use it when draft Ai Emails.
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 3, cursor: "pointer" }} />
                      </Tooltip>
                      {/* </div> */}
                      {/* <div style={{ fontSize: "12px", paddingRight: "25px" }}>
                              Recommended to use: Meetz will get the prospects LinkedIn profile to check relevant posts or bio information to connect with your
                              value proposition. Then it will display that information when they answer + use it when draft Ai Emails. <br />
                            </div> */}
                      {/* </div>{" "} */}
                      {/* </Flex> */}
                    </Flex>
                    {/* </div> */}
                    {/* </Text> */}
                    <CustomSelect
                      mode="single"
                      style={{ width: "100px", marginLeft: "auto" }}
                      placeholder="Select yes or no"
                      onChange={handlePersLinkedinChange}
                      options={[
                        { value: true, label: "On" },
                        { value: false, label: "Off" },
                      ]}
                      // tagRender={tagRender}
                      value={useLinkedin}
                      maxTagCount={0}
                      isSearchSelect={false}
                    />
                    {/* </Row> */}
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px", display: "flex", width: "100%" }}>
                    {/* <Row justify="center" align="middle"> */}
                    2. {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}> */}
                    {/* <Flex justify={"center"}> */}
                    <Flex justify={"center"} align={"center"} style={{ color: "#464A57", marginLeft: "5px", height: "23px" }}>
                      {" "}
                      {/* <div> */} {/* <div style={{ paddingBottom: "210px" }}> */} Personalize with your prospect's company's information{" "}
                      <Tooltip
                        placement={"top"}
                        title={
                          <>
                            {" "}
                            Recommended to use: Meetz will get the prospects LinkedIn profile to check relevant posts or bio information to connect with your
                            value proposition. Then it will display that information when they answer + use it when draft Ai Emails.
                          </>
                        }
                      >
                        <SvgIcon type={"tooltip"} style={{ margin: 0, marginLeft: 3, cursor: "pointer" }} />
                      </Tooltip>
                      {/* </div> */}
                      {/* <div style={{ fontSize: "12px", paddingRight: "25px" }}>
                              Recommended to use: Meetz will get the prospects LinkedIn profile to check relevant posts or bio information to connect with your
                              value proposition. Then it will display that information when they answer + use it when draft Ai Emails. <br />
                            </div> */}
                      {/* </div>{" "} */}
                      {/* </Flex> */}
                    </Flex>
                    {/* </div> */}
                    {/* </Text> */}
                    <CustomSelect
                      mode="single"
                      style={{ width: "100px", marginLeft: "auto" }}
                      placeholder="Select yes or no"
                      onChange={handlePersCompChange}
                      options={[
                        { value: true, label: "On" },
                        { value: false, label: "Off" },
                      ]}
                      // tagRender={tagRender}
                      value={useCompany}
                      maxTagCount={0}
                      isSearchSelect={false}
                    />
                    {/* {!phonesData?.length && (
                      <Button className="link" style={{ marginLeft: "15px" }} onClick={showDrawer}>
                        {" "}
                        Add phones
                      </Button>
                    )} */}
                    {/* </Row> */}
                  </div>
                </List.Item>
                <br />
                <div style={{ color: "#464A57", fontSize: "12px", paddingRight: "25px" }}>
                  <br />
                  Recommended to turn both on. If no LinkedIn activity is relevant to your value proposition so the company information will be used. <br />
                </div>
                {/* <List.Item>
       <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
          3. <Flex style={{marginLeft:'15px' }}>{!selectedEmails.length && <SvgIcon type={"warning"} style={{ width:'14px',height:'14px',marginRight:'7px',marginTop:'4px'}}/>} Emails to use in campaign</Flex></div>
         </Text> 
        <CustomSelect
          mode="multiple"
          style={{ width: '250px',marginLeft:'5px' }}
          placeholder="Select emails"
          onChange={handleEmailChange}
          options={emailsData}
          tagRender={tagRender}
          value={selectedEmails}
          maxTagCount={0}  
          isSearchSelect={false}
        /><Button className="link" style={{marginLeft:'8px'}} onClick={showDrawer}>  Add more</Button>
    </List.Item> */}
              </List>

              {/* FAQ:<br />
      <Collapse items={emitems}/> */}
            </div>
          )}
          {tabAppModal === "phoneSteps" && (
            <div>
              <Flex>
                {" "}
                <p>Advanced Campaigns Dialer Settings</p>
                <Button className="link" onClick={showAddNumberModal} style={{ marginLeft: "auto", marginTop: "13px" }}>
                  {phone_numbers?.length ? `Edit Phone Settings` : `Add Phone Number`}
                </Button>
              </Flex>
              <List style={{ width: "100%", height: "275px", paddingRight: "10px", overflowY: "scroll" }}>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px", textAlign: "center" }}>
                    <Row justify="center" align="middle">
                      1.{" "}
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Flex justify={"center"} style={{ color: "#464A57", marginLeft: "5px" }}>
                          <Flex justify={"center"} align={"center"} style={{ height: "fit-content" }}>
                            {" "}
                            <div style={{ height: "fit-content" }}> Phone number to call with</div>{" "}
                          </Flex>
                        </Flex>
                      </div>
                      {/* </Text> */}
                      <CustomSelect
                        mode="single"
                        style={{ width: phonesData?.length > 1 && selectedCampaign?.phone_numbers_used?.length ? "300px" : "200px", marginLeft: "20px" }}
                        placeholder="Select phone number"
                        onChange={handlePhoneChange}
                        options={phonesData}
                        // tagRender={tagRender}
                        value={selectedPhone}
                        maxTagCount={0}
                        isSearchSelect={false}
                      />
                      {!phonesData?.length && (
                        <Button className="link" style={{ marginLeft: "15px" }} onClick={showDrawer}>
                          {" "}
                          Add phones
                        </Button>
                      )}
                    </Row>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px" }}>
                    <Flex>
                      <div style={{ marginRight: "5px", marginTop: "3.5px" }}> 2.</div>
                      <div>
                        {" "}
                        Amount of prospects to call at the same time with the parallel dialer
                        <Tooltip
                          placement={"top"}
                          title={
                            <>
                              {" "}
                              <b>FAQ:</b>
                              <br />
                              <br />
                              <b> 1. What happens when someone answers?</b> The first prospect to answer will be displayed on your screen with their
                              information. The Ai will give you a sentence you can choose to say at the start of the call.
                              <br />
                              <br />
                              <b> 2. What happens if more than 1 person answers?</b> It's very rare that more than 1 person will answer at the exact same time
                              since once someone answers other calls are ended. If it does happen you will know and have a notification on your screen so that
                              you can switch to the other call if you prefer.
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                        </Tooltip>
                      </div>
                    </Flex>
                    {/* {thisisPriorCamp ? ``:  */}
                    <Flex style={{ marginLeft: "25px", marginTop: "15px" }}>
                      <div style={{ fontSize: "12px", paddingRight: "25px" }}>
                        Meetz will call this many people when you click on "Activate Parallel Dialer" in the campaign. <br />
                      </div>
                      <div style={{ marginRight: "15px" }}>
                        <Select
                          style={{ width: "250px" }}
                          placeholder="Select Amount"
                          onChange={handleAmntToCallChange}
                          options={amntToCallAtSameTimeOptions}
                          value={amntToCallAtSameTime}
                        />
                      </div>
                    </Flex>
                    {/* }  */}
                  </div>
                </List.Item>{" "}
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px", textAlign: "center" }}>
                    <Row justify="center" align="middle">
                      3.{" "}
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Flex justify={"center"} style={{ color: "#464A57", marginLeft: "5px" }}>
                          <Flex justify={"center"} align={"center"} style={{ height: "fit-content" }}>
                            {" "}
                            <div style={{ height: "fit-content" }}> Prospect phone numbers to call</div>{" "}
                          </Flex>
                        </Flex>
                      </div>
                      {/* <Select
                        style={{ width: "300px", marginLeft: "20px" }}
                        placeholder="Select Which Types"
                        onChange={handlePhoneTypesToCall}
                        options={phoneTypes}
                        value={phoneTypesToCall}
                        mode="multiple"
                      />{" "} */}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <CustomSelect
                          mode="multiple"
                          style={{ width: "170px", marginLeft: "40px" }}
                          placeholder="Select phone types"
                          onChange={handlePhoneTypesToCall}
                          options={phoneTypes}
                          tagRender={tagRenderTypes}
                          value={phoneTypesToCall}
                          maxTagCount={0}
                          isSearchSelect={false}
                        />
                        {!phoneTypesToCall?.length ? (
                          <div style={{ color: "red", height: 0, marginLeft: "30px", fontSize: "12px" }}>Need to select at least one</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  </div>
                </List.Item>
                <List.Item>
                  <div style={{ padding: "10px 0", borderRadius: "8px" }}>
                    <Flex>
                      <div style={{ marginRight: "5px", marginTop: "4.5px" }}> 4.</div>
                      <div>
                        {" "}
                        Only pass through calls if a human answers{" "}
                        <Tooltip
                          placement={"top"}
                          title={
                            <>
                              We recommend keeping it off to minimize delays. If you want to use it you can call back if the delay caused the prospect to hang
                              up before you talked with them, this way you only talk with available prospects.
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                        </Tooltip>
                        {/* <Tooltip
                          placement={"top"}
                          title={
                            <>
                              {" "}
                              <b>FAQ:</b>
                              <br />
                              <br />
                              <b> 1. What happens when someone answers?</b> The first prospect to answer will be displayed on your screen with their
                              information. The Ai will give you a sentence you can choose to say at the start of the call.
                              <br />
                              <br />
                              <b> 2. What happens if more than 1 person answers?</b> It's very rare that more than 1 person will answer at the exact same time
                              since once someone answers other calls are ended. If it does happen you will know and have a notification on your screen so that
                              you can switch to the other call if you prefer.
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                        </Tooltip> */}
                      </div>
                    </Flex>
                    {/* {thisisPriorCamp ? ``:  */}
                    <Flex style={{ marginLeft: "25px", marginTop: "15px" }}>
                      <div style={{ fontSize: "12px", paddingRight: "25px" }}>
                        The AI ensures that a human answered and not a voicemail before connecting the call. <br />
                        {/* The AI checks for a human or voicemail before connecting the call, using 1 dialer minute per check. <br /> */}
                        This may slightly delay the prospect hearing you as the AI verifies the voice type.
                        {/* <Tooltip
                          placement={"top"}
                          title={
                            <>
                              {" "}
                              <b>FAQ:</b>
                              <br />
                              <br />
                              <b> 1. What happens when someone answers?</b> Each detection uses 1 dialer minute, whether it’s a human or voicemail. <br />
                              <br />
                              If connected, there might be a brief delay as the AI ensures it’s a human voice before transferring the call.
                            </>
                          }
                        >
                          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
                        </Tooltip> */}
                        <br />
                      </div>
                      <div style={{ marginRight: "15px" }}>
                        <Select
                          style={{ width: "100px" }}
                          placeholder="Select Amount"
                          onChange={handleChangeAiVoiceRecognition}
                          options={[
                            { value: true, label: "On" },
                            { value: false, label: "Off" },
                          ]}
                          value={useAiVoice}
                        />
                      </div>
                    </Flex>
                    {/* }  */}
                  </div>
                </List.Item>{" "}
                {/* <List.Item>
       <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
          3. <Flex style={{marginLeft:'15px' }}>{!selectedEmails.length && <SvgIcon type={"warning"} style={{ width:'14px',height:'14px',marginRight:'7px',marginTop:'4px'}}/>} Emails to use in campaign</Flex></div>
         </Text> 
        <CustomSelect
          mode="multiple"
          style={{ width: '250px',marginLeft:'5px' }}
          placeholder="Select emails"
          onChange={handleEmailChange}
          options={emailsData}
          tagRender={tagRender}
          value={selectedEmails}
          maxTagCount={0}  
          isSearchSelect={false}
        /><Button className="link" style={{marginLeft:'8px'}} onClick={showDrawer}>  Add more</Button>
    </List.Item> */}
              </List>

              {/* FAQ:<br />
      <Collapse items={emitems}/> */}
            </div>
          )}
        </div>

        {/* </Sider> */}
      </Flex>

      {/*
     
      

      <CustomLabel title={"Email Body"}  /> */}

      <br />
      <br />
      <ConfirmButtons
        leftButtonTitle={"Close"}
        rightButtonTitle={"Save Settings & Close"}
        onCancel={closeModal}
        handleSubmit={saveSettings}
        loading={loadingGenAiForPros}
        loadingText="Generating"
      />
      <ManageEmailDrawer open={openDrawer} onCancel={onClsDrawer} campaign={campaignDisplaying?.campaign} />
      {isAddNumberDrawerOpen && <AddNumberDrawer open={isAddNumberDrawerOpen} onCancel={handleAddNumberModalClose} />}
      {/* <div>* Every Ai Personalized Email generation will use 1 Ai Personalized Email credit</div> */}
    </Modal>
  );
};
