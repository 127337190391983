import { Button, Card, Col, Flex, Row, Tabs, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ContentCard, Loader, Modal, TabsSwitch } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { browsers, companyProfile, virtualLady } from "src/shared/images";
import "./index.less";
import { CompanyCardContent, ContactCardContent, DialerButtonsFooter, LinkedInCardContent } from "./ui";
import { dialerSelector } from "src/store/dialer";
import { useSelector } from "react-redux";
import TabPane from "antd/es/tabs/TabPane";

type AiGeneratedPersonalModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  onHangUp: VoidFunction;
  callPhoneFnction: VoidFunction;
  onHangUpAndClose: VoidFunction;
  data: any;
  addNotes: boolean;
  callSeconds: number;
};

export const AiGeneratedPersonalIntroModal: FC<AiGeneratedPersonalModalProps> = ({
  open,
  onCancel,
  data,
  onHangUp,
  callPhoneFnction,
  callSeconds,
  onHangUpAndClose,
  addNotes,
}) => {
  const closeModal = () => {
    onCancel();
  };

  const {
    firstLoad,
    // callingInProgress,
    dialerPowerDialingOnInfo,
    multiCallingFullyPaused,
    finishedWithProspectsInDialer,
    prosp_called_now,
    amount_of_prosp_to_call_now,
    loadingTokenCompletedFirstTime,
    toggleOnTheConfirmationPopUp,
    activeProspectOnPhoneNowId,
    credits,
    finishedReason,
    whoIsActiveContactCurrentlyOnCall,
    whoIsActiveContactCompanyCurrentlyOnCall,
    whoIsActiveContactLinkedInCurrentlyOnCall,
    numberToDialOnCallNow,
  } = useSelector(dialerSelector);

  // useEffect(() => {
  //   console.log(open, "openopenopen");
  // }, [open]);
  // console.log(whoIsActiveContactCurrentlyOnCall, "whoIsActiveContactCurrentlyOnCall");

  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  useEffect(() => {
    setShowLinkedin(!!dialerPowerDialingOnInfo?.overview?.scrape_linkedin);
    setShowCompany(!!dialerPowerDialingOnInfo?.overview?.scrape_company);
  }, [dialerPowerDialingOnInfo]);

  const [msgToShow, setMsgToShow] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [bioInfoSum, setBioInfoSum] = useState(null);
  const [linkedInSum, setLinkedInSum] = useState(null);
  const [companySum, setCompanySum] = useState(null);
  // linkedinActivitySentence
  // linkedinBioSentence;
  // const hasMoreThan1=
  // const setActiveTab = (ioe) => {
  //   console.log(ioe, "option activated");
  // };
  const [phoneAvails, setPhoneAvails] = useState([] as any);
  useEffect(() => {
    const mobile_phone_extension_num = whoIsActiveContactCurrentlyOnCall?.mobile_phone_extension_num;
    const mobile_phone = whoIsActiveContactCurrentlyOnCall?.mobile_phone?.length > 6 ? whoIsActiveContactCurrentlyOnCall?.mobile_phone : null;
    const direct_phone_extension_num = whoIsActiveContactCurrentlyOnCall?.direct_phone_extension_num;
    const direct_phone = whoIsActiveContactCurrentlyOnCall?.direct_phone?.length > 6 ? whoIsActiveContactCurrentlyOnCall?.direct_phone : null;
    const business_number_extension_num = whoIsActiveContactCurrentlyOnCall?.business_number_extension_num;
    const business_number = whoIsActiveContactCurrentlyOnCall?.business_number?.length > 6 ? whoIsActiveContactCurrentlyOnCall?.business_number : null;
    setPhoneAvails(
      [
        mobile_phone?.length && { type: "Mobile", phone: mobile_phone, ext: mobile_phone_extension_num },
        direct_phone?.length && { type: "Direct", phone: direct_phone, ext: direct_phone_extension_num },
        business_number?.length && { type: "Business", phone: business_number, ext: business_number_extension_num },
      ]?.filter(Boolean)
    );
  }, [whoIsActiveContactCurrentlyOnCall]);
  // console.log(whoIsActiveContactLinkedInCurrentlyOnCall, "whoIsActiveContactLinkedInCurrentlyOnCall");
  useEffect(() => {
    let actTabNow = activeTab;
    const linkedinActivitySentence = whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinActivitySentence;
    const linkedinBioSentence = whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinBioSentence;
    const companyPersonalizedSent = whoIsActiveContactCompanyCurrentlyOnCall?.companyPersonalizedSent;
    if (linkedinActivitySentence?.length) {
      setLinkedInSum(linkedinActivitySentence);
      if (!actTabNow) {
        actTabNow = "LinkedIn Activity";
      }
    }
    if (linkedinBioSentence?.length) {
      setBioInfoSum(linkedinBioSentence);
      if (!actTabNow) {
        actTabNow = "LinkedIn Bio";
      }
    }

    if (companyPersonalizedSent?.length) {
      setCompanySum(companyPersonalizedSent);
      if (!actTabNow) {
        actTabNow = "Company";
      }
    }
    setActiveTab(actTabNow);
  }, [whoIsActiveContactCompanyCurrentlyOnCall, whoIsActiveContactLinkedInCurrentlyOnCall]);

  const updActiveTab = (opt) => {
    // console.log(opt, "option activated");
    setActiveTab(opt);
  };

  useEffect(() => {
    let msg;
    const linkedinActivitySentence = whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinActivitySentence;
    const linkedinBioSentence = whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinBioSentence;
    const companyPersonalizedSent = whoIsActiveContactCompanyCurrentlyOnCall?.companyPersonalizedSent;
    // console.log(activeTab, "activeTabactiveTab");
    switch (activeTab) {
      case "LinkedIn Activity":
        msg = linkedinActivitySentence;
        break;
      case "LinkedIn Bio":
        msg = linkedinBioSentence;
        break;
      case "Company":
        msg = companyPersonalizedSent;
        break;
    }
    setMsgToShow(msg);
  }, [activeTab]);

  const shouldRenderTabsSwitch = bioInfoSum || linkedInSum || companySum;
  const tabsComponent = useMemo(() => {
    if (shouldRenderTabsSwitch) {
      const tabItems = [
        bioInfoSum && { key: "1", tab: "LinkedIn Bio", value: "LinkedIn Bio" },
        linkedInSum && { key: "2", tab: "LinkedIn Activity", value: "LinkedIn Activity" },
        companySum && { key: "3", tab: "Company", value: "Company" },
      ].filter(Boolean);
      const handleTabChange = (index) => {
        // Find the corresponding item by key
        const selectedItem = tabItems?.[index];
        if (selectedItem) {
          updActiveTab(selectedItem?.value); // Send the value you want here
        }
      };
      return (
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          size="middle"
          style={{
            width: `${tabItems.length * 150}px`,
          }}
        >
          {tabItems.map((item, i) => (
            <TabPane tab={item.tab} key={i} />
          ))}
        </Tabs>
      );
    }
    return null;
  }, [bioInfoSum, linkedInSum, companySum, activeTab, updActiveTab]);

  const [phoneCalling, setPhoneCalling] = useState(null);
  useEffect(() => {
    const phoneThatAnswered = whoIsActiveContactCurrentlyOnCall?.phoneThatAnswered;
    const simPhone = phoneAvails?.find((ph) => ph?.phone === phoneThatAnswered);

    setPhoneCalling(simPhone || { type: "other", phone: phoneThatAnswered, ext: null });
  }, [phoneAvails, whoIsActiveContactCurrentlyOnCall]);

  const callBackFunction = () => {
    if (
      //not on call
      1 &&
      phoneCalling
    ) {
      callPhoneFnction(phoneCalling);
    }
  };

  return (
    <Modal
      customClassName={"ai-generated-personal-modal"}
      width={!showLinkedin && !showCompany ? 600 : 970}
      // style={{ height: "200px" }}
      open={open}
      title={"Phone Answered - Contact Information Of Prospect Below"}
      forceOnlyCloseIconClose
      onCancel={closeModal}
    >
      {" "}
      {/* <SvgIcon type={"phoneDialer"} /> */}
      <Flex vertical style={{ height: "450px", overflowY: "scroll", padding: "5px", overflowX: "hidden" }}>
        {/* <Typography.Paragraph style={{ marginBottom: 28, color: "#757A89" }}>AI Generated Personal Intro Sentence</Typography.Paragraph>{" "} */}
        {shouldRenderTabsSwitch ? (
          <Card className="ai-box-sentence">
            <div
              style={{
                marginBottom: "-15px",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "auto",
                marginTop: "-5px",
                width: "fit-content",
              }}
            >
              {tabsComponent}
            </div>
            <div>
              <Typography.Paragraph style={{ color: "#757A89", textAlign: "center", marginBottom: "8px" }}>
                AI Generated Intro Sentence{" "}
                <Tooltip
                  placement={"top"}
                  title={
                    <>
                      Meetz Ai will try to summarize the below LinkedIn activity and company information that is connected to your value proposition, so that
                      you can quickly tie it in to the cold call. Recommended to say parts of the sentence that you sounds good to you.{" "}
                    </>
                  }
                >
                  {" "}
                  <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
                </Tooltip>
              </Typography.Paragraph>
            </div>
            {msgToShow}
          </Card>
        ) : (
          ""
        )}
        <Row gutter={24} wrap={false}>
          {!!showLinkedin && (
            <Col span={!showCompany || !showLinkedin ? 14 : 8}>
              <ContentCard
                headerIcon={<SvgIcon type={"likeLinkedIn"} />}
                cardTitle={"Prospects LinkedIn"}
                headerRightContent={
                  !!whoIsActiveContactCurrentlyOnCall?.linkedin_profile_url?.length && (
                    <Button
                      className={"link"}
                      onClick={() =>
                        window.open(
                          "https://www." + whoIsActiveContactCurrentlyOnCall?.linkedin_profile_url.replace(/^(https?:\/\/)?(www\.)?/i, ""),
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      Profile
                    </Button>
                  )
                }
                style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", border: "1px solid #dcdcdc" }}
              >
                <Flex
                  justify={"center"}
                  align={
                    whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinInfo?.first_name?.length ||
                    whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinActivity?.length
                      ? "unset"
                      : "center"
                  }
                  style={{ height: 500 }}
                >
                  {whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinInfo?.first_name?.length ||
                  whoIsActiveContactLinkedInCurrentlyOnCall?.linkedinActivity?.length ? (
                    <LinkedInCardContent data={whoIsActiveContactLinkedInCurrentlyOnCall} />
                  ) : (
                    <div style={{ padding: "auto" }}>
                      <div style={{ margin: "auto", width: "fit-content" }}>
                        <img src={browsers} alt={"browsers with paper plane"} width={111} height={98} style={{ margin: "auto" }} />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {whoIsActiveContactCurrentlyOnCall?.id && !whoIsActiveContactCurrentlyOnCall?.linkedin_profile_url ? (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            No LinkedIn URL connected to this prospect. So we can't fetch the information.
                            <br />
                            <br />
                          </>
                        ) : !whoIsActiveContactLinkedInCurrentlyOnCall ? (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            Getting prospects LinkedIn...
                            <br />
                            <br />
                            <Loader />
                          </>
                        ) : (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            Couldn't fetch {whoIsActiveContactCurrentlyOnCall?.first_name
                              ? whoIsActiveContactCurrentlyOnCall?.first_name
                              : `this prospects`}{" "}
                            LinkedIn
                            <br />
                            <br />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Flex>
              </ContentCard>
            </Col>
          )}
          <Col
            span={!showLinkedin && !showCompany ? 22 : 8}
            style={{ padding: 0, marginLeft: !showLinkedin && showCompany ? "30px" : !showLinkedin && !showCompany ? "15px" : 0 }}
          >
            <ContentCard
              headerIcon={<SvgIcon type={"contactInfo"} />}
              cardTitle={"Contact Information"}
              style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", border: "1px solid #dcdcdc" }}
            >
              <Flex justify={data ? "unset" : "center"} align={data ? "unset" : "center"} style={{ height: 500 }}>
                {whoIsActiveContactCurrentlyOnCall?.id ? (
                  <ContactCardContent data={whoIsActiveContactCurrentlyOnCall} isLarger={!showLinkedin && !showCompany} />
                ) : (
                  <Flex vertical justify={"center"} gap={18}>
                    <Flex justify={"center"}>
                      <img src={virtualLady} alt={"lady with VR glasses"} width={174} />
                    </Flex>
                    <Typography.Paragraph className={"loading-contact-info"}>
                      Loading... Gathering information. This may take a few moments.
                    </Typography.Paragraph>
                  </Flex>
                )}
              </Flex>
            </ContentCard>
          </Col>
          {!!showCompany && (
            <Col span={!showCompany || !showLinkedin ? 14 : 8}>
              <ContentCard
                headerIcon={<SvgIcon type={"companyInfo"} />}
                cardTitle={"Company Overview"}
                headerRightContent={
                  !!whoIsActiveContactCurrentlyOnCall?.company_url?.length && (
                    <Button
                      className={"link"}
                      onClick={() =>
                        window.open(
                          "https://www." + whoIsActiveContactCurrentlyOnCall?.company_url.replace(/^(https?:\/\/)?(www\.)?/i, ""),
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      Website
                    </Button>
                  )
                }
                style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", border: "1px solid #dcdcdc" }}
              >
                <Flex
                  justify={"center"}
                  align={
                    whoIsActiveContactCompanyCurrentlyOnCall?.companyLIUpdatesArr?.length ||
                    whoIsActiveContactCompanyCurrentlyOnCall?.companyLICoInfo?.name?.length ||
                    whoIsActiveContactCompanyCurrentlyOnCall?.companyLICoInfo?.website?.length
                      ? "unset"
                      : "center"
                  }
                  style={{ height: 500 }}
                >
                  {/* !whoIsActiveContactCompanyCurrentlyOnCall */}
                  {whoIsActiveContactCompanyCurrentlyOnCall?.companyLIUpdatesArr?.length ||
                  whoIsActiveContactCompanyCurrentlyOnCall?.companyLICoInfo?.name?.length ||
                  whoIsActiveContactCompanyCurrentlyOnCall?.companyLICoInfo?.website?.length ? (
                    <CompanyCardContent data={whoIsActiveContactCompanyCurrentlyOnCall} />
                  ) : (
                    <>
                      {" "}
                      <div>
                        {" "}
                        <Flex justify={"center"}>
                          {" "}
                          <img src={companyProfile} alt={"company profile on the gadget"} width={116} />{" "}
                        </Flex>
                        {whoIsActiveContactCurrentlyOnCall?.id &&
                        !whoIsActiveContactCurrentlyOnCall?.company_name &&
                        !whoIsActiveContactCurrentlyOnCall?.company_url ? (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            No company name connected to this prospect. So we can't fetch company information.
                            <br />
                            <br />
                          </>
                        ) : !whoIsActiveContactCompanyCurrentlyOnCall ? (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            Loading... Gathering company information
                            <br />
                            <br />
                            <Loader />
                          </>
                        ) : (
                          <>
                            {" "}
                            <br />
                            {/* linkedin_profile_url */}
                            Couldn't fetch the information about{" "}
                            {whoIsActiveContactCurrentlyOnCall?.company_name
                              ? `the prospects company, ${whoIsActiveContactCurrentlyOnCall?.company_name}`
                              : `this prospects company`}
                            . <br />
                            <br />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </Flex>
              </ContentCard>
            </Col>
          )}
        </Row>
      </Flex>
      {/* to reset the open call notes */}
      {open && (
        <DialerButtonsFooter
          closeModal={closeModal}
          onHangUp={onHangUp}
          callBackFunction={callBackFunction}
          callSeconds={callSeconds}
          onHangUpAndClose={onHangUpAndClose}
          DialerButtonsFooter={DialerButtonsFooter}
          addNotes={addNotes}
          allowShowNotes
        />
      )}
    </Modal>
  );
};
