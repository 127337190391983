/* eslint-disable no-unreachable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Flex, MenuProps, message, Tooltip, Typography } from "antd";
import _ from "lodash";
import { DealsContext } from "../../../../app/context/DealsContext";
import { CustomCard } from "../../../../ui/CustomCard";
import { CustomTag } from "../../../../ui/CustomTag";
import { getTagStyles } from "../../../../../helpers";
import { useDrag, useDragLayer, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ItemTypes } from "../Group";
import "./index.less";
import { SvgIcon } from "src/shared/icons";
import Speedometer from "src/components/pages/prospect-data/ui/ExploreTable/ui/Speedometer";
import { DeleteDealModal } from "../DeleteDealModal";

export const DealCard = ({
  deal,
  setDealToEdit,
  index,
  groupId,
  dropSpace,
  dealsGroup,
  fixDeals,
  idOfDragging,
  setIdOfDragging,
  openDeleteDealModal,
}: // setSelectedDealId,
{
  deal;
  setDealToEdit;
  index;
  groupId;
  dropSpace;
  dealsGroup;
  fixDeals;
  idOfDragging;
  openDeleteDealModal;
  setIdOfDragging: React.Dispatch<React.SetStateAction<string>>;
  // setSelectedDealId;
}) => {
  // console.log(setIdOfDragging, "setIdOfDragging");
  const iconRef = useRef<HTMLDivElement>(null);
  const listItemRef = useRef<HTMLDivElement>(null);
  // const { setSelectedDealId, openEditDealModal, openDeleteDealModal, setDealsGroups } = useContext(DealsContext);
  // const [dealsGroupArr, setDealsGroupArr] = useState([]);
  // console.log(deal?.notes, "deal?.notes");
  // console.log(dealsGroupArr, "dealsGroup1");

  // useEffect(() => {
  //   setDealsGroupArr(dealsGroup);
  // }, [dealsGroup]);

  const moveCard = (hoverIndex, itemId, origGroupId, targGroupId) => {
    // console.log(dealsGroup, "dealsGroup", hoverIndex, itemId, origGroupId, targGroupId);
    const dealGroupCop = [...(dealsGroup || [])];
    dealGroupCop
      ?.sort((a, b) => a.order - b.order)
      ?.forEach((group) => {
        if (group?.id === origGroupId) {
          let dealFoundExternal;
          let isSamePlace = false;

          const updDealGroups = dealGroupCop?.map((dealGr) => {
            const dealGrCopy = [...(dealGr?.deals || [])];
            let dealFound = dealGrCopy?.find((dl: { id: any }) => dl?.id === itemId);
            // console.log(dealFound, "dealFound");
            let updDeals = [...(dealGrCopy?.sort((a, b) => a.order - b.order) || [])];

            if (!dealFound) {
              dealGroupCop?.forEach((dealGroup) => {
                const dealFoundFromOtherColumn = dealGroup?.deals?.find((dl: { id: any }) => dl?.id === itemId);
                if (!!dealFoundFromOtherColumn) {
                  dealFoundExternal = dealFoundFromOtherColumn;
                }
              });
            }
            // dealFoundExternal = dealFound;

            // situation based update deals
            if (!!dealFound && dealGr?.id === targGroupId) {
              const currentIndex = updDeals.indexOf(dealFound);
              if (currentIndex !== hoverIndex) {
                updDeals.splice(currentIndex, 1);
                updDeals.splice(hoverIndex, 0, dealFound);
              } else {
                isSamePlace = true;
              }
            } else if (!!dealFound) {
              updDeals.splice(updDeals.indexOf(dealFound), 1);
            } else if (dealGr?.id === targGroupId && dealFoundExternal) {
              updDeals.splice(hoverIndex, 0, dealFound || dealFoundExternal);
            }
            let totalPrice = 0;
            updDeals.forEach((deal, idx) => {
              totalPrice += deal?.estimated_money_value;
              updDeals[idx] = { ...deal, order: idx + 1 };
            });
            // console.log(updDeals, "updDeals");
            return { ...dealGr, totalPrice, deals: updDeals };
          });
          if (!isSamePlace) {
            fixDeals(updDealGroups);
          }

          return;
        }
      });
  };
  const CustomDragLayer: React.FC = () => {
    const { item, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
    }));

    // If we are not currently dragging, or have no offsets, don't render anything.
    if (!isDragging || !initialOffset || !currentOffset) {
      return null;
    }

    // Compute x/y for our preview so that it follows the mouse properly.
    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;

    // const transform = `translate(${currentOffset.x}px,${currentOffset.y}px)`;

    return (
      <div
        style={{
          transform,
          WebkitTransform: transform,
          pointerEvents: "none",
          zIndex: 1000,
          // left: 0,
          // top: 0,
          // width: "100%",
          // height: "100%",
        }}
      >
        {" "}
        <CustomCard
          ref={iconRef}
          id={deal?.id}
          // onClick={() => setDealToEdit(deal)}
          title={deal?.title}
          description={deal?.descriptions}
          dropdownMenuItems={items}
          // onDropdownClick={() => {
          //   // event.stopPropagation();
          //   setSelectedDealId(deal.id);
          // }}
          footerContent={cardFooter()}
          deal
        />
      </div>
    );
  };
  const [{ isDragging, opacity }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id: deal?.id, index, groupId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.8 : 1,
    }),
    canDrag: () => !dropSpace,
  });
  useEffect(() => {
    if (isDragging && deal.id !== idOfDragging) {
      setIdOfDragging(deal.id);
    }
    // if (!isDragging) {
    //   setIdOfDragging("");
    // }
  }, [isDragging]);

  const [, drop] = useDrop({
    accept: "card",
    hover(item: any, monitor) {
      if (!listItemRef.current) {
        return;
      }
      // const dragIndex = item.index;
      const hoverIndex = index;
      const isOver = monitor.isOver({ shallow: true });
      // console.log(item, "hoverIndex", hoverIndex, dealsGroup, groupId);
      const itemId = item?.id;
      const origGroupId = item?.groupId;
      if (!isOver) {
        return;
      }
      moveCard(hoverIndex, itemId, origGroupId, groupId);
    },
    drop(item: any, monitor) {
      // console.log(item, monitor);
      // const isOrderChanged = !currentData.every((item, index) => item === prevData[index]);
      setIdOfDragging("");
      // if (isOrderChanged && isEdit && !isDraft) {
      //   showChangeStepModal();
      // } else if (isOrderChanged && isDraft) {
      //   handleChangeStepConfirmed();
      // }
    },
  });

  drag(iconRef);
  drop(listItemRef);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      onClick: () => {
        // console.log("beforeclick2");
        setDealToEdit(deal);
      },
    },
    {
      key: "2",
      label: "Delete",
      onClick: () => openDeleteDealModal(deal?.id),
    },
  ];
  const timeToString = (unixTimestamp) => {
    const now = new Date();
    const targetDate = new Date(unixTimestamp * 1000); // Convert seconds to milliseconds
    const diffInMs = targetDate.getTime() - now.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 3600 * 24)); // Convert ms to days

    if (diffInDays < 0) {
      return "Past due";
    }

    const months = Math.floor(diffInDays / 30);
    const weeks = Math.floor(diffInDays / 7);
    const days = diffInDays;

    if (months > 0) {
      return months === 1 ? "In 1 month" : `In ${months} months`;
    }

    if (weeks > 0) {
      return weeks === 1 ? "In 1 week" : `In ${weeks} weeks`;
    }

    return days === 1 ? "In 1 day" : `In ${days} days`;
  };

  const cardFooter = () => {
    const tagStyles = getTagStyles("Green");

    const timeLeft = deal?.estimated_close_time_unix ? timeToString(deal?.estimated_close_time_unix) : null;

    return (
      <>
        <Flex justify={"space-between"} align={"center"} className={"deal-card-footer-wrapper"}>
          <Typography.Paragraph className={"date"} style={{ color: timeLeft === "Past due" ? "orange" : "default" }}>
            {!!timeLeft ? (
              <Tooltip
                placement={"top"}
                title={`Estimated close time: ${new Date(deal?.estimated_close_time_unix * 1000).toLocaleDateString(undefined, {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}`}
              >
                {" "}
                Est. close: {timeLeft}
              </Tooltip>
            ) : (
              ""
            )}
          </Typography.Paragraph>
          <CustomTag text={`$${deal?.estimated_money_value?.toLocaleString()}`} color={tagStyles.color} backgroundColor={tagStyles.backgroundColor} />
        </Flex>
        <div style={{ marginTop: "5px", display: "flex", alignItems: "center" }}>
          <Tooltip
            title={
              deal?.sales_pros_connected?.length
                ? deal?.sales_pros_connected
                    ?.filter(Boolean)
                    ?.map(
                      (prospect) =>
                        `${prospect.first_name || ""} ${prospect.last_name || ""} ${prospect.title ? `- ${prospect.title}` : ""} ${
                          prospect.company_name ? `at ${prospect.company_name}` : ``
                        }`
                    )
                    .join("\n")
                    .substring(0, 250)
                    .split("\n") // Split by newlines
                    .map((line, idx) => (
                      <React.Fragment key={idx}>
                        {!idx && (
                          <>
                            <strong>Prospects connected:</strong>
                            <br />
                            <br />
                          </>
                        )}{" "}
                        {(idx || 0) + 1}. {line}
                        <br />
                        <br />
                      </React.Fragment>
                    ))
                : `No prospect connected to this deal`
            }
          >
            <SvgIcon type={deal?.sales_pros_connected?.length ? "personIcons" : "personGreyIcon"} style={{ width: 30, height: 30 }} />
          </Tooltip>

          <Tooltip
            title={
              deal?.deal_activity?.length ? (
                <div>
                  {deal.deal_activity
                    ?.map(
                      (note: { unix_added: number; text: string }) =>
                        `${new Date(note?.unix_added * 1000).toLocaleDateString(undefined, {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}: ${note.text}`
                    )
                    .join("\n")
                    .substring(0, 250)
                    .split("\n") // Split by newlines
                    .map((line, idx) => (
                      <React.Fragment key={idx}>
                        {!idx && (
                          <>
                            <strong>Comments:</strong>
                            <br />
                            <br />
                          </>
                        )}{" "}
                        {(idx || 0) + 1}. {line}
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  {deal?.deal_activity?.map((note) => `${note.text}`).join(" ").length > 250 ? "..." : ""}
                </div>
              ) : (
                "No notes added in this deal"
              )
            }
          >
            <SvgIcon type={deal?.deal_activity?.length ? "notesIcon" : "notesGreyIcon"} style={{ width: 30, height: 30, marginLeft: "5px" }} />
          </Tooltip>
          <Tooltip
            title={
              <>
                <b>How good is this deal?</b> <br />
                <br />
                Based on what you marked in the deal: {deal?.how_good_is_it || ""}0%
              </>
            }
          >
            {" "}
            <Speedometer value={(deal?.how_good_is_it || 0) / 10} animate={false} medium />
          </Tooltip>
        </div>
      </>
    );
  };
  // console.log(idOfDragging, "idOfDragging");
  return dropSpace ? (
    <div ref={listItemRef} style={{ opacity: 0, height: "100%", cursor: "default" }}>
      <CustomCard
        ref={iconRef}
        id={deal?.id}
        dropSpace
        // onClick={() => setDealToEdit(deal)}
        // title={deal?.title}
        // description={deal?.descriptions}
        // dropdownMenuItems={items}
        // onDropdownClick={() => {
        //   // event.stopPropagation();
        //   setSelectedDealId(deal.id);
        // }}
        // footerContent={cardFooter()}
        // deal
      />
    </div>
  ) : (
    <DndProvider backend={HTML5Backend}>
      {/* <CustomDragLayer />{" "} */}
      <div
        ref={listItemRef}
        style={{
          width: "100%",
          cursor: "pointer",
          opacity,
          boxShadow:
            idOfDragging === deal.id
              ? "0 0 15px 5px rgba(173, 216, 230, 0.8), 0 0 30px 10px rgba(173, 216, 230, 0.6), 0 0 10px 5px rgba(173, 216, 230, 0.4)"
              : "0 0 5px rgba(211, 211, 211, 0.5)",
          // backgroundColor: idOfDragging === deal.id ? "red" : "black",
          transform: idOfDragging === deal.id ? "scale(1.05)" : "none",
          transition: "transform 0.2s, box-shadow 0.2s",
          // zIndex: idOfDragging === deal.id ? 1000 : "auto",
          // zIndex: 1000,
          position: idOfDragging === deal.id ? "relative" : "static",
          // margin: "15px 0",
          // cursor: finalSeq ? "default" : "pointer",
        }}
        key={deal?.id}
        onMouseEnter={(e) => {
          if (!idOfDragging) {
            e.currentTarget.style.transform = "scale(1.02)";
            e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.2)";
          }
        }}
        onMouseLeave={(e) => {
          if (!idOfDragging) {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.1)";
          }
        }}
      >
        <CustomCard
          ref={iconRef}
          id={deal?.id}
          // onClick={(event) => {
          //   console.log("beforeclick1");
          //   setDealToEdit(deal);
          // }}
          setDealToEdit={() => setDealToEdit(deal)}
          title={deal?.title}
          description={deal?.descriptions}
          dropdownMenuItems={items}
          onDropdownClick={(event) => {
            // event.stopPropagation();
            // setSelectedDealId(deal.id);
          }}
          footerContent={cardFooter()}
          deal
        />{" "}
      </div>
    </DndProvider>
  );
};
