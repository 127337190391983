interface Tag {
  text: string;
  color: string;
}

interface Link {
  text: string;
}

interface Relationships {
  id: string;
  name: string;
  shouldAddGroupMembersToMeeting: boolean;
  shouldAddInitiatorToMeeting: boolean;
  shouldOnlyOfferTimesInitiatorIsAlsoAvailable: boolean;
  shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: boolean;
}

interface Members {
  id: string;
  firstName: string;
  role: string;
  email: string;
  teamSchedulingGroup: string;
}

interface Team {
  id: string;
  name: string;
  description: string;
  tags: Tag[];
  links: Link[];
  isOutlined: boolean;
  relationships: Relationships[];
  members: Members[];
}

export const teams: Team[] = [
  {
    id: "1",
    name: "SDR",
    description: "Super Admin: Team Owner",
    tags: [
      {
        text: "Members: 20",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Define relationships",
      },
    ],
    isOutlined: false,
    members: [
      // {
      //   id: "1.11",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "aronadams@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.12",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "oliviajohnson@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.13",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "jean@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.14",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "joan@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.15",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "aronadams@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.16",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "aronadams@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.17",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "aronadams@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      // {
      //   id: "1.18",
      //   firstName: "",
      //   role: "Team Owner",
      //   email: "aronadams@gmail.com",
      //   teamSchedulingGroup: "SDR",
      // },
      {
        id: "1.19",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.20",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.21",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.22",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.23",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.24",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.25",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.26",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.27",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.28",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.29",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.30",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.31",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.32",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.33",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.34",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.35",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.36",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.37",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.38",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.39",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.40",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.41",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.42",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.43",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.44",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.45",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.46",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.47",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.48",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.49",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.50",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.51",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.52",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.53",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.54",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.55",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.56",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.57",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.58",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.59",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "1.60",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.11",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.12",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.13",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.14",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.15",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.16",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.17",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.18",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.19",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.20",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.21",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.22",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.23",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.24",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.25",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.26",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.27",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.28",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.29",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.30",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.31",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.32",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.33",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.34",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.35",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.36",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.37",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.38",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.39",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.40",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.41",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.42",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.43",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.44",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.45",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.46",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.47",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.48",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.49",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.50",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.51",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.52",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.53",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.54",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.55",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.56",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.57",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.58",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.59",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
      {
        id: "2.60",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "SDR",
      },
    ],
    relationships: [
      {
        id: "2",
        name: "AE",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "3",
        name: "Team 2.1",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "4",
        name: "Team 2.2",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "5",
        name: "Team 2.7",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: true,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: false,
      },
    ],
  },
  {
    id: "2",
    name: "AE",
    description: "Super Admin: Team Owner",
    tags: [
      {
        text: "Members: 1",
        color: "Blue",
      },
    ],
    links: [
      {
        text: "Define relationships",
      },
    ],
    relationships: [
      {
        id: "1",
        name: "SDR",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "3",
        name: "Team 2.1",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "4",
        name: "Team 2.2",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "5",
        name: "Team 2.7",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: true,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: false,
      },
    ],
    members: [
      {
        id: "1.11",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "AE",
      },
    ],
    isOutlined: false,
  },
  {
    id: "3",
    name: "VP Sales",
    description: "Super Admin: Team Owner",
    tags: [
      {
        text: "Members: 1",
        color: "Red",
      },
    ],
    links: [
      {
        text: "Define relationships",
      },
    ],
    relationships: [
      {
        id: "1",
        name: "SDR",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "2",
        name: "AE",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "4",
        name: "Managers",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "5",
        name: "Marketing",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: true,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: false,
      },
    ],
    members: [
      {
        id: "1.11",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "Team 2.1",
      },
    ],
    isOutlined: false,
  },
  {
    id: "4",
    name: "Managers",
    description: "Super Admin: Team Owner",
    tags: [
      {
        text: "Members: 1",
        color: "Purple",
      },
    ],
    links: [
      {
        text: "Define relationships",
      },
    ],
    relationships: [
      {
        id: "1",
        name: "SDR",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "2",
        name: "AE",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "3",
        name: "Team 2.1",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "5",
        name: "Team 2.7",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: true,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: false,
      },
    ],
    members: [
      {
        id: "1.11",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "Team 2.2",
      },
    ],
    isOutlined: false,
  },
  {
    id: "5",
    name: "Marketing",
    description: "Super Admin: Team Owner",
    tags: [
      {
        text: "Members: 1",
        color: "Light Green",
      },
    ],
    links: [
      {
        text: "Define relationships",
      },
    ],
    relationships: [
      {
        id: "1",
        name: "SDR",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "2",
        name: "AE",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "3",
        name: "Team 2.1",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: false,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: true,
      },
      {
        id: "4",
        name: "Team 2.2",
        shouldAddGroupMembersToMeeting: true,
        shouldAddInitiatorToMeeting: true,
        shouldOnlyOfferTimesInitiatorIsAlsoAvailable: false,
        shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: false,
      },
    ],
    members: [
      {
        id: "1.11",
        firstName: "",
        role: "Team Owner",
        email: "aronadams@gmail.com",
        teamSchedulingGroup: "Team 2.7",
      },
    ],
    isOutlined: false,
  },
];
