import React, { FC, useState } from "react";
import { CustomSelect, Loader } from "src/components/ui";
import { periodOptions } from "../OverviewCard";
import { DashboardCard } from "../ui/DashboardCard";
import { AiInitiatedEmailChart, AiInitiatedEmailEmptyData, AiInitiatedEmailTable } from "./ui";
import "./index.less";
import { AppDispatch } from "src/store";
import { useDispatch, useSelector } from "react-redux";
import { getPhoneGraphAnalytics } from "src/store/outreach-phones/asyncRequests";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { Button, Row } from "antd";
import { SvgIcon } from "src/shared/icons";

export const AiInitiatedEmailCard = () => {
  const [periodOfTime, setPeriodOfTime] = useState("Last 7 days");

  const dispatch = useDispatch<AppDispatch>();
  const { graphLoading, phoneAnalyticsOverview } = useSelector(outreachPhonesSelector);
  const getAnalytics = (timeframe?: string) => {
    const tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    dispatch(getPhoneGraphAnalytics({ timeframe: timeframe || periodOfTime, tz }));
  };

  const handleChangeTimeframe = (val) => {
    // console.log(val, "VALasvsdav");
    setPeriodOfTime(val);
    getAnalytics(val);
  };
  const headerRightContent = (
    <Row wrap={false} style={{ gap: 18 }}>
      <Button className="link" onClick={() => !graphLoading && getAnalytics()}>
        {graphLoading ? <Loader size={"small"} /> : <SvgIcon type={"refresh"} width={12} height={12} />}
        <div style={{ marginLeft: "5px" }}> Refresh</div>
      </Button>
      <CustomSelect
        isSearchSelect={false}
        options={periodOptions}
        value={periodOfTime}
        popupClassName={"dashboard-select-dropdown"}
        style={{ width: 130, height: 26 }}
        onSelect={handleChangeTimeframe}
      />
    </Row>
  );
  const isTeam = false;

  return (
    <DashboardCard customClassName={"ai-initiated-card-wrapper"} title={"Dialer Analytics Overview"} headerRightContent={headerRightContent}>
      {phoneAnalyticsOverview?.length ? (
        <>
          <AiInitiatedEmailChart data={phoneAnalyticsOverview} />
        </>
      ) : (
        <AiInitiatedEmailEmptyData />
      )}
      {isTeam ? <AiInitiatedEmailTable /> : ""}
    </DashboardCard>
  );
};
