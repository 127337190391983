import { Typography } from "antd";
import { FC, useContext } from "react";
import { DealsContext } from "../../../../app/context/DealsContext";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

interface DeleteColumnModalProps {
  onClose: () => void;
}

export const DeleteColumnModal: FC<DeleteColumnModalProps> = ({ onClose, currentGroup, deleteColumn }) => {
  // const { deleteColumn } = useContext(DealsContext);

  const handleSubmitBtnClick = () => {
    deleteColumn();
    // onClose();
  };

  return (
    <Modal open={true} title={"Are you sure you want to delete this column?"} onCancel={onClose} rootClassName={"delete-column-modal"}>
      <Typography.Paragraph className={"description"}>
        This action will permanently remove all deals ({currentGroup?.deals?.length || 0} deals in this column) still in the column.
        <b> This process cannot be undone.</b>
      </Typography.Paragraph>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  );
};
