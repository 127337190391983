/* eslint-disable react/jsx-fragments */
import { Button, Col, Flex, message, Space, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import PageLayout from "src/components/layout/PageLayout";
import { StatisticsBar } from "src/components/ui";
import { PlansModal } from "../../billing/ui/PlansModal";
import { ActionsToTakeCard, ActivitiesCard, AiInitiatedEmailCard, ExclusiveOfferBanner, OverviewCard, UnlockAccessModal } from "../index";
import "./index.less";
import { LockedActionsToTake, LockedAiInitiatedEmail, LockedOverviewCard } from "./ui";
import { useBreakpoints } from "../../../../helpers/useBreakpoints";
import { GoToDesktopModal } from "../../../ui/GoToDesktopModal";
import { useDispatch, useSelector } from "react-redux";
import { userIsPayingSelector } from "src/store/user";
import { outreachCampaignSelector, resOutcomeEmailDashboard } from "src/store/outreach-campaigns";

const emptyDashboardStatistic = [
  {
    title: "Active Campaigns",
    value: 0,
  },
  {
    title: "Total Campaigns",
    value: 0,
  },
];

const dashboardStatistic = [
  {
    title: "Active Campaigns",
    value: 20,
  },
  {
    title: "Total Campaigns",
    value: 32,
  },
];

// const hasAccess = false

interface DashboardLayoutProps {
  isTourStep?: boolean;
  disableModals?: boolean;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ disableModals, isTourStep }) => {
  // const [isDashboardLocked, setIsDashboardLocked] = useState(true);
  const [openPlansAndPricing, setOpenPlansAndPricing] = useState(false);
  const [openUnlockAccessModal, setOpenUnlockAccessModal] = useState(false); // later for locked trial make true and force lock the close button IF its not a paid account.

  const handleUnlockAccessModalClose = () => {
    setOpenUnlockAccessModal(false);
  };
  const { isDesktop } = useBreakpoints();

  const handleSubmit = () => {
    handleUnlockAccessModalClose();
    setOpenPlansAndPricing(true);
    // setIsDashboardLocked(true)
  };
  // const handleTest = () => {
  //   //  for initiate checkout
  //   // var callback = function () {
  //   //   console.log("went in callback");
  //   //   // if (typeof url != "undefined") {
  //   //   // window.location = url;
  //   //   // }
  //   // };
  //   // window.gtag("event", "conversion", {
  //   //   send_to: "AW-630038230/lDvhCI3InsgZENa9tqwC",
  //   //   event_callback: callback,
  //   // });
  //   // return false;

  //   // book appointment
  //   // console.log(window.gtag, "before is in here????handleTest");
  //   // console.log("is in here????handleTest");
  //   // // window.gtag("config", "AW-630038230");
  //   // console.log("is in here????handleTest2");
  //   // window.gtag("event", "conversion", { send_to: "AW-633fgdfsg0038230/nacECPC8w8gZENa9tqwC" });
  //   // console.log("is in here????handleTest3");

  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     event: "scheduledemo",
  //   });
  //   console.log("window.dataLayer", window.dataLayer);
  // };

  const payingClient = useSelector(userIsPayingSelector);
  const { outreachCampaignsCnt, successfullUpdateMsgEmailDashboard, failedUpdateMsgEmailDashboard, infoUpdateMsgEmailDashboard } =
    useSelector(outreachCampaignSelector);
  const hasCampaign = outreachCampaignsCnt?.length === 2 ? outreachCampaignsCnt[0]?.value : "loading";
  const dispatch = useDispatch();
  // cant be from 1 state of dashboard since other pages affect of what we see on dashboard if its used and then tehy check dashboard without refreshing data
  useEffect(() => {
    if (successfullUpdateMsgEmailDashboard) {
      message.destroy();
      message.success(successfullUpdateMsgEmailDashboard);
      dispatch(resOutcomeEmailDashboard());
    }
  }, [successfullUpdateMsgEmailDashboard]);

  useEffect(() => {
    if (failedUpdateMsgEmailDashboard) {
      message.destroy();
      message.error(failedUpdateMsgEmailDashboard);
      dispatch(resOutcomeEmailDashboard());
    }
  }, [failedUpdateMsgEmailDashboard]);

  useEffect(() => {
    if (infoUpdateMsgEmailDashboard) {
      message.destroy();
      message.info(infoUpdateMsgEmailDashboard);
      dispatch(resOutcomeEmailDashboard());
    }
  }, [infoUpdateMsgEmailDashboard]);

  return isDesktop ? (
    <PageLayout pageTitle={<>Dashboard </>} isTableContent={false} bottomHeaderContent={<ExclusiveOfferBanner />}>
      {" "}
      {/* <Button onClick={handleTest}>
        <Flex align={"center"} gap={8} className={"profile-item-wrapper"}>
          <Typography.Paragraph className={"profile-menu-title"}>TEST IN DEV</Typography.Paragraph>
        </Flex>
      </Button> */}
      <Flex style={{ gap: 24 }}>
        <Space direction={"vertical"} size={24}>
          <div className={"overview-wrapper"}>
            <div className={!hasCampaign || hasCampaign === "loading" ? "makeblur" : ""}>
              {" "}
              <OverviewCard />
            </div>
            {(!hasCampaign || hasCampaign === "loading") && (
              <div className={1 ? "blur-mask" : ""}>
                <LockedOverviewCard campCount={hasCampaign} />
              </div>
            )}
          </div>
          <div className={"overview-wrapper"}>
            <div className={!hasCampaign || hasCampaign === "loading" ? "makeblur" : ""}>
              <AiInitiatedEmailCard data={false} />
            </div>
            {(!hasCampaign || hasCampaign === "loading") && (
              <div className={1 ? "blur-mask" : ""}>
                <LockedAiInitiatedEmail campCount={hasCampaign} />
              </div>
            )}
          </div>
        </Space>
        <Col style={{ maxWidth: 360 }}>
          <Space direction={"vertical"} size={24}>
            <StatisticsBar uniqId={1} dataType="campaignOverview" />
            <div className={"actions-wrapper"}>
              <div
              // className={!hasCampaign || hasCampaign === "loading" ? "makeblur" : ""}
              >
                {" "}
                <ActionsToTakeCard outreachCampaignsCnt={outreachCampaignsCnt} />
              </div>
              {/* {(!hasCampaign || hasCampaign === "loading") && (
                <div className={"blur-mask"}>
                  <LockedActionsToTake />
                </div>
              )} */}
            </div>
            <ActivitiesCard />
          </Space>
        </Col>
      </Flex>
      {!isTourStep && !disableModals && <UnlockAccessModal open={openUnlockAccessModal} onCancel={handleUnlockAccessModalClose} handleSubmit={handleSubmit} />}
      {openPlansAndPricing && (
        <PlansModal
          onClose={() => {
            setOpenPlansAndPricing(false);
            setOpenUnlockAccessModal(true);
          }}
        />
      )}
    </PageLayout>
  ) : (
    <>
      {openUnlockAccessModal ? (
        <UnlockAccessModal open={openUnlockAccessModal} onCancel={handleUnlockAccessModalClose} handleSubmit={handleSubmit} />
      ) : (
        <GoToDesktopModal />
      )}
    </>
  );
};
