/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";
import {
  addFreeNum,
  checkAddress,
  getAddressInformation,
  getAllPhoneStats,
  getPhoneAnalytics,
  getPhoneGraphAnalytics,
  getProsInfoPopup,
  listPhoneNums,
  manualDialerProsSearch,
  manualDialerProsSearchTopSearch,
  remNumFromTwillio,
  updatePhoneSettings,
} from "./asyncRequests";
import dayjs from "dayjs";
// import { checkIfProspIsGenerating, doNotSell, downloadProspects, getProspects, searchForAvailProsp, searchProsp } from './asyncRequests';

const outreachPhonesSlice = createSlice({
  name: "outreachPhones",
  initialState: {
    loading: false,
    loadingNewPhoneNum: false,
    loadingcheckAddress: false,
    is_continuos_calls: false,
    guessedAddresses: [],
    phone_numbers_available: [],
    phone_numbers: [],
    rangeOfPros: [],
    addressComplete: { street: "notloaded" },
    phoneNumbersLeft: 0,
    number_forward_to: "+1",
    record_calls: false,
    successfullUpdateMsg: false as boolean | string,
    infoUpdateMsg: false as boolean | string,
    failedUpdateMsg: false as boolean | string,

    graphLoading: false,
    phoneAnalyticsOverview: [],

    // manual dialer state
    loadingPros: false,
    searchedPros: [],
    selectedPros: {},

    //popupinfo
    basicPopupInfo: {},
    advancedPopupInfo: { id: "loading" as null | "loading" },
    advancedPopupInfoLoading: false,

    // TOP LEVEl SEARCH (Similar to above function)
    loadingProsTopSearch: false,
    searchedProsTopSearch: [],
    selectedProsTopSearch: {},

    // phoneStats: null as null | any[],

    phoneLoading: true,
    dataAll: { campaignId: "all" },
    analyticPhoneDates: [dayjs().subtract(30, "days").startOf("day")?.unix(), dayjs().endOf("day")?.unix()],
    analyticsBarAll: [] as any,
    analyticsBarCampaign: { campId: null, analytics: [] },
    campaignDisplayingAnalyticsId: "all",

    // download an
    nameOfExportFile: "Meetz: Phone Outreach Analytics",
    informationToDownload: ["Outgoing Calls", "Incoming Calls", "Good Calls", "Bad Calls"],
    campaignsToDownload: "all" as string | string[],
    unixdates: null as null | number[],
  },
  reducers: {
    openPopUp: (state, action) => {
      state.basicPopupInfo = action.payload;
    },
    setExportInformationToDownload: (state, action) => {
      state.informationToDownload = action.payload;
    },
    setPhoneDateRange: (state, action) => {
      state.analyticPhoneDates = action.payload;
    },
    setExportName: (state, action) => {
      state.nameOfExportFile = action.payload;
    },
    setExportCampsId: (state, action) => {
      state.campaignsToDownload = action.payload;
    },
    setExportUnixRange: (state, action) => {
      state.unixdates = action.payload;
    },
    resetProsSearch: (state) => {
      state.loadingPros = false;
      state.searchedPros = [];
    },
    resetProsSearchTopSearch: (state) => {
      state.loadingProsTopSearch = false;
      state.searchedProsTopSearch = [];
    },
    changeIsSelPros: (state, action) => {
      state.selectedPros = action.payload;
    },
    changeIsSelItemTopSearch: (state, action) => {
      state.selectedProsTopSearch = action.payload;
    },
    changeIsContinuosCalling: (state, action) => {
      state.is_continuos_calls = action.payload;
    },
    remOptimisticPhoneNum: (state, action) => {
      const phoneNumCopy = _.cloneDeep(state.phone_numbers);
      state.phone_numbers = phoneNumCopy?.filter((num) => num?.id !== action.payload?.phoneId);
    },
    resetOutcome: (state) => {
      state.successfullUpdateMsg = false;
      state.failedUpdateMsg = false;
      state.infoUpdateMsg = false;
    },
    updAdress: (state, action) => {
      state.addressComplete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        // state.phoneStats = action.payload?.phoneStats || [];
        state.phoneAnalyticsOverview = action.payload?.phoneAnalyticsOverview;
        state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || [];
        state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft || 0;
        state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to || "+1";
        state.record_calls = !!action.payload?.phoneNumbersInfo?.dialer?.record_calls;
        state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros;
        state.is_continuos_calls = action.payload?.phoneNumbersInfo?.is_continuos_calls;
        console.log(action.payload.phoneStats, "action.payload.phoneStats");
        state.dataAll = action.payload?.phoneStats?.phoneFirstPageData || { campaignId: "all" };
        state.analyticsBarAll = action.payload?.phoneStats?.phoneAnalyticsBar || [
          {
            id: "phone_out",
            title: "Outgoing Calls",
            value: 0,
            explanation: `Total number of outgoing calls made`,
            extrVal1: `0 Conv.`,
            extrVal1Explanation: `Number of answered calls that led to a conversation (At least 15 seconds).`,
            extraVal2: `0% CR`,
            extrVal2Explanation: `Percentage of conversations out of the total attempted calls.`,
            extrVal3: `0 UC`,
            extrVal3Explanation: `Unique prospects called, counting only the number of different prospects called.`,
            extraVal4: `0% ACP`,
            extrVal4Explanation: `Average number of calls per prospect.`,
          },
          {
            id: "phone_in",
            title: "Incoming Calls",
            value: 0,
            explanation: `Total number of incoming calls received`,
            extrVal1: `0 UC`,
            extrVal1Explanation: `Unique prospects who called, only counts how many prospects called. Not how many times they called.`,
          },

          { id: "good_call", title: "Good Calls", value: 0, explanation: `Total number of calls with a positive outcome` },
          { id: "bad_call", title: "Bad Calls", value: 0, explanation: `Total number of calls with a negative outcome` },
        ];
        state.phoneLoading = false;
      })
      .addCase(getProsInfoPopup.fulfilled, (state, action) => {
        state.advancedPopupInfo = action.payload || {};
        state.advancedPopupInfoLoading = false;
      })
      .addCase(getProsInfoPopup.pending, (state, action) => {
        state.advancedPopupInfo = { id: null };
        state.advancedPopupInfoLoading = true;
      })
      .addCase(getProsInfoPopup.rejected, (state, action) => {
        state.advancedPopupInfo = { id: null };
        state.advancedPopupInfoLoading = false;
      })
      .addCase(getAllPhoneStats.pending, (state, action) => {
        state.dataAll = {};
        state.phoneLoading = true;
      })
      .addCase(getAllPhoneStats.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload123");
        state.dataAll = action.payload.phoneFirstPageData || { campaignId: "all" };
        state.analyticsBarAll = action.payload.phoneAnalyticsBar || [
          {
            id: "phone_out",
            title: "Outgoing Calls",
            value: 0,
            explanation: `Total number of outgoing calls made.`,
            extrVal1: `0 Conv.`,
            extrVal1Explanation: `Number of answered calls that led to a conversation (At least 15 seconds).`,
            extraVal2: `0% CR`,
            extrVal2Explanation: `Percentage of conversations out of the total attempted calls.`,
            extrVal3: `0 UC`,
            extrVal3Explanation: `Unique prospects called, counting only the number of different prospects called.`,
            extraVal4: `0% ACP`,
            extrVal4Explanation: `Average number of calls per prospect.`,
          },
          {
            id: "phone_in",
            title: "Incoming Calls",
            value: 0,
            explanation: `Total number of incoming calls received.`,
            extrVal1: `0 UC`,
            extrVal1Explanation: `Unique prospects who called, counting only the number of different prospects who called.`,
          },
          { id: "good_call", title: "Good Calls", value: 0, explanation: `Total number of calls with a positive outcome.` },
          { id: "bad_call", title: "Bad Calls", value: 0, explanation: `Total number of calls with a negative outcome.` },
        ];
        state.phoneLoading = false;
      })
      .addCase(getAllPhoneStats.rejected, (state, action) => {
        state.failedUpdateMsg = `Couldn't fetch prospects, please check your internet`;
        state.dataAll = { campaignId: "all" };
        state.phoneLoading = false;
      })
      .addCase(manualDialerProsSearch.fulfilled, (state, action) => {
        console.log(action?.payload, "manualDialerProsSearch");
        state.searchedPros = action?.payload?.filteredLeads || [];
        state.loadingPros = false;
      })
      .addCase(manualDialerProsSearch.pending, (state, action) => {
        state.loadingPros = true;
      })
      .addCase(manualDialerProsSearch.rejected, (state, action) => {
        state.searchedPros = [];
        state.loadingPros = false;
      })
      .addCase(manualDialerProsSearchTopSearch.fulfilled, (state, action) => {
        console.log(action?.payload, "manualDialerProsSearchTopSearch");
        state.searchedProsTopSearch = action?.payload?.fixedTopSearchArray || [];
        state.loadingProsTopSearch = false;
      })
      .addCase(manualDialerProsSearchTopSearch.pending, (state, action) => {
        state.loadingProsTopSearch = true;
      })
      .addCase(manualDialerProsSearchTopSearch.rejected, (state, action) => {
        state.searchedProsTopSearch = [];
        state.loadingProsTopSearch = false;
      })
      .addCase(getPhoneGraphAnalytics.fulfilled, (state, action) => {
        state.phoneAnalyticsOverview = action.payload;
        state.graphLoading = false;
      })
      .addCase(getPhoneGraphAnalytics.pending, (state, action) => {
        state.graphLoading = true;
      })
      .addCase(getPhoneGraphAnalytics.rejected, (state, action) => {
        state.graphLoading = false;
      })
      .addCase(getPhoneAnalytics.fulfilled, (state, action) => {
        console.log(action.payload, "getPhoneAnalytics");
        state.dataAll = action.payload || { campaignId: "all" };

        state.phoneLoading = false;
      })
      .addCase(getPhoneAnalytics.rejected, (state, action) => {
        state.phoneLoading = false;
      })
      .addCase(getPhoneAnalytics.pending, (state, action) => {
        state.phoneLoading = true;
      })
      .addCase(updatePhoneSettings.fulfilled, (state, action) => {
        state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || [];
        state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft || 0;
        state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to || "+1";
        state.record_calls = !!action.payload?.phoneNumbersInfo?.dialer?.record_calls;
        state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros;
        state.successfullUpdateMsg = "Updated phone settings";
      })
      .addCase(updatePhoneSettings.rejected, (state, action) => {
        state.failedUpdateMsg = "Failed to update phone settings, please check your internet";
      })
      .addCase(listPhoneNums.fulfilled, (state, action) => {
        state.phone_numbers_available = action.payload?.phone_numbers_available;
        state.addressComplete = action.payload?.address;
        state.loadingNewPhoneNum = false;
      })
      .addCase(listPhoneNums.pending, (state, action) => {
        state.loadingNewPhoneNum = true;
        state.phone_numbers_available = [];
      })
      .addCase(listPhoneNums.rejected, (state, action) => {
        state.phone_numbers_available = [];
        state.loadingNewPhoneNum = false;
        state.failedUpdateMsg = "Failed to get phone numbers";
      })
      .addCase(addFreeNum.fulfilled, (state, action) => {
        state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || [];
        state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft || 0;
        state.number_forward_to = action.payload?.phoneNumbersInfo?.number_forward_to || "+1";
        state.record_calls = !!action.payload?.phoneNumbersInfo?.dialer?.record_calls;
        state.rangeOfPros = action.payload?.phoneNumbersInfo?.rangeOfPros;
        state.successfullUpdateMsg = "Added your new Meetz phone number! 📱🎉";
      })
      .addCase(addFreeNum.rejected, (state, action) => {
        state.failedUpdateMsg = `Something went wrong, make sure your street address included a house/office number. Or your internet might not be connected`;
      })
      .addCase(remNumFromTwillio.fulfilled, (state, action) => {
        if (action.payload?.successful) {
          state.successfullUpdateMsg = "Removed phone number";
        } else {
          state.failedUpdateMsg = "Could not remove phone number. Please check your internet";
        }
        state.phone_numbers = action.payload?.phoneNumbersInfo?.phoneNumbers || [];
        state.phoneNumbersLeft = action.payload?.phoneNumbersInfo?.phoneCredsLeft || 0;
      })
      .addCase(remNumFromTwillio.rejected, (state) => {
        state.failedUpdateMsg = "Could not remove phone number. Please check your internet";
      })
      .addCase(checkAddress.pending, (state) => {
        if (!state.loadingcheckAddress) {
          state.loadingcheckAddress = true;
        }
      })
      .addCase(checkAddress.fulfilled, (state, action) => {
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
        state.guessedAddresses = action.payload.places;
      })
      .addCase(checkAddress.rejected, (state, action) => {
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
        state.guessedAddresses = [];
      })
      .addCase(getAddressInformation.rejected, (state, action) => {
        state.failedUpdateMsg = `Not able to get address, please check your internet or manually write it out.`;
      })
      .addCase(getAddressInformation.fulfilled, (state, action) => {
        state.addressComplete = action.payload;
      });

    //     if (!state.loading) {
    //       state.loading = true;
    //     }
    //     state.totalProspectsAvailable=0
    //     state.curProspPages=1
    //     state.totalProspPages=1
    //      state.prospOnCurPage=[]
    //   })
  },
});

const { actions, reducer } = outreachPhonesSlice;
export const {
  remOptimisticPhoneNum,
  resetOutcome,
  changeIsContinuosCalling,
  updAdress,
  resetProsSearch,
  changeIsSelPros,
  changeIsSelItemTopSearch,
  resetProsSearchTopSearch,
  setExportInformationToDownload,
  setExportName,
  setExportCampsId,
  setExportUnixRange,
  openPopUp,
  setPhoneDateRange,
} = actions;

export default reducer;

export const outreachPhonesSelector = createSelector(
  (state) => state.outreachPhones,
  (outreachPhones) => outreachPhones
);
