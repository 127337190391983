import React from "react";
import { Progress, Tooltip } from "antd";
import GaugeChart from "react-gauge-chart";

const Speedometer = ({ value, animate, small, medium }) => {
  return (
    <div style={{ width: small ? "30px" : medium ? "50px" : "75px", height: small ? "20px" : medium ? "20px" : "30px" }}>
      {" "}
      {/* <Tooltip
        placement={"topRight"}
        title={
          <>
            Try to get a search that matches a total of 1K-12K prospects, so that when you create a campaign you can see exactly what audience segment is
            resonating with your offer, this will help you double down and scale. <br />
            <b>Extra Tip:</b> Extract 100-200 of each audience and create different campaigns to find the winning audience
            <br />
            <br />
            <span
              style={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                backgroundColor: "#ffc34d",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />{" "}
            Too broad {value > 0.3 && <span style={{ fontWeight: 500 }}>⬅️ Your current search</span>}
            <br />
            <span
              style={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                backgroundColor: "#29bf00",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />{" "}
            Perfect {value >= 0.1 && value <= 0.3 && <span style={{ fontWeight: 500 }}>⬅️ Your current search</span>}
            <br />
            <span
              style={{
                display: "inline-block",
                width: "8px",
                height: "8px",
                backgroundColor: "#c70035",
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />{" "}
            Too narrow {value < 0.1 && <span style={{ fontWeight: 500 }}>⬅️ Your current search</span>}
            <br />
          </>
        }
      > */}
      <GaugeChart
        id="gauge-chart"
        nrOfLevels={30}
        arcsLength={[0.3, 0.3, 0.3]} // 10% red, 20% green, 70% yellow
        colors={["#c70035", "#ffc34d", "#29bf00"]}
        arcWidth={0.3}
        percent={value} // Example value; adjust as needed
        needleColor="#464A4F"
        animate={animate}
      />{" "}
      {/* </Tooltip> */}
    </div>
  );
};

export default Speedometer;
