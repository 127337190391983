/* eslint-disable react/jsx-fragments */
import { Form, FormInstance, FormItemProps } from "antd";
import React, { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext";
import { prospectData } from "src/components/pages/prospect-data/info";
import { CustomSelect } from "src/components/ui";

import { FilterTagsList } from "src/components/ui/FilterTagsList";
import { prospectDataSelector, setFilterFormData } from "src/store/prospect_data";

interface Option {
  key: string;
  label: string;
  value: string;
}

interface SelectFilterItem extends FormItemProps {
  data: string[];
  form: FormInstance;
  fieldName: string;
  placeholder?: string;
  staticOptions?: Option[];
  forceShowAllOptionsAsOptionsArePrefiltered?: boolean;
  forceDontHighlight?: string[];
  forceDontHighlightBlocks?: [string, string];
}

const options = (fieldName: string): Option[] => {
  const uniqueValues = new Set<string>();

  prospectData.forEach((item: any) => {
    const fieldValue = item[fieldName];
    if (Array.isArray(fieldValue)) {
      fieldValue.forEach((value) => uniqueValues.add(value));
    } else {
      uniqueValues.add(fieldValue);
    }
  });

  return Array.from(uniqueValues).map((value) => ({
    key: value,
    label: value,
    value: value,
  }));
};

export const SelectFilterItem: FC<SelectFilterItem> = ({
  placeholder,
  data,
  form,
  fieldName,
  staticOptions,
  forceShowAllOptionsAsOptionsArePrefiltered,
  forceDontHighlight, // dont use anymore
  forceDontHighlightBlocks, // dont use anymore INSTEAD USE {{ignore_start}} and when want so {{ignore_end}}
}) => {
  const dispatch = useDispatch();

  const { filterFormData } = useSelector(prospectDataSelector);
  const onChange = (value: any, field: string) => {
    //  console.log('her13',value);
    dispatch(setFilterFormData({ ...filterFormData, [field]: value }));
  };

  return (
    <>
      <Form.Item name={fieldName}>
        <CustomSelect
          placeholder={placeholder}
          options={staticOptions ?? options(fieldName)}
          placement={"bottomLeft"}
          tagRender={() => <></>}
          onChange={(value) => onChange(value, fieldName)}
          showSearch
          isSearchSelect
          mode={"multiple"}
          forceShowAllOptionsAsOptionsArePrefiltered={forceShowAllOptionsAsOptionsArePrefiltered}
          forceDontHighlight={forceDontHighlight}
          forceDontHighlightBlocks={forceDontHighlightBlocks}
        />
      </Form.Item>
      <FilterTagsList data={data} form={form} fieldToUpdate={fieldName} />
    </>
  );
};
