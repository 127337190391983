import { message, Button, Checkbox, Flex, Image, Input, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import { ConfirmButtons, CustomLabel, CustomSelect, Loader, Modal, StatusTag, TextEditor } from "src/components/ui";
import { MinusOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import moment from "moment";
import "../index.less"; // Assuming you have a CSS file for custom styles
import { useDispatch, useSelector } from "react-redux";
import { getProsInfoPopup } from "src/store/outreach-phones/asyncRequests";
import noData from "src/shared/images/phoneNotification.svg";
import { outreachPhonesSelector } from "src/store/outreach-phones";
import { Plus, X } from "lucide-react";
import { SvgIcon } from "src/shared/icons";
// import { navigate } from "react-router";
import tz from "moment-timezone";
import { useNavigate } from "react-router";
import { DialerModal } from "../ui";
import { PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { sendEmail } from "src/store/outreach-emails/asyncRequests";
import { motion, AnimatePresence } from "framer-motion";
import { outreachEmailsSelector, resetEmailSending } from "src/store/outreach-emails";

interface ViewModalProps {
  info: any;
  isDialer?: boolean;
  isLarger?: boolean;
  refreshScrollDown?: boolean;
}

const LoadingImage = ({ empty, isDialer }) => (
  <div style={{ textAlign: "center", padding: "20px" }}>
    <Image preview={false} src={noData} alt={"No data"} width={isDialer ? 150 : 300} height={isDialer ? 150 : 300} />
    <Flex align="center" justify="center">
      {!empty && <Loader />}
      <div style={{ marginLeft: !empty ? "20px" : 0 }}>{empty ? `No activity yet` : `Loading prospect's activity`}</div>
    </Flex>
  </div>
);

export const ActivityComponent: FC<ViewModalProps> = ({ isLarger, info, isDialer, refreshScrollDown }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [emailToDraft, setEmailToDraft] = useState(false);
  const [canHaveReplyTo, setCanHaveReplyTo] = useState(false);
  const [replySubjectLine, setReplySubjectLine] = useState("");

  const [emailFrom, setEmailFrom] = useState([]);
  const [emailsData, setEmailsData] = useState([]);
  const [emailToSendFromId, setEmailToSendFromId] = useState(null);

  const [latestThreadIdToRespondTo, setLatestThreadIdToRespondTo] = useState(null);
  const [latestThreadEmailCanRespondWith, setLatestThreadEmailCanRespondWith] = useState(null);
  const [latestProspInCampId, setLatestProspInCampId] = useState(null);

  const [idToFetch, setIdToFetch] = useState(null);
  const [idHandledData, setIdHandledData] = useState(null);
  const [displayData, setDisplayData] = useState(null);
  const [uniqCampaigns, setUniqCampaigns] = useState([]);
  const { advancedPopupInfo, advancedPopupInfoLoading, dataAll } = useSelector(outreachPhonesSelector);
  const { additionalEmails, emailSending } = useSelector(outreachEmailsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const toggleSubject = (body) => {
    setEmailSubject(body ? "ReplyTo" : "");
  };
  const handleSubjUpd = (body) => {
    setEmailSubject(body);
  };
  const handleBodyUpd = (body) => {
    setEmailBody(body);
  };
  // const [actCampIds, setActCampIds] = useState([]);

  useEffect(() => {
    // const isEmailCanRespondToo = latestThreadEmailCanRespondWith === emailsData?.find((item) => item?.value === emailToSendFromId)?.email;

    // console.log(latestThreadIdToRespondTo, "isEmailCanRespondToo");

    // if (latestThreadIdToRespondTo && isEmailCanRespondToo) {
    if (latestThreadIdToRespondTo) {
      setCanHaveReplyTo(true);
      setEmailSubject("ReplyTo");
    } else {
      setCanHaveReplyTo(false);
    }
  }, [latestThreadIdToRespondTo, emailToSendFromId, emailsData, latestThreadIdToRespondTo]);
  const handleChangeId = (value) => {
    setEmailToSendFromId(value);
  };
  useEffect(() => {
    if (emailsData?.length && emailFrom?.length) {
      // const emailFromFiltered = emailFrom.filter((email) => email?.length > 0);
      const emailToSendFrom = emailsData?.find(
        (email) => !email?.disabled && emailFrom.reverse().some((fromEmail) => email?.email?.toLowerCase().trim() === fromEmail.toLowerCase().trim())
      );
      // console.log(emailsData, emailFrom, "additionalEmailsadditionalEmails", emailToSendFrom);
      setEmailToSendFromId(emailToSendFrom?.value);
    }
  }, [emailsData, emailFrom]);

  useEffect(() => {
    setEmailsData(
      [...(additionalEmails || [])]
        ?.sort((a, b) => {
          return a?.id - b?.id;
        })
        ?.map((email) => {
          const emailToDisplay = email?.smtp?.from?.length ? email?.smtp?.from?.toLowerCase() : email?.email_alias_emailofcal?.toLowerCase().trim();
          return {
            label: `${emailToDisplay} ${
              emailFrom.some((fromEmail) => emailToDisplay === fromEmail.toLowerCase().trim()) && additionalEmails?.length > 1
                ? `(Got email from this address)`
                : ""
            } ${!email?.connected ? "- Disconnected" : ""}`,
            value: email?.id,
            email: emailToDisplay,
            disabled: !email?.connected,
          };
        })
    );
  }, [additionalEmails, emailFrom]);

  // console.log(advancedPopupInfo, "advancedPopupInfo");
  const [dialerModal, setDialerModal] = useState(false);
  const [emails, setEmails] = useState({ to: [], cc: [] });

  const resetEmail = ({ outcome }: { outcome: "success" | "fail" }) => {
    setEmailToDraft(false);
    setIdHandledData(null);
    // setEmailSubject("");
    setEmailBody("");
    message.destroy();
    if (outcome === "success") {
      message.success(`Sent email`);
    } else {
      message.error(`Failed to send email, try to check that the email is still connected properly.`);
    }
    dispatch(resetEmailSending());
    setIdToFetch(info?.id);
    dispatch(getProsInfoPopup({ id: info.id }));
  };

  useEffect(() => {
    if (emailSending === "success") {
      resetEmail({ outcome: "success" });
    }
    if (emailSending === "fail") {
      resetEmail({ outcome: "fail" });
    }
  }, [emailSending]);

  useEffect(() => {
    if (info?.id && info?.id !== idToFetch && !dialerModal) {
      setIdToFetch(info?.id);
      dispatch(getProsInfoPopup({ id: info.id }));
    }
  }, [info?.id, dialerModal]);

  useEffect(() => {
    if (advancedPopupInfo?.id && advancedPopupInfo?.id !== idHandledData && info?.id === advancedPopupInfo?.id) {
      setIdHandledData(info?.id);
      // Step 1: Combine all sales_analytics into one array, tagging each item with the campaign ID from its prospect
      let allEmailsFrom = [];
      let allPhonesFrom = [];
      let combinedAnalytics = [];
      let lastProspInCampid = null;
      for (let i = 0; i < advancedPopupInfo?.prospectsinsalesseries?.length; i++) {
        let series = advancedPopupInfo?.prospectsinsalesseries?.[i];
        if (series?.id) {
          lastProspInCampid = series?.id;
        }
        let campaignId = series.new_outreach_campaign ? series.new_outreach_campaign.id : null;

        if (series?.sales_analytics && Array.isArray(series.sales_analytics)) {
          for (let j = 0; j < series.sales_analytics.length; j++) {
            let item = { ...series.sales_analytics[j], myCampaignId: campaignId };
            // Add the campaign ID as a new property (or overwrite if you'd prefer)
            item.myCampaignId = campaignId;
            combinedAnalytics.push(item);
            // Check if email_from is doessnt need to be unique so that the latest FROM is in there and can select it
            if (item?.email_from && item?.email_action === "email_sent") {
              allEmailsFrom.push(item.email_from);
            }
            if (
              item?.dialer_number_from &&
              !allPhonesFrom.some((dialer_number_from) => dialer_number_from === item.dialer_number_from) &&
              item?.dialer_action === "outgoing_call"
            ) {
              allPhonesFrom.push(item.dialer_number_from);
            }
          }
        }
      }
      setLatestProspInCampId(lastProspInCampid);
      setEmailFrom(allEmailsFrom);

      const combineOpensAndClicksAnalytics = [];
      combinedAnalytics?.forEach((an) => {
        if (
          (an?.email_action === "email_opened" || an?.email_action === "email_clicked") &&
          ((an?.campaign_id && an?.step_id) || an?.is_manual_email_reply_id)
        ) {
          // const email_opened = an?.email_action === "email_opened" ? an : null
          // const email_clicked = an?.email_action === "email_clicked" ? an : null
          // const email_opened_clicked = {
          //   ...email_opened,
          //   email_clicked: email_clicked
          // }
          combineOpensAndClicksAnalytics.push({
            action: an?.email_action,
            campid: an?.campaign_id,
            stepid: an?.step_id,
            manualId: an?.is_manual_email_reply_id,
          });
        }
      });

      // Step 2: Sort combinedAnalytics from earliest to latest by unix_time_action_happened
      combinedAnalytics = combinedAnalytics.filter(
        (item) =>
          item?.email_action === "email_replied" ||
          item?.email_action === "email_sent" ||
          item?.dialer_action === "outgoing_call" ||
          item?.dialer_action === "incoming_call" ||
          item?.dialer_action === "sms_recieved"
      );

      combinedAnalytics.sort((a, b) => {
        return a?.unix_time_action_happened - b?.unix_time_action_happened;
      });
      let hasThreadToRespondToo = false;
      let latestThreadIdToRespondTo;
      let latestSucject;
      let latestEmailFrom;
      combinedAnalytics?.forEach((analy) => {
        if (analy?.email_thread_id_resp_to) {
          // console.log(analy?.email_thread_id_resp_to, "email_thread_id_resp_to", analy);
          hasThreadToRespondToo = true;
          latestSucject = analy?.replied_email_subject || analy?.meetz_email_subject;
          latestThreadIdToRespondTo = analy?.email_thread_id_resp_to;
          //  the below is not the best logic as they might of responded to a different email  from before in the thread but for now it will do for 95% of cases + add later to the object from where the email that they responded too came from if needed
          // if (analy?.email_action === "email_sent") {
          //   latestEmailFrom = analy?.email_from;
          // }
        }
      });
      // console.log(latestSucject, "latestSucject");
      // setCanHaveReplyTo(hasThreadToRespondToo);
      setReplySubjectLine(latestSucject);
      setLatestThreadIdToRespondTo(latestThreadIdToRespondTo);
      // setLatestThreadEmailCanRespondWith(latestEmailFrom);
      // setCanHaveReplyTo(true);
      // setLatestThreadIdToRespondTo(advancedPopupInfo?.prospectsinsalesseries?.[0]?.emails_thread_ids?.[0]);

      // Step 2.1: Add open and click counts to email_sent items
      combinedAnalytics.forEach((item) => {
        if (item.email_action === "email_sent") {
          const relatedOpensAndClicks = combineOpensAndClicksAnalytics.filter(
            (an) => (an.campid === item.campaign_id && an.stepid === item.step_id) || an?.manualId === item?.is_manual_email_reply_id
          );
          item.openedEmail = relatedOpensAndClicks.filter((an) => an.action === "email_opened").length;
          item.clickedEmail = relatedOpensAndClicks.filter((an) => an.action === "email_clicked").length;
        }
      });

      // Step 3: Build a separate array of all unique campaigns
      let uniqueCampaigns = [];
      let campaignSet = new Set();

      for (let i = 0; i < advancedPopupInfo?.prospectsinsalesseries?.length; i++) {
        let series = advancedPopupInfo?.prospectsinsalesseries?.[i];
        let cId = series?.new_outreach_campaign ? series?.new_outreach_campaign?.id : null;
        let cName = series?.new_outreach_campaign ? series?.new_outreach_campaign?.campaign_name : "No Name";

        if (!campaignSet.has(cId)) {
          campaignSet.add(cId);
          uniqueCampaigns.push(cId);
          // uniqueCampaigns.push({
          //   campaignId: cId,
          //   campaignName: cName,
          // });
        }
      }

      // Step 4: Show the output in the console
      // console.log("Sorted sales analytics (earliest to latest) with campaign ID:");
      // console.log("Sorted sales analytics (earliest to latest) with campaign ID:", combinedAnalytics);
      setDisplayData(combinedAnalytics);
      setUniqCampaigns(uniqueCampaigns);
      // console.log("\nAll unique campaigns:");
      // console.log(uniqueCampaigns);
    }
    //  else {
    //   setDisplayData([]);
    // }
  }, [advancedPopupInfo]);

  const scrollRef = useRef(null);
  const [didFirstSCrollDown, setdidFirstSCrollDown] = useState(false);
  useEffect(() => {
    if (refreshScrollDown && !didFirstSCrollDown && scrollRef.current) {
      setdidFirstSCrollDown(true);
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [refreshScrollDown, scrollRef]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [displayData, advancedPopupInfoLoading, advancedPopupInfo]);

  // TODON later add scheduled meetings and stuff

  return (
    <>
      {/* <div style={{ flex: 1, marginLeft: "50px" }}>
        {" "}
        <h2 style={{ marginTop: 0 }}>Activity:</h2> */}
      <div ref={scrollRef} className={isDialer ? (isLarger ? "scrollable-section-medium" : "scrollable-section-small") : "scrollable-section"}>
        {advancedPopupInfo?.id === info?.id ? (
          advancedPopupInfoLoading || !displayData ? (
            <LoadingImage isDialer={isDialer} />
          ) : displayData?.length ? (
            displayData?.map((item, index) => {
              let consoleloggedfortesting = false;
              // if (!consoleloggedfortesting && item?.email_action === "email_replied") {
              //   consoleloggedfortesting = true;
              //   // console.log(item, "iikikik email_replied");
              // }
              // console.log(uniqCampaigns, "uniqCampaignsuniqCampaigns");
              const fixCampName =
                !item?.campaign_id || uniqCampaigns?.includes(item?.campaign_id) ? item?.campaign_name : `${item?.campaign_name} (Deleted campaign)`;

              const actCampId = !!item?.campaign_id && uniqCampaigns?.includes(item?.campaign_id) ? item?.campaign_id : null;
              let enterSMS = 150;
              if (item?.dialer_sms_message?.length) {
                // const newlineIndex = item?.dialer_sms_message?.indexOf("\n");
                // if (newlineIndex > 1 && newlineIndex < 150) {
                //   enterSMS = newlineIndex;
                // }
                let pos = -1;
                // let count = 0;

                for (let i = 0; i < 3; i++) {
                  pos = item?.dialer_sms_message?.indexOf("\n", pos + 1);
                  if (pos === -1) break; // Stop if no more occurrences
                }
                if (pos > 1 && pos < 150) {
                  enterSMS = pos;
                } else {
                  enterSMS = 0;
                }
              } else {
                enterSMS = 0;
              }
              const fixedLinksInEmailBody = item?.meetz_email_body?.replace(
                /(?:href=["'])(https?:\/\/)?(www\.)?([^"']+)(?:["'])/gi,
                (match, protocol, www, domain) => {
                  const fullUrl = `https://www.${domain}`;
                  return `href="${fullUrl}"`;
                }
              );
              const fixedLinksInEmailBodyReply = item?.replied_email_body?.replace(
                /(?:href=["'])(https?:\/\/)?(www\.)?([^"']+)(?:["'])/gi,
                (match, protocol, www, domain) => {
                  const fullUrl = `https://www.${domain}`;
                  return `href="${fullUrl}"`;
                }
              );

              return (
                <>
                  {index && index + 1 === displayData.length ? (
                    <div className="chat-divider">
                      <span className="chat-divider-text">Most recent action</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {item?.dialer_action === "outgoing_call" ? (
                    <div style={{ marginTop: index ? "35px" : "0px" }} key={index}>
                      <div className="message to">
                        <div style={{ marginBottom: "5px", fontWeight: 600, width: "100%", textAlign: "center" }}>
                          Outgoing call <SvgIcon type={"outgoingCallWhite"} style={{ marginLeft: 8, height: 12, width: 12 }} />
                        </div>
                        <b>From:</b> {item?.dialer_number_from || ""}
                        <br />
                        <b>To:</b>{" "}
                        {item?.prospect_number_type ? (
                          <Tooltip placement={"top"} title={item?.prospect_number_type}>
                            {item?.dialer_number_to || ""}
                          </Tooltip>
                        ) : (
                          item?.dialer_number_to || ""
                        )}
                        <br />
                        {item?.dialer_call_duration_seconds && item?.dialer_answered ? (
                          <>
                            <b>Duration:</b> {Math.floor(item?.dialer_call_duration_seconds / 60)}m {item?.dialer_call_duration_seconds % 60}s
                            {item?.dialer_recording ? (
                              <>
                                <br />
                                <Flex>
                                  <div style={{ height: "15px" }}>
                                    {" "}
                                    <b>Recording:</b>
                                  </div>
                                  <Button
                                    type={"link"}
                                    href={item?.dialer_recording}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "white", textDecoration: "underline", margin: 0, marginLeft: "5px", padding: 0, height: "19px" }}
                                  >
                                    Call Recording
                                  </Button>
                                </Flex>
                              </>
                            ) : (
                              <br />
                            )}{" "}
                            <b>Status:</b> {item?.dialer_status_friendly ? item?.dialer_status_friendly : <MinusOutlined style={{ marginLeft: "3px" }} />}
                            <br />
                            <b>Notes:</b>{" "}
                            {item?.dialer_call_notes ? (
                              item?.dialer_call_notes?.length > 100 ? (
                                <Tooltip placement={"right"} title={`Call notes: ${item?.dialer_call_notes}`}>
                                  {item?.dialer_call_notes?.substring(0, 100) + "..."}
                                </Tooltip>
                              ) : (
                                item?.dialer_call_notes
                              )
                            ) : (
                              // <span style={{ marginTop: "50px" }}>
                              <MinusOutlined style={{ marginLeft: "3px" }} />
                              // </span>
                            )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <b>Status:</b> Not answered
                            <br />
                          </>
                        )}
                        {item?.step_number && fixCampName ? (
                          <Typography.Paragraph
                            // className={"emails-company-white"}
                            style={{
                              cursor: actCampId ? "pointer" : "default",
                              color: "white",
                              marginLeft: "auto",
                              marginRight: "2%",
                              marginTop: "5px",
                              width: "fit-content",
                              // width: "100%",
                            }}
                            onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                          >
                            {`Step ${item?.step_number} in`}{" "}
                            {fixCampName && fixCampName?.length > 25 ? (
                              <Tooltip
                                placement={"right"}
                                title={`Campaign name: ${fixCampName}`}
                                // style={{
                                //   color: "white",
                                //   textDecoration: "underline",
                                //   margin: 0,
                                //   marginLeft: "1px",
                                //   padding: 0,
                                //   height: "19px",
                                //   cursor: actCampId ? "pointer" : "default",
                                // }}
                              >
                                {" "}
                                <Button
                                  type={"link"}
                                  style={{
                                    color: "white",
                                    textDecoration: "underline",
                                    margin: 0,
                                    marginLeft: "1px",
                                    padding: 0,
                                    height: "19px",
                                    cursor: actCampId ? "pointer" : "default",
                                  }}
                                >
                                  {" "}
                                  {fixCampName?.substring(0, 25) + "..."}
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button
                                type={"link"}
                                style={{
                                  cursor: actCampId ? "pointer" : "default",
                                  color: "white",
                                  textDecoration: "underline",
                                  margin: 0,
                                  marginLeft: "2px",
                                  padding: 0,
                                  height: "19px",
                                }}
                              >
                                {" "}
                                {fixCampName}{" "}
                              </Button>
                            )}{" "}
                            {/* <Button type={"link"} className={"company-link"}>
                    {analytics?.campaign_name}
                  </Button> */}
                          </Typography.Paragraph>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ marginLeft: "auto", marginRight: "2%", width: "fit-content", color: "grey", fontWeight: 300, marginTop: "-2px" }}>
                        Called: {tz.unix(item?.unix_time_action_happened).format("MMMM D, h:mm A z")}
                      </div>
                      {/* <div className="message from">I'm glad you like it Steve! We've been working very hard to make this project a reality!</div> */}
                    </div>
                  ) : item?.dialer_action === "incoming_call" ? (
                    <div style={{ marginTop: index ? "35px" : "0px" }} key={index}>
                      <div className="message from">
                        <div style={{ marginBottom: "5px", fontWeight: 600, width: "100%", textAlign: "center" }}>
                          Incoming call <SvgIcon type={"incomingCallBlack"} style={{ marginLeft: 8, height: 12, width: 12 }} />
                        </div>
                        <b>From:</b> {item?.dialer_number_from || ""}
                        <br />
                        <b>To:</b> {/* {item?.prospect_number_type ? ( */}
                        <Tooltip
                          placement={"right"}
                          title={`The number the call was forwarded too. The prospect didn't call or see this phone number, they called your Meetz number.`}
                        >
                          {item?.dialer_number_to || ""}
                        </Tooltip>
                        {/* ) : (
                              item?.dialer_number_to || ""
                            )} */}
                        <br />
                        {item?.dialer_call_duration_seconds && item?.dialer_answered ? (
                          <>
                            {" "}
                            <b>Duration:</b> {Math.floor(item?.dialer_call_duration_seconds / 60)}m {item?.dialer_call_duration_seconds % 60}s
                            {item?.dialer_recording ? (
                              <>
                                <br />
                                <Flex>
                                  <div style={{ height: "15px" }}>
                                    {" "}
                                    <b>Recording:</b>
                                  </div>
                                  <Button
                                    type={"link"}
                                    href={item?.dialer_recording}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ margin: 0, marginLeft: "5px", padding: 0, height: "19px" }}
                                  >
                                    Call Recording
                                  </Button>
                                </Flex>
                              </>
                            ) : (
                              <br />
                            )}
                          </>
                        ) : (
                          <>
                            <b>Status:</b> Not answered
                            <br />
                          </>
                        )}
                      </div>
                      <div style={{ marginRight: "auto", marginLeft: "2%", width: "fit-content", color: "grey", fontWeight: 300, marginTop: "-2px" }}>
                        Called: {tz.unix(item?.unix_time_action_happened).format("MMMM D, h:mm A z")}
                      </div>
                      {/* <div className="message from">I'm glad you like it Steve! We've been working very hard to make this project a reality!</div> */}
                    </div>
                  ) : item?.dialer_action === "sms_recieved" ? (
                    <div style={{ marginTop: index ? "35px" : "0px" }} key={index}>
                      <div className="message from">
                        <div style={{ marginBottom: "5px", fontWeight: 600, width: "100%", textAlign: "center" }}>
                          Incoming SMS <SvgIcon type={"incomingCallBlack"} style={{ marginLeft: 8, height: 12, width: 12 }} />
                        </div>
                        <b>From:</b> {item?.dialer_number_from || ""}
                        <br />
                        <b>To:</b> {item?.dialer_number_to || ""}
                        <br />
                        <b>Text:</b>{" "}
                        {item?.dialer_sms_message?.length > 150 || enterSMS ? (
                          <Tooltip
                            placement={"right"}
                            title={
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize((item?.dialer_sms_message || "No sms to show").replace(/\n/g, "<br/>")),
                                }}
                              />
                            }
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  (item.dialer_sms_message?.substring(0, enterSMS || 150) + "..." || "No sms to show").replace(/\n/g, "<br/>")
                                ),
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize((item.dialer_sms_message || "No sms to show").replace(/\n/g, "<br/>")),
                            }}
                          />
                        )}
                        {/* <b>Status:</b> {item?.dialer_status_friendly ? item?.dialer_status_friendly : <MinusOutlined style={{ marginLeft: "3px" }} />}
                            <br />
                            <b>Notes:</b>{" "}
                            {item?.dialer_call_notes ? (
                              item?.dialer_call_notes?.length > 100 ? (
                                <Tooltip placement={"right"} title={`Call notes: ${item?.dialer_call_notes}`}>
                                  {item?.dialer_call_notes?.substring(0, 100) + "..."}
                                </Tooltip>
                              ) : (
                                item?.dialer_call_notes
                              )
                            ) : (
                              // <span style={{ marginTop: "50px" }}>
                              <MinusOutlined style={{ marginLeft: "3px" }} />
                              // </span>
                            )}
                            {item?.step_number && fixCampName ? (
                              <Typography.Paragraph
                                // className={"emails-company-white"}
                                style={{
                                  cursor: actCampId ? "pointer" : "none",
                                  color: "white",
                                  marginLeft: "auto",
                                  marginRight: "2%",
                                  marginTop: "5px",
                                  width: "fit-content",
                                }}
                                onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                              >
                                {`Step ${item?.step_number} in`}{" "}
                                {fixCampName && fixCampName?.length > 25 ? (
                                  <Button
                                    type={"link"}
                                    style={{ color: "white", textDecoration: "underline", margin: 0, marginLeft: "2px", padding: 0, height: "19px" }}
                                  >
                                    {" "}
                                    <Tooltip placement={"right"} title={`Campaign name: ${fixCampName}`}>
                                      {fixCampName?.substring(0, 25) + "..."}
                                    </Tooltip>{" "}
                                  </Button>
                                ) : (
                                  <Button
                                    type={"link"}
                                    style={{ color: "white", textDecoration: "underline", margin: 0, marginLeft: "2px", padding: 0, height: "19px" }}
                                  >
                                    {" "}
                                    {fixCampName}{" "}
                                  </Button>
                                )}{" "}
                                
                              </Typography.Paragraph>
                            ) : (
                              ""
                            )} */}
                      </div>
                      <div style={{ marginRight: "auto", marginLeft: "2%", width: "fit-content", color: "grey", fontWeight: 300, marginTop: "-2px" }}>
                        Received: {tz.unix(item?.unix_time_action_happened).format("MMMM D, h:mm A z")}
                      </div>
                      {/* <div className="message from">I'm glad you like it Steve! We've been working very hard to make this project a reality!</div> */}
                    </div>
                  ) : item?.email_action === "email_sent" ? (
                    <div style={{ marginTop: index ? "35px" : "0px" }} key={index}>
                      <div className="message to">
                        <div style={{ marginBottom: "5px", fontWeight: 600, width: "100%", textAlign: "center" }}>
                          Sent email <SvgIcon type={"emailWhite"} style={{ marginLeft: 8, height: 11, width: 11 }} />
                        </div>
                        <b>From:</b> {item?.email_from}
                        <br />
                        <b>To:</b>{" "}
                        {item?.email_to?.length ? (
                          item?.email_to?.map((em, index) => {
                            if (index === item.email_to.length - 1 && item.email_to.length > 1) {
                              return ` and ${em}`;
                            } else if (index === item.email_to.length - 2 && item.email_to.length > 2) {
                              return `${em},`;
                            } else if (index < item.email_to.length - 1) {
                              return `${em}, `;
                            } else {
                              return em;
                            }
                          })
                        ) : (
                          <MinusOutlined style={{ marginLeft: "3px" }} />
                        )}
                        {item?.email_cc?.length ? (
                          <>
                            <br />
                            <b>CC:</b>{" "}
                            {item?.email_cc?.map((em, index) => {
                              if (index === item.email_cc.length - 1 && item.email_cc.length > 1) {
                                return ` and ${em}`;
                              } else if (index === item.email_cc.length - 2 && item.email_cc.length > 2) {
                                return `${em},`;
                              } else if (index < item.email_cc.length - 1) {
                                return `${em}, `;
                              } else {
                                return em;
                              }
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        <br />
                        <br />
                        <div style={{ marginBottom: "5px" }}>
                          {" "}
                          {item?.meetz_email_subject === "ReplyTo" || item?.meetz_email_subject?.toLowerCase().includes("re:") ? (
                            <>
                              {" "}
                              <b>Replied to previous steps email</b>
                            </>
                          ) : (
                            <>
                              {" "}
                              <b>Subject:</b> {item?.meetz_email_subject}
                            </>
                          )}
                        </div>
                        <b>Body:</b>{" "}
                        {item?.meetz_email_body && item?.meetz_email_body?.length > 350 ? (
                          <Tooltip
                            placement={"right"}
                            title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fixedLinksInEmailBody || "No email body to show") }} />}
                          >
                            <div
                              className="email-content-makewhite"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(fixedLinksInEmailBody?.substring(0, 350) + "..." || "No email body to show"),
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div
                            className="email-content-makewhite"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fixedLinksInEmailBody || "No email body to show") }}
                          />
                        )}
                        {item?.step_number && fixCampName ? (
                          <Typography.Paragraph
                            // className={"emails-company-white"}
                            style={{
                              cursor: actCampId ? "pointer" : "default",
                              color: "white",
                              marginLeft: "auto",
                              marginRight: "2%",
                              marginTop: "5px",
                              width: "fit-content",
                              // width: "100%",
                            }}
                            onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                          >
                            {`Step ${item?.step_number} in`}{" "}
                            {fixCampName && fixCampName?.length > 25 ? (
                              <Tooltip placement={"right"} title={`Campaign name: ${fixCampName}`}>
                                {" "}
                                <Button
                                  type={"link"}
                                  style={{
                                    color: "white",
                                    textDecoration: "underline",
                                    margin: 0,
                                    marginLeft: "1px",
                                    padding: 0,
                                    height: "19px",
                                    cursor: actCampId ? "pointer" : "default",
                                  }}
                                >
                                  {" "}
                                  {fixCampName?.substring(0, 25) + "..."}
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button
                                type={"link"}
                                style={{
                                  cursor: actCampId ? "pointer" : "default",
                                  color: "white",
                                  textDecoration: "underline",
                                  margin: 0,
                                  marginLeft: "2px",
                                  padding: 0,
                                  height: "19px",
                                }}
                              >
                                {" "}
                                {fixCampName}{" "}
                              </Button>
                            )}{" "}
                            {/* <Button type={"link"} className={"company-link"}>
                    {analytics?.campaign_name}
                  </Button> */}
                          </Typography.Paragraph>
                        ) : item?.is_manual_email_reply_id ? (
                          <Typography.Paragraph
                            // className={"emails-company-white"}
                            style={{
                              // cursor: actCampId ? "pointer" : "default",
                              color: "white",
                              marginLeft: "auto",
                              marginRight: "2%",
                              marginTop: "5px",
                              width: "fit-content",
                              // width: "100%",
                            }}
                            // onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                          >
                            {`Manual email you sent`}{" "}
                          </Typography.Paragraph>
                        ) : (
                          ""
                        )}{" "}
                      </div>
                      <Flex style={{ marginLeft: "25%" }}>
                        <Tooltip
                          placement={"top"}
                          title={
                            item?.openedEmail
                              ? `Opened email ${item?.openedEmail} times. *Note: This might count their email providers automated open.`
                              : `Didn't open the email.`
                          }
                        >
                          {" "}
                          <Flex style={{ justifyContent: "center", alignItems: "center", fontWeight: 700 }}>
                            {item?.openedEmail}
                            {item?.openedEmail ? (
                              <SvgIcon type={"greenOpenSymbol"} style={{ marginLeft: 3, height: 18, width: 18 }} />
                            ) : (
                              <SvgIcon type={"greyOpenSymbol"} style={{ marginLeft: 3, height: 18, width: 18 }} />
                            )}
                          </Flex>
                        </Tooltip>
                        <Tooltip
                          placement={"top"}
                          title={
                            item?.clickedEmail
                              ? `Clicked email's links ${item?.clickedEmail} times`
                              : `Didn't click on any links. *Note: This only tracks clicks on links, not the email itself.`
                          }
                        >
                          <Flex style={{ marginLeft: "10px", justifyContent: "center", alignItems: "center", fontWeight: 700 }}>
                            {item?.clickedEmail}
                            {item?.clickedEmail ? (
                              <SvgIcon type={"blueClickSymbol"} style={{ marginLeft: 3, height: 18, width: 18 }} />
                            ) : (
                              <SvgIcon type={"greyClickSymbol"} style={{ marginLeft: 3, height: 18, width: 18 }} />
                            )}
                          </Flex>
                        </Tooltip>
                        {/* {item?.clickedEmail} */}
                        <div style={{ marginLeft: "auto", marginRight: "2%", width: "fit-content", color: "grey", fontWeight: 300 }}>
                          Sent: {tz.unix(item?.unix_time_action_happened).format("MMMM D, h:mm A z")}
                        </div>
                      </Flex>
                      {/* <div className="message from">I'm glad you like it Steve! We've been working very hard to make this project a reality!</div> */}
                    </div>
                  ) : item?.email_action === "email_replied" ? (
                    <div style={{ marginTop: index ? "35px" : "0px" }} key={index}>
                      <div className="message from">
                        <div style={{ marginBottom: "5px", fontWeight: 600, width: "100%", textAlign: "center" }}>
                          Incoming Email <SvgIcon type={"emailBlack"} style={{ marginLeft: 3, height: 12, width: 12 }} />
                        </div>
                        <b>From:</b> {item?.email_from}
                        <br />
                        <b>To:</b>{" "}
                        {item?.email_to?.length ? (
                          item?.email_to?.map((em, index) => {
                            if (index === item.email_to.length - 1 && item.email_to.length > 1) {
                              return ` and ${em}`;
                            } else if (index === item.email_to.length - 2 && item.email_to.length > 2) {
                              return `${em},`;
                            } else if (index < item.email_to.length - 1) {
                              return `${em}, `;
                            } else {
                              return em;
                            }
                          })
                        ) : (
                          <MinusOutlined style={{ marginLeft: "3px" }} />
                        )}
                        {item?.email_cc?.length ? (
                          <>
                            <br />
                            <b>CC:</b>{" "}
                            {item?.email_cc?.map((em, index) => {
                              if (index === item.email_cc.length - 1 && item.email_cc.length > 1) {
                                return ` and ${em}`;
                              } else if (index === item.email_cc.length - 2 && item.email_cc.length > 2) {
                                return `${em},`;
                              } else if (index < item.email_cc.length - 1) {
                                return `${em}, `;
                              } else {
                                return em;
                              }
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {/* <br />
                            <b>To:</b>{" "}
                            {item?.email_to?.map((em, index) => {
                              if (index === item.email_to.length - 1 && item.email_to.length > 1) {
                                return ` and ${em}`;
                              } else if (index === item.email_to.length - 2 && item.email_to.length > 2) {
                                return `${em},`;
                              } else if (index < item.email_to.length - 1) {
                                return `${em}, `;
                              } else {
                                return em;
                              }
                            })} */}
                        <br />
                        <br />
                        <div style={{ marginBottom: "5px" }}>
                          {" "}
                          {item?.replied_email_subject === "ReplyTo" || item?.replied_email_subject?.toLowerCase().includes("re:") ? (
                            <>
                              {" "}
                              <b>Replied to previous email</b>
                            </>
                          ) : (
                            <>
                              {" "}
                              <b>Subject:</b> {item?.replied_email_subject}
                            </>
                          )}
                        </div>
                        <b>Body:</b>{" "}
                        {item?.replied_email_body && item?.replied_email_body?.length > 350 ? (
                          <Tooltip
                            placement={"right"}
                            title={<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fixedLinksInEmailBodyReply || "No email body to show") }} />}
                          >
                            <div
                              className="email-content-makewhite"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(fixedLinksInEmailBodyReply?.substring(0, 350) + "..." || "No email body to show"),
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <div
                            className="email-content-makewhite"
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fixedLinksInEmailBodyReply || "No email body to show") }}
                          />
                        )}
                        {item?.step_number && fixCampName ? (
                          <Typography.Paragraph
                            // className={"emails-company-white"}
                            style={{
                              cursor: actCampId ? "pointer" : "default",
                              // color: "white",
                              marginLeft: "auto",
                              marginRight: "2%",
                              marginTop: "5px",
                              width: "fit-content",
                              // width: "100%",
                            }}
                            onClick={() => actCampId && navigate(`/campaign/${btoa(`${actCampId + 12000}`)}`)}
                          >
                            {`Step ${item?.step_number} in`}{" "}
                            {fixCampName && fixCampName?.length > 25 ? (
                              <Tooltip
                                placement={"right"}
                                title={`Campaign name: ${fixCampName}`}
                                // style={{
                                //   color: "white",
                                //   textDecoration: "underline",
                                //   margin: 0,
                                //   marginLeft: "1px",
                                //   padding: 0,
                                //   height: "19px",
                                //   cursor: actCampId ? "pointer" : "default",
                                // }}
                              >
                                {" "}
                                <Button
                                  type={"link"}
                                  style={{
                                    // color: "white",
                                    // textDecoration: "underline",
                                    margin: 0,
                                    marginLeft: "1px",
                                    padding: 0,
                                    height: "19px",
                                    cursor: actCampId ? "pointer" : "default",
                                  }}
                                >
                                  {" "}
                                  {fixCampName?.substring(0, 25) + "..."}
                                </Button>
                              </Tooltip>
                            ) : (
                              <Button
                                type={"link"}
                                style={{
                                  cursor: actCampId ? "pointer" : "default",
                                  // color: "white",
                                  // textDecoration: "underline",
                                  margin: 0,
                                  marginLeft: "2px",
                                  padding: 0,
                                  height: "19px",
                                }}
                              >
                                {" "}
                                {fixCampName}{" "}
                              </Button>
                            )}{" "}
                            {/* <Button type={"link"} className={"company-link"}>
                    {analytics?.campaign_name}
                  </Button> */}
                          </Typography.Paragraph>
                        ) : (
                          ""
                        )}{" "}
                      </div>
                      <div style={{ marginRight: "auto", marginLeft: "2%", width: "fit-content", color: "grey", fontWeight: 300 }}>
                        Received: {tz.unix(item?.unix_time_action_happened).format("MMMM D, h:mm A z")}
                      </div>
                    </div>
                  ) : (
                    "sss"
                  )}
                </>
              );
            })
          ) : (
            <LoadingImage empty isDialer={isDialer} />
          )
        ) : (
          <LoadingImage isDialer={isDialer} />
        )}
      </div>
      {/* </div>  */}
    </>
  );
};
export default ActivityComponent;
