/* eslint-disable react/jsx-fragments */
import type { GetProp } from "antd";
import { Alert, Button, Checkbox, Flex, Row, Select, Tooltip, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, CustomSelect, Modal } from "src/components/ui";
import "./index.less";
import { initialOptions } from "../..";

const { Text } = Typography;

interface CsvData {
  title: string;
  info: string[];
}

type ExtractionFileModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  options: {
    value: string;
    label: string;
    column: null;
  }[];
  setOptions: (upd: any) => void;
  setProspectAmount: (num: number) => void;
  fileData?: CsvData[];
  fileName?: string;
};

export const UploadingFormModal: FC<ExtractionFileModalProps> = ({ open, onCancel, fileData, fileName, setProspectAmount, options, setOptions }) => {
  // console.log(fileData, "FileData");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [openUploadFormModal, setOpenUploadFormModal] = useState(false);

  const closeUploadFormModal = () => {
    setOpenUploadFormModal(false);
  };
  const handleUpload = () => {
    onCancel();
    // setOpenUploadFormModal(false)
  };

  useEffect(() => {
    setOptions(initialOptions);
    setProspectAmount(0);
  }, [fileName]);

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    // console.log("checked = ", checkedValues);
  };
  const [arrayObjOfFirst, setArrayObjOfFirst] = useState([]);
  const [objectArrOfCells, setObjectArrOfCells] = useState({});

  useEffect(() => {
    // 🫧🧹🧼 cleans the data to display
    const columnsFirstFound = [];
    const objArrOfCells = {};
    const arrayObjFirst = [];
    const stopCheckingColumn = [];
    if (fileData) {
      fileData.forEach((rw: any, iRw) => {
        // console.log(rw,'rwrwrwr');
        rw.forEach((cel, cRw) => {
          const cell = cel.toString();

          if (stopCheckingColumn.indexOf(cRw) > -1 || !cell?.length) {
            return;
          }

          if (columnsFirstFound.indexOf(cRw) === -1) {
            arrayObjFirst.push({ firstCell: cell, iRw, cRw });
            columnsFirstFound.push(cRw);
            return;
          }

          if (objArrOfCells[cRw]?.length === 4) {
            stopCheckingColumn.push(cRw);
            return;
          }

          if (objArrOfCells[cRw]?.length) {
            objArrOfCells[cRw].push(cell);
          } else {
            objArrOfCells[cRw] = [cell];
          }
        });
      });

      const compare = (a, b) => {
        if (a.cRw < b.cRw) {
          return -1;
        }
        if (a.cRw > b.cRw) {
          return 1;
        }
        return 0;
      };
      arrayObjFirst.sort(compare);
      setArrayObjOfFirst(arrayObjFirst);
      setObjectArrOfCells(objArrOfCells);
    }
    // console.log(fileData, "FileData2");
  }, [fileData]);
  const [emailError, setEmailError] = useState({});

  useEffect(() => {
    if (arrayObjOfFirst?.length) {
      const columnNames = arrayObjOfFirst?.map((col) => col?.firstCell);
      console.log(columnNames, "columnNames");
      if (
        JSON.stringify(columnNames) ===
        JSON.stringify([
          "Email Address",
          "First Name",
          "Last Name",
          "Company",
          "Company's Website",
          "Company's Linkedin",
          "Job Title",
          "Department",
          "Industry",
          "Sub Industry",
          "Company Phone Number",
          "Direct Phone Number",
          "Mobile Phone Number",
          "Department",
          "Seniority Level",
          "Contacts City",
          "Contacts State",
          "Contacts Country",
          "LinkedIn",
          "Twitter",
          "Facebook",
          "Company's HeadCount",
          "Company's Revenue",
          "Company's Specialties",
          "Company City",
          "Company Address",
          "Company Country",
          "Company City",
          "Company Year Founded",
        ])
      ) {
        //  this is a Meetz file uploaded
        setOptions([
          {
            value: "email",
            label: "Email",
            column: 0,
          },
          {
            value: "first_name",
            label: "First Name",
            column: 1,
          },
          {
            value: "last_name",
            label: "Last Name",
            column: 2,
          },
          {
            value: "linkedin_profile_url",
            label: "LinkedIn URL",
            column: 18,
          },
          {
            value: "title",
            label: "Job Title",
            column: 6,
          },
          {
            value: "business_number",
            label: "Company Phone Number",
            column: 10,
          },
          {
            value: "direct_phone",
            label: "Direct Phone Number",
            column: 11,
          },
          {
            value: "mobile_phone",
            label: "Mobile Phone Number",
            column: 12,
          },
          {
            value: "company_name",
            label: "Company Name",
            column: 3,
          },
          {
            value: "company_url",
            label: "Company Website URL",
            column: 4,
          },
        ]);
        // console.log(objectArrOfCells[0]?.length ? objectArrOfCells[0]?.filter((email) => !emailPattern?.test(email)) : 0, "the bad ones");
        const amntOfPros = objectArrOfCells[0]?.length ? objectArrOfCells[0]?.filter((email) => emailPattern?.test(email))?.length || 0 : 0;
        setProspectAmount(amntOfPros); // THIS IS NOT CORECT LOGIC IN CASE ITS NEEDED only returns 4 the other setProspectAmount is correct and should be used
        if (amntOfPros) {
          setEmailError({});
        } else {
          setEmailError({ errCollumn: 0 });
        }
        // setProspectAmount(objectArrOfCells[0]?.length && objectArrOfCells[0]?.filter(email => emailPattern?.test(email))?.length || 0 : 0)
      }
    }
  }, [arrayObjOfFirst]);

  const emailCol = options?.find((opt) => opt.value === "email");

  const [busPhoneError, setBusPhoneError] = useState({});
  const [dirPhoneError, setDirPhoneError] = useState({});
  const [mobPhoneError, setMobPhoneError] = useState({});

  const valEmailSelected =
    emailCol?.column !== null && emailCol?.column !== undefined && (emailError?.errCollumn === null || emailError?.errCollumn === undefined);

  const updatePropertiesCol = (selVal, column) => {
    // console.log(selVal,column,'selValcolumn');

    const objIndToUpd = options?.findIndex((opt) => opt.value === selVal);
    const objToUpd = options[objIndToUpd];

    let optionCopy = [...options]?.map((option) => {
      if (option?.column === column) {
        return { ...option, column: null };
      }
      return option;
    });
    optionCopy[objIndToUpd] =
      objToUpd?.column !== undefined && objToUpd?.column !== null && objToUpd?.column === column ? { ...objToUpd, column: null } : { ...objToUpd, column };
    const showingItsSelected = `<span style="color: lightblue;"> - Selected</span>`;
    optionCopy = optionCopy?.map((opt) =>
      opt?.column !== undefined && opt?.column !== null
        ? { ...opt, label: opt?.label?.includes(showingItsSelected) ? opt?.label : `${opt?.label}${showingItsSelected}` }
        : { ...opt, label: opt?.label?.replace(showingItsSelected, "") }
    );

    setOptions(optionCopy);
    const phonePattern = /^(?:\+|00)/;
    // checking validity
    optionCopy?.map((opt) => {
      if (opt?.value === "email") {
        let validEmailCount = 0;
        if (opt?.column !== undefined && opt?.column !== null) {
          if (objectArrOfCells[opt?.column]?.length) {
            // validEmailCount = fileData?.forEach((rw) => rw[opt?.column]?.filter((email) => emailPattern?.test(email))?.length || 0);
            fileData?.forEach((rw) => {
              if (rw && rw?.length > opt?.column) {
                const filteredEmails = emailPattern?.test(rw[opt?.column]);
                validEmailCount += filteredEmails ? 1 : 0;
              }
            });
            if (validEmailCount > 0) {
              setEmailError({});
            } else {
              setEmailError({ errCollumn: opt?.column });
            }
          } else {
            setEmailError({});
          }
        } else {
          setEmailError({});
        }
        setProspectAmount(validEmailCount);
      }
      if (opt?.value === "business_number" || opt?.value === "direct_phone" || opt?.value === "mobile_phone") {
        const updateProperState = (newState) => {
          switch (opt?.value) {
            case "business_number":
              setBusPhoneError(newState);
              break;
            case "direct_phone":
              setDirPhoneError(newState);
              break;
            case "mobile_phone":
              setMobPhoneError(newState);
              break;
          }
        };
        if (opt?.column !== undefined && opt?.column !== null && objectArrOfCells[opt?.column]?.length) {
          const phoneArr = objectArrOfCells[opt?.column];

          if (
            phoneArr?.some((phone) => {
              const cleanedPhone = phone?.replace(/[^0-9]/g, "");
              return cleanedPhone.length >= 8;
            })
          ) {
            if (phoneArr?.some((phone) => phonePattern.test(phone))) {
              // found + extenstion number and valid phone
              updateProperState({});
            } else {
              updateProperState({
                errCollumn: opt?.column,
                message: `No country extension found in this column, Meetz will default to +1 (USA/Canada)`,
                level: "warning",
              });
            }
          } else {
            updateProperState({ errCollumn: opt?.column, message: `No valid phone numbers found`, level: "error" });
          }
        } else {
          updateProperState({});
        }
      }
    });
  };

  return (
    <Modal width={820} centered open={open} title={"Label your files columns"} onCancel={onCancel}>
      {" "}
      <text>
        Tip: The <b>more labels</b> you add to the file the <b>more personalized</b> the outreach campaign will be, it's highly recommended to mark the LinkedIn
        Url. If you will use the phone mark the phone number column(s) as well.
      </text>
      <br />
      <br />
      <Flex gap={10} vertical className={"form-container"}>
        <Flex justify={"space-between"} align={"center"} className={"form-item column-head"}>
          <Text className={"item-title"} style={{ width: "100%", textAlign: "center" }}>
            Column headers from your file
          </Text>
          <Text className={"item-info"} style={{ width: "100%", textAlign: "center" }}>
            Preview File Information
          </Text>
          <Text className={"item-select"} style={{ width: "100%", textAlign: "center" }}>
            Label Property
          </Text>
        </Flex>
        {arrayObjOfFirst?.map((colObj) => (
          <>
            <Flex justify={"space-between"} align={"center"} className={"form-item"} key={colObj?.cRw}>
              {
                <>
                  <Tooltip placement={"left"} title={colObj?.firstCell}>
                    <div style={{ overflow: "hidden", width: "180px", whiteSpace: "nowrap", textOverflow: "ellipsis", display: "inline-block" }}>
                      {" "}
                      {colObj?.firstCell?.substring(0, 35)}
                    </div>
                  </Tooltip>
                </>
              }
              <TextArea
                variant={"filled"}
                className={"item-info"}
                value={objectArrOfCells[colObj?.cRw]?.map((info) => (info.length > 35 ? info.slice(0, 35) + "..." : info)).join("\n")}
                // onClick={() => false}
                // disabled
                readOnly
              />
              <CustomSelect
                allowClear
                key={colObj?.cRw}
                placeholder={"Choose Property"}
                className={"item-select"}
                value={options?.find((opt) => opt?.column === colObj?.cRw)?.value || null}
                options={options}
                onChange={(e) => updatePropertiesCol(e, colObj?.cRw)}
                style={{ width: "250px" }}
                useOnSelectChange
              />
            </Flex>
            {busPhoneError?.errCollumn !== undefined && busPhoneError?.errCollumn === colObj?.cRw && (
              <Alert message={busPhoneError?.message} type={busPhoneError?.level} showIcon />
            )}
            {/* TODOF later add info that mobile and direct will be used for SMS sends (if no direct then use mobile) */}
            {dirPhoneError?.errCollumn !== undefined && dirPhoneError?.errCollumn === colObj?.cRw && (
              <Alert message={dirPhoneError?.message} type={dirPhoneError?.level} showIcon />
            )}
            {mobPhoneError?.errCollumn !== undefined && mobPhoneError?.errCollumn === colObj?.cRw && (
              <Alert message={mobPhoneError?.message} type={mobPhoneError?.level} showIcon />
            )}
            {emailError?.errCollumn !== undefined && emailError?.errCollumn === colObj?.cRw && (
              <Alert message="No valid email address found" type="error" showIcon />
            )}
          </>
        ))}
      </Flex>
      <ConfirmButtons
        onCancel={onCancel}
        leftButtonTitle={"Back"}
        rightButtonTitle={"Save Columns"}
        onClick={onCancel}
        disabled={!valEmailSelected}
        disabledExplanation="Fix: No valid email column selected"
      />
      {/* <Row justify={"space-between"} align={"middle"}>
        <Button className={"link"} style={{width: 211}} onClick={onCancel}>Back</Button>
        <Button className={"primary"} style={{width: 211}} onClick={handleUpload}>Upload</Button>
      </Row> */}
      <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginTop: 12 }}>
        *When you use our power dialer, the contacts phone numbers (Company/Direct/Mobile) will auto rotate to increase the chance of a conversations
      </Typography.Paragraph>
    </Modal>
  );
};
