/* eslint-disable react/jsx-fragments */
import { CheckOutlined, MinusOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, Flex, Form, Image, Input, MenuProps, Radio, Row, Space, Tooltip, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useCallback, useEffect, useState } from "react";
import NoData from "src/shared/images/noData.png";
import { debounce } from "lodash";
import { ConfirmButtons, Modal, StatusTag, Table, TableHeader } from "src/components/ui";
import "./index.less";
import { App } from "src/types";
import { forStepTwoData } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/stepTwoData";
import { useDispatch, useSelector } from "react-redux";
import {
  outreachCampaignSelector,
  resetNewCmpId,
  resetSelProsIds,
  resetUplName,
  resetUploadedInfo,
  selectAllCurrent,
  setSelectedRowKeys,
} from "src/store/outreach-campaigns";
import { SvgIcon } from "src/shared/icons";
import { ProspectsFilter } from "./ProspectsFilter";
import { PhonesCell } from "src/components/pages/prospect-data";
import {
  addProsToCampaign,
  editContactsInAccountAllow,
  editContactsInAccountCampaign,
  editContactsInAccountDelete,
  editContactsInAccountStop,
  getFilteredLeads,
  searchForContact,
} from "../../../../../store/outreach-campaigns/asyncRequests";
import { useNavigate, useParams } from "react-router";
import { prospectDataSelector, resetSelectedRows } from "src/store/prospect_data";
import { userSelector } from "src/store/user";
import { ExtractionFileModal } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/ui/ExtractionFileModal";
import { Loader } from "src/components/layout";
import { UploadProspectsModal } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/ui/UploadProspectsModal";
import { openPopUp } from "src/store/outreach-phones";

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Add To Sequence",
  },
  {
    key: "2",
    label: "Take Off Of Sequence",
  },
];

export const ProspectsTable: FC<{
  handleDetailsModalOpen?: (id: App.ForStepTwoProspectEntity["id"]) => void;
  addProsToCamp?: boolean;
  crm?: boolean;
}> = ({ handleDetailsModalOpen, addProsToCamp, crm }) => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  const { Text } = Typography;
  const UploadingLeadsFulfill = ({ leadsLeftOut, leadsUploaded }) => {
    //  if you show how many were not uploaded so too many questions (even the column title is counted as not uploaded)
    return (
      <Space direction={"vertical"}>
        <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
          {leadsUploaded ? <SvgIcon type={"success"} /> : <SvgIcon type={"alarm"} />}
          <div>
            {!!leadsLeftOut && !leadsUploaded && (
              <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#ff772ed9" }}>
                {`${
                  leadsLeftOut === 1 ? `1 Prospect Was` : `${leadsLeftOut} Prospects Were `
                } Not Uploaded, Deselect the "Filter" Checkboxes In The Upload Section If Necessary.`}
              </Typography.Paragraph>
            )}
            {!!leadsUploaded && (
              <Typography.Paragraph className={"bulk-message-title-warn"} style={{ color: "#4ec352d9" }}>
                {`${leadsUploaded === 1 ? `1 Prospect Was` : `${leadsUploaded} Prospects Were`} Successfully Uploaded!`}
              </Typography.Paragraph>
            )}
          </div>
        </Row>
      </Space>
    );
  };
  const UploadingLeads = ({ prospectAmountExtracting }) => {
    return (
      <Space direction={"vertical"}>
        <Row align={"middle"} style={{ gap: 8, margin: "10px" }}>
          <Loader size="large" style={{ marginRight: "25px" }} />
          <Typography.Paragraph className={"bulk-message-title"}>{`Uploading ${prospectAmountExtracting} Potential Future Clients`}</Typography.Paragraph>
          <SvgIcon type={"ultimate"} />
        </Row>
      </Space>
    );
  };
  const columns = [
    // {
    //   title: "Contacts",
    //   className: "clickable-table-title",
    //   key: "first_name",
    //   // align: "right",
    //   render: record =>
    //     record.first_name ? (
    //       <Typography.Paragraph onClick={() => handleDetailsModalOpen && handleDetailsModalOpen(record.id)}>
    //         {record.contacts}
    //       </Typography.Paragraph>
    //     ) : (
    //       <MinusOutlined />
    //     ),
    // }, TODOF add this later to the name so they can open and get the contact details
    {
      title: "Details",
      dataIndex: "first_name",
      key: "first_name",
      width: "230px",
      // align: "right",
      render: (_, val) => {
        console.log(val, "valval");
        let jobTitle = val?.title;
        if (jobTitle?.length > 18) {
          jobTitle = (
            <Tooltip placement={"top"} title={jobTitle}>
              {jobTitle?.substring(0, 18) + "..."}
            </Tooltip>
          );
        }
        let companyName = val?.company_name;
        if (companyName?.length > 15) {
          companyName = (
            <Tooltip placement={"top"} title={companyName}>
              {companyName?.substring(0, 15) + "..."}
            </Tooltip>
          );
        }

        return (
          <>
            {
              <div
                style={{ cursor: "pointer", borderRadius: "8px", padding: "2px" }}
                onClick={() => dispatch(openPopUp(val))}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              >
                <div>
                  <b>First name:</b> {val?.first_name || <MinusOutlined style={{ marginLeft: "5px" }} />} {val?.last_name || ""}
                </div>
                <div>
                  <b>Job Title:</b> {jobTitle || <MinusOutlined style={{ marginLeft: "5px" }} />}
                </div>
                <div>
                  <b>Company:</b> {companyName || <MinusOutlined style={{ marginLeft: "5px" }} />}
                </div>
                <div>
                  <b>Email:</b> {val?.email || <MinusOutlined style={{ marginLeft: "5px" }} />}
                </div>
              </div>
            }
          </>
        );
      },
    },
    {
      title: "Phone Numbers",
      key: "status",
      align: "center",
      width: "110px",
      render: (text, record) => (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <PhonesCell phonesData={[record?.business_number, record?.direct_phone, record?.mobile_phone]} />
        </div>
      ),
    },
    {
      title: "LinkedIn Url",
      dataIndex: "linkedin_profile_url",
      key: "linkedin_profile_url",
      // align: "left",
      width: "90px",
      align: "center",
      render: (record) =>
        record?.length ? (
          <Tooltip placement={"right"} title={record}>
            <CheckOutlined />{" "}
          </Tooltip>
        ) : (
          <MinusOutlined />
        ),
    },
    {
      title: "Upload Name",
      dataIndex: "upload_name",
      key: "upload_name",
      width: "80px",
      render: (record, val) => {
        if (record?.length) {
          if (record?.length > 15) {
            return (
              <>
                <Tooltip placement={"right"} title={record}>
                  {" "}
                  {record?.substring(0, 15) + "..."}
                </Tooltip>

                {val?.provider_name ? (
                  <>
                    <br />
                    <Tooltip placement={"right"} title={`This contact came from your integration with: ${val?.provider_name}`}>
                      {" "}
                      <span style={{ color: "purple", fontSize: "12px", fontWeight: 500 }}>{val?.provider_name}</span>{" "}
                    </Tooltip>{" "}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else {
            return (
              <>
                {record}

                {val?.provider_name ? (
                  <>
                    {" "}
                    <br />
                    <br />{" "}
                    <Tooltip placement={"right"} title={`This contact came from integration with: ${val?.provider_name}`}>
                      <span style={{ color: "teal", fontSize: "12px", fontWeight: 500 }}>{val?.provider_name}</span>{" "}
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          }
        } else {
          return <MinusOutlined />;
        }
      },
    },
    {
      title: "# Of Campaigns In",
      dataIndex: "campaigns",
      key: "campaigns",
      align: "center",
      width: "160px",
      // style: { marginRight: "10px" },
      render: (record) =>
        record?.length ? (
          <Tooltip
            placement={"top"}
            title={
              <>
                <b>Campaigns Prospect Is Connected To Already:</b>
                {record?.map((campName, i) => {
                  return (
                    <>
                      <br />
                      <br />
                      <b>{i + 1}.</b> {campName}
                    </>
                  );
                })}
              </>
            }
          >
            {record?.length}
          </Tooltip>
        ) : (
          "0"
        ),
    },
    {
      title: "Outreach Status",
      dataIndex: "campaigns",
      key: "campaigns",
      align: "center",
      width: "160px",
      // padding: "70px",
      render: (record, val) => {
        const status = val?.marked_as_dnc_reason as
          | "smsin"
          | "unsub"
          | "emailin"
          | "markeddnc"
          | "bounced"
          | "goodcalloutcome"
          | "badcalloutcome"
          | "suppressed"
          | "markedmanuallyasdonotcontact"
          | "connectedtodeal";

        let statusColor = "cyan";
        let displayStatus = "Ready";
        let displayStatusExpl = "Ready to be added into a campaign";
        switch (status) {
          case "smsin":
            displayStatus = "Got SMS";
            statusColor = "cyan";
            displayStatusExpl = "Won't continue to get your outreach campaigns. Prospect has replied with an SMS.";
            break;
          case "unsub":
            displayStatus = "Unsubscribed";
            statusColor = "orange";
            displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect has unsubscribed from your emails.`;
            break;
          case "emailin":
            displayStatus = "Email Reply";
            statusColor = "blue";
            displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect has replied with an email.`;
            break;
          case "markeddnc":
            displayStatus = "Marked DNC";
            statusColor = "yellow";
            displayStatusExpl = `Won't continue to get your outreach campaigns. You marked this prospect as Do Not Contact after a call`;
            break;
          case "bounced":
            displayStatus = "Bounced";
            statusColor = "red";
            displayStatusExpl = `Won't continue to get your outreach campaigns. Prospect's email bounced.`;
            break;
          case "goodcalloutcome":
            displayStatus = "Good Call Outcome";
            statusColor = "green";
            displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the call as a good outcome.`;
            break;
          case "badcalloutcome":
            displayStatus = "Bad Call Outcome";
            statusColor = "volcano";
            displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the call as a bad outcome.`;
            break;
          case "suppressed":
            displayStatus = "Suppressed DNC List";
            statusColor = "magenta";
            displayStatusExpl = `Won't continue to get your outreach campaigns. You marked the company or prospects email as DNC in your suppression list. You can find this in your account settings.`;
            break;
          case "markedmanuallyasdonotcontact":
            displayStatus = "CRM Marked DNC";
            statusColor = "lime";
            displayStatusExpl = `Won't continue to get your outreach campaigns. You marked this prospect as Do Not Contact in the Meetz CRM.`;
            break;
          case "connectedtodeal":
            displayStatus = "Connected to a deal";
            statusColor = "green";
            displayStatusExpl = `You connected this prospect to a deal in your "Deals" section. They will not continue to receive automated campaign steps, to avoid double outreach.`;
            break;
        }

        if (displayStatus === "Ready" && val?.prospectsinsalesseries?.length) {
          // check if in campaign and if active or not
          if (
            val?.prospectsinsalesseries?.some((sp) => {
              return sp?.new_outreach_campaign?.active && !sp?.is_finished;
            })
          ) {
            displayStatus = "In Campaign";
            statusColor = "blue";
            displayStatusExpl = `Prospect is currently in a campaign. They will continue to receive follow ups until the campaign is finished.`;
          } else {
            const prospectInActCampaignFin = val?.prospectsinsalesseries?.every((sp) => sp?.is_finished);
            if (prospectInActCampaignFin) {
              displayStatus = `Finished Campaign`;
              statusColor = "gold";
              displayStatusExpl = `Prospect is in a campaign and finished getting all the steps.`;
            } else {
              displayStatus = `Campaign Paused`;
              statusColor = "red";
              displayStatusExpl = `Prospect is currently in a campaign but not active. They will not receive any follow ups until the campaign is active again.`;
            }
          }
        }

        return (
          <>
            <Tooltip placement={"top"} title={displayStatusExpl}>
              <div style={{ width: "fit-content", margin: "auto" }}>
                <StatusTag color={statusColor} value={displayStatus} style={{ padding: "5px 20px", borderWidth: "1px", borderColor: "lightgrey" }} />
              </div>
            </Tooltip>
          </>
        );
      },
    },

    //   {
    //     title: "Actions",
    //     key: "action",
    //     render: (_, record) => (
    //       <Dropdown placement={"bottomRight"} menu={{ items: items }} trigger={["click"]}>
    //         <Space style={{ cursor: "pointer" }} onClick={e => e.preventDefault()}>
    //           <MoreOutlined />
    //         </Space>
    //       </Dropdown>
    //     ),
    //   },TODOF add option to  delete the prospect if needed
  ];

  // https://lordicon.com/icons/wired/outline TODOC add to rocket in the pop up also replace the icons if needed
  const dispatch = useDispatch();

  const {
    leads,
    leadsIdSelected,
    uplNameOptions,
    filteredAfterProsUploadName,
    leadsTotalCnt,
    loadingLeads,
    leadsIds,
    newCampId,
    loadingUploadPros,
    leadsLeftOut,
    leadsUploaded,
    allCampsList,
  } = useSelector(outreachCampaignSelector);
  console.log(allCampsList, "allCampsList");
  // console.log(leads, "leads");

  // const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [campToAddId, setcampToAddId] = useState<string | undefined>();
  // const [campToAddTo, setCampToAddTo] = useState<string | undefined>();
  const filteredCampaigns = allCampsList?.filter((item) => item?.campName?.toLowerCase()?.includes(searchTerm?.toLowerCase()))?.slice(0, 5);
  const handleRadioBtnChange = (e: any) => {
    setcampToAddId(e.target.value);
    // form.setFieldsValue({ camptocopy: e?.target?.value }); // Ensure form updates with selected campaign
  };
  const [uploadNameAvail, setUploadNameAvail] = useState([]);
  const [filters, setFilters] = useState({
    uploadName: "All",
    recentlyUpdated: false,
    // includeInCampaign: false,
    includeContactedAlready: false,
    campaignInName: "none",
    // excludeDoNotContact: true,
    prosStatusArr: [["canbecontacted"]],
  });
  const resetSelPros = () => {
    // remove
    dispatch(resetSelProsIds());
  };
  const [searchContact, setSearchContact] = useState("");

  const [page, setPage] = useState(1);
  const debouncedSearch = useCallback(
    debounce((search) => {
      setPage(1);
      if (search?.trim()?.length) {
        dispatch(searchForContact({ search }));
      } else {
        dispatch(
          getFilteredLeads({
            ...filters,
            page: 1,
            pageChange: false,
            selectedCampaignId: selectedCampaignId || null,
          })
        );
      }
    }, 1500),
    []
  );

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchContact(value);
    debouncedSearch(value);
  };

  const handlePageChanger = (pageChangeTo) => {
    // console.log(pageChangeTo, a, "pageChangeTo,a");
    setPage(pageChangeTo);
    dispatch(
      getFilteredLeads({
        ...filters,
        page: pageChangeTo,
        pageChange: true,
        search: searchContact,
      })
    );
  };

  const { id } = useParams();
  const selectedCampaignId = id ? (atob(`${id}`) ? +atob(`${id}`) : -1) - 12000 : null;

  useEffect(() => {
    setUploadNameAvail([
      { label: <span style={{ color: "black" }}>All</span>, value: "All" },
      ...uplNameOptions?.map((upl) => upl && { label: upl, value: upl })?.filter(Boolean),
    ]);
  }, [uplNameOptions]);

  const [campaignNames, setCampaignNames] = useState([]);
  useEffect(() => {
    setCampaignNames([
      { label: <span style={{ color: "black" }}>Not in any campaign</span>, value: "none" },
      { label: <span style={{ color: "black" }}>In any campaign</span>, value: "any" },
      { label: <span style={{ color: "black" }}>All prospects uploaded to Meetz</span>, value: "all" },
      ...allCampsList?.map((camp) => camp && { label: camp?.campName, value: camp?.id })?.filter(Boolean),
    ]);
  }, [uplNameOptions]);

  const [filtersSubmited, setFiltersSubmited] = useState(0);

  const submitFilters = () => {
    setSearchContact("");
    const filtereduploadName = filters?.uploadName !== "All" ? 1 : 0;
    const filteredCamps = filters?.campaignInName !== "none" ? 1 : 0;
    const prosStatusArr = filters?.prosStatusArr?.length - 1;
    const filteredrecentlyUpdated = filters?.recentlyUpdated ? 1 : 0;
    // const filteredincludeInCampaign = filters?.includeInCampaign ? 1 : 0;
    const filteredincludeContactedAlready = filters?.includeContactedAlready ? 1 : 0;

    setFiltersSubmited(filtereduploadName + filteredCamps + prosStatusArr + filteredrecentlyUpdated + filteredincludeContactedAlready);

    setOpenFilterDrawer(false);
    message.info("Applying Filters");
    // remove
    // console.log(filters, "filters");
    setPage(1);
    console.log(filters, "filters");
    dispatch(
      getFilteredLeads({
        ...filters,
        page: 1,
        pageChange: false,
        selectedCampaignId: selectedCampaignId || null,
      })
    );
  };

  useEffect(() => {
    if (filteredAfterProsUploadName?.length) {
      setFilters({
        uploadName: filteredAfterProsUploadName,
        recentlyUpdated: true,
        // includeInCampaign: true,
        includeContactedAlready: true,
        prosStatusArr: ["canbecontacted"],
      });
      setFiltersSubmited(4);
      setPage(1);
      dispatch(resetUplName());
    }
  }, [filteredAfterProsUploadName]);

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    // pagination selection logic check whats selected on page then rem/add based on the logic
    const leadsOnPage = leads
      ?.map((lead) => lead?.id)
      ?.filter(Boolean)
      ?.filter((id) => leadsIdSelected.includes(id));

    const added = newSelectedRowKeys.filter((key) => !leadsOnPage.includes(key));
    const removed = leadsOnPage.filter((key) => !newSelectedRowKeys.includes(key));
    // console.log(added, removed, "removedremoved");

    dispatch(setSelectedRowKeys({ added, removed }));
  };

  const rowSelection = {
    selectedRowKeys: leadsIdSelected,
    onChange: onSelectChange,
  };

  const setUploadNameToSearch = (uploadName) => {
    setFilters({ ...filters, uploadName });
  };
  const setCampaignInName = (campaignInName) => {
    console.log(campaignInName, "campaignInName");
    setFilters({ ...filters, campaignInName });
  };
  const setProsStatus = (prosStatusArr) => {
    // console.log(campaignInName, "campaignInName");
    // if (prosStatusArr?.length) {
    setFilters({ ...filters, prosStatusArr });
    // }
  };

  const setRecentlyUpdatedToSearch = (event) => {
    const recentlyUpdated = event.target.checked;
    setFilters({ ...filters, recentlyUpdated });
  };
  // const setExcludeDoNotContact = (event) => {
  //   const excludeDoNotContact = event.target.checked;
  //   setFilters({ ...filters, excludeDoNotContact });
  // };

  // const setIncludeInCampaignToSearch = (event) => {
  //   const includeInCampaign = event.target.checked;
  //   setFilters({ ...filters, includeInCampaign });
  // };

  const setIncludeContactedAlreadyToSearch = (event) => {
    const includeContactedAlready = event.target.checked;
    setFilters({ ...filters, includeContactedAlready });
  };

  const resetFilters = () => {
    setFilters({
      uploadName: "All",
      recentlyUpdated: false,
      // includeInCampaign: false,
      includeContactedAlready: false,
      campaignInName: "none",

      prosStatusArr: ["canbecontacted"],
    });

    setPage(1);
    dispatch(
      getFilteredLeads({
        uploadName: "All",
        recentlyUpdated: false,
        // includeInCampaign: false,
        includeContactedAlready: false,
        page: 1,
        pageChange: false,
        campaignInName: "none",
        prosStatusArr: ["canbecontacted"],
      })
    );
    setFiltersSubmited(0);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (newCampId) {
      // random number for campaign
      // window.location.href = `campaign/${btoa(`${newCampId}`)}`
      navigate(`/campaign/${btoa(`${newCampId + 12000}`)}`);
      dispatch(resetNewCmpId());
    }
  }, [newCampId]);
  const selectedLength = leadsIdSelected?.length || "0";
  const [confirmationStopIt, setConfirmationStopIt] = useState("");
  const [confirmationDelete, setConfirmationDelete] = useState("");
  const [openManageContacts, setOpenManageContacts] = useState(false);
  const manageSelected = () => {
    if (+selectedLength) {
      setOpenManageContacts(true);
      // message.info("Adding prospects");
      // dispatch(
      //   addProsToCampaign({
      //     leadsIdSelected,
      //     campId: selectedCampaignId,
      //   })
      // );
    } else {
      message.warning("Select contacts to edit");
    }
  };

  const [editContacts, setEditContacts] = useState(null);
  const editContactsAction = (action) => {
    //  keep as false since the IDS need to be updated if its deleted
    const search = { ...filters, search: searchContact, page, pageChange: false };
    switch (action) {
      case "campaign":
        message.info("Adding to campaign");
        dispatch(
          editContactsInAccountCampaign({
            campToAddId,
            action,
            leadsIdSelected,
            search,
          })
        );
        break;
      case "stopIt":
        message.info("Updating contacts");
        dispatch(
          editContactsInAccountStop({
            campToAddId,
            action,
            leadsIdSelected,
            search,
          })
        );
        break;
      case "proceed":
        message.info("Updating contacts");
        dispatch(
          editContactsInAccountAllow({
            campToAddId,
            action,
            leadsIdSelected,
            search,
          })
        );
        break;
      case "deleted":
        message.info("Deleting contacts in your account");
        dispatch(
          editContactsInAccountDelete({
            campToAddId,
            action,
            leadsIdSelected,
            search,
          })
        );
        break;
    }
    setEditContacts(null);
    setOpenManageContacts(false);
    resetSelPros();
  };

  const handleAddProspects = () => {
    if (+selectedLength) {
      message.info("Adding prospects");
      dispatch(
        addProsToCampaign({
          leadsIdSelected,
          campId: selectedCampaignId,
        })
      );
    } else {
      message.warning("Select prospects to add first");
    }
  };
  const [messageApi, contextHolder] = message.useMessage();

  const [prospectAmountExtracting, setProspectAmountExtracting] = useState(0);
  const [openExtractionFileModal, setOpenExtractionFileModal] = useState(false);
  const [openUpModal, setOpenUpModal] = useState(false);
  const [openUploadProspectsModal, setOpenUploadProspectsModal] = useState(false);
  const allIdsPresent = leadsIds?.every((id) => leadsIdSelected.includes(id));
  useEffect(() => {
    if (prospectAmountExtracting && loadingUploadPros) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeads prospectAmountExtracting={prospectAmountExtracting} />,
        className: "custom-message",
        duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
    }
  }, [prospectAmountExtracting, loadingUploadPros]);

  useEffect(() => {
    // console.log(leadsLeftOut, leadsUploaded, "leadsLeftOutleadsLeftOut");
    if (leadsLeftOut || leadsUploaded) {
      messageApi.destroy();
      messageApi.open({
        key: "updateMessage",
        content: <UploadingLeadsFulfill leadsLeftOut={leadsLeftOut} leadsUploaded={leadsUploaded} />,
        className: "custom-message",
        duration: 7,
        // duration: 0,
        style: {
          padding: "10px 16px",
        },
      });
      dispatch(resetUploadedInfo());
      dispatch(resetSelectedRows());
    }
    // }, []);
  }, [leadsLeftOut, leadsUploaded]);

  const { exportFiles } = useSelector(prospectDataSelector);
  const client = useSelector(userSelector);
  const showExtractionFileModal = () => {
    setOpenUpModal(false);
    setOpenExtractionFileModal(true);
  };
  const showUploadProspectsModal = () => {
    setOpenUpModal(false);
    setOpenUploadProspectsModal(true);
  };
  const closeExtractionFileModal = () => {
    // setOpenUpModal(true);
    setOpenExtractionFileModal(false);
  };
  const closeUploadProspectsModal = () => {
    // setOpenUpModal(true);
    setOpenUploadProspectsModal(false);
  };
  const createCampaignItem = [
    {
      icon: <SvgIcon type={"moveToCampaign"} width={28} height={28} />,
      title: "Add to campaign",
      subtitle: "If they are in a campaign will remove them from it and add them to the selected campaign.",
      onClick: () => {
        setOpenManageContacts(false);
        setEditContacts("campaign");
      },
      // disabled: true,
    },
    {
      icon: <SvgIcon type={"proceed"} width={28} height={28} />,
      title: "Mark as allow contact",
      subtitle:
        'By default is true. If you marked them as "stop contacting" this will allow them to continue. Won\'t resume prospects that unsubscribed themselves.',
      onClick: () => {
        setOpenManageContacts(false);
        setEditContacts("proceed");
      },
      // disabled: true,
    },
    {
      icon: <SvgIcon type={"stopIt"} width={28} height={28} />,
      title: "Mark as stop contacting",
      subtitle: "If they requested to not be contacted or are interested and you are talking with them now.",
      onClick: () => {
        setOpenManageContacts(false);
        setEditContacts("stopIt");
      },
      // disabled: true,
    },
    {
      icon: <SvgIcon type={"deletedSvg"} width={28} height={28} />,
      title: `Delete contact${+selectedLength > 1 ? "s" : ""}`,
      subtitle: `Delete the selected contact${+selectedLength > 1 ? "s" : ""} from Meetz.`,
      onClick: () => {
        setOpenManageContacts(false);
        setEditContacts("deleted");
      },
    },
  ]?.filter(Boolean);

  return (
    <>
      <Modal
        open={editContacts?.length}
        title={`Manage selected contacts - ${+selectedLength || 0} selected`}
        onCancel={() => {
          setConfirmationStopIt("");
          setConfirmationDelete("");
          setcampToAddId(undefined);
          setEditContacts(null);
          setOpenManageContacts(true);
        }}
      >
        <Flex vertical gap={8}>
          {editContacts === "campaign" ? (
            <>
              {" "}
              <Flex vertical gap={10}>
                <Typography.Paragraph>Search for campaign to add too</Typography.Paragraph>
                <Input.Search
                  className={"search-input"}
                  placeholder={"Search previous campaigns name..."}
                  allowClear
                  prefix={<SvgIcon type={"search"} />}
                  enterButton={false}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {/* <Form.Item name={"camptocopy"} rules={[{ required: true, message: "Select campaign to copy" }]} style={{ width: "350px" }}> */}
                <Radio.Group value={campToAddId} onChange={handleRadioBtnChange} style={{ padding: "10px" }}>
                  <Flex gap={10} vertical>
                    {filteredCampaigns?.length ? (
                      filteredCampaigns.map((item, index) => (
                        <Radio key={`${index}-${item.id}`} value={item.id}>
                          {item.campName}
                        </Radio>
                      ))
                    ) : (
                      <div>No campaign found</div>
                    )}
                  </Flex>
                </Radio.Group>
                {/* </Form.Item> */}
                <ConfirmButtons
                  leftButtonTitle={"Back"}
                  rightButtonTitle={"Add to campaign"}
                  handleSubmit={() => editContactsAction(editContacts)}
                  className={"skip-trial-wrapper"}
                  style={{ margin: "0 0 20px 0" }}
                />
                <div className="content-footnote">
                  If any selected contacts are now in a campaign they will be removed and then added to the selected campaign. All prospects will be added to
                  the first step of the campaign you select.
                </div>
              </Flex>
            </>
          ) : (
            ""
          )}
          {editContacts === "stopIt" ? (
            <>
              <Flex vertical gap={10}>
                <Typography.Paragraph>Mark prospects as stop contacting. Will stop prospects from receiving follow ups in the campaign.</Typography.Paragraph>
                <br /> To confirm type "Confirm"
                <Input placeholder={"Confirm"} onChange={(event) => setConfirmationStopIt(event.target.value)} />
                <br />
                <ConfirmButtons
                  leftButtonTitle={"Back"}
                  rightButtonTitle={"Mark as stop contacting"}
                  handleSubmit={() => editContactsAction(editContacts)}
                  className={"skip-trial-wrapper"}
                  style={{ margin: "0 0 20px 0" }}
                  disabled={confirmationStopIt?.trim()?.toLowerCase() !== "confirm"}
                />
                <div className="content-footnote">
                  Note: This happens automatically when a prospect responds, unsubscribes, or books a meeting with you with a Meetz link
                </div>
              </Flex>
            </>
          ) : (
            ""
          )}
          {editContacts === "deleted" ? (
            <>
              {" "}
              <Flex vertical gap={10}>
                <Typography.Paragraph>Mark prospects as stop contacting. Will stop prospects from receiving follow ups in the campaign.</Typography.Paragraph>
                <br /> To confirm type "Confirm"
                <Input placeholder={"Confirm"} onChange={(event) => setConfirmationDelete(event.target.value)} />
                <br />
                <ConfirmButtons
                  leftButtonTitle={"Back"}
                  rightButtonTitle={"Delete"}
                  handleSubmit={() => editContactsAction(editContacts)}
                  className={"skip-trial-wrapper"}
                  style={{ margin: "0 0 20px 0" }}
                  disabled={confirmationDelete?.trim()?.toLowerCase() !== "confirm"}
                />
                {/* <div className="content-footnote">
                  Note: This happens automatically when a prospect responds, unsubscribes, or books a meeting with you with a Meetz link
                </div> */}
              </Flex>
            </>
          ) : (
            ""
          )}
          {editContacts === "proceed" ? (
            <>
              {" "}
              <Flex vertical gap={10}>
                <Typography.Paragraph>Mark prospects as allow to be contacted. Will allow prospects to be in your campaign.</Typography.Paragraph>
                <br /> To confirm type "Confirm"
                <Input placeholder={"Confirm"} onChange={(event) => setConfirmationDelete(event.target.value)} />
                <br />
                <ConfirmButtons
                  leftButtonTitle={"Back"}
                  rightButtonTitle={"Allow"}
                  handleSubmit={() => editContactsAction(editContacts)}
                  className={"skip-trial-wrapper"}
                  style={{ margin: "0 0 20px 0" }}
                  disabled={confirmationDelete?.trim()?.toLowerCase() !== "confirm"}
                />
                {/* <div className="content-footnote">
                  Note: This happens automatically when a prospect responds, unsubscribes, or books a meeting with you with a Meetz link
                </div> */}
              </Flex>
            </>
          ) : (
            ""
          )}
        </Flex>
      </Modal>
      <Modal
        open={openManageContacts}
        title={`Manage selected contacts - ${+selectedLength || 0} selected`}
        onCancel={() => {
          setOpenManageContacts(false);
        }}
      >
        <Flex vertical gap={8}>
          {createCampaignItem.map((item, index) => (
            <Flex key={`${index}-${item.title}`} className={"modal-item"} align={"center"} gap={16} onClick={item.onClick}>
              {item.icon}
              <Flex gap={2} vertical>
                <Typography.Paragraph className={"item-title-modal-edit"}>{item.title}</Typography.Paragraph>
                {<Typography.Paragraph className={"item-subtitle-edit"}>{item.subtitle}</Typography.Paragraph>}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Modal>
      {(!!addProsToCamp || !!crm) && openUpModal && (
        <Modal
          open
          title={"Upload prospects to Meetz"}
          onCancel={() => {
            // console.log("closingsgnifb");
            setOpenUpModal(false);
          }}
        >
          <br />
          <Flex vertical align="flex-start" gap={10} style={{ marginBottom: "20px" }}>
            {/* <Text>Add prospects from your Meetz saved lists</Text>
            <Button
              onClick={() => exportFiles?.length && showExtractionFileModal()}
              className={"link"}
              style={{ marginTop: "-7px", pointerEvents: !exportFiles?.length ? "none" : "auto", color: !exportFiles?.length ? "orange" : "default" }}
            >
              {!exportFiles?.length
                ? //  clients with no files should be directed to prospect data page  to get prospects
                  'No Exported Prospects From Meetz - Go to the "Prospect Data" tab'
                : `Select saved file`}
            </Button> */}
            <Flex vertical>
              <Text>Upload prospects</Text>
              <small>Add prospects from a file you have</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"link"} style={{ marginTop: "-5px" }}>
              Upload prospects file
            </Button>
            {/* <Text style={{ margin: "20px 0" }}>or</Text>
            <Flex vertical>
              <Text>Upload prospects</Text>
              <small>Add prospects from a file you have</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"link"} style={{ marginTop: "-5px" }}>
              Upload prospects file
            </Button> */}
          </Flex>
        </Modal>
      )}
      {contextHolder}
      <Flex vertical className={"explore-wrapper step-two-table-container"} style={{ padding: addProsToCamp ? 0 : 24 }}>
        <div>
          {" "}
          <Flex justify="space-between" align="center">
            {" "}
            <Typography.Title level={3}>{crm ? `Contacts in your account` : `Select Prospects To Add To Campaign`}</Typography.Title>
            {(!!addProsToCamp || !!crm) && (
              <Flex justify="space-between" align="center">
                <Button onClick={showUploadProspectsModal} className={"link"} style={{ cursor: "pointer" }}>
                  Upload prospects
                </Button>
                <Button onClick={crm ? manageSelected : handleAddProspects} className={"primary"} style={{ cursor: "pointer", marginLeft: "15px" }}>
                  {crm ? "Manage selected" : `Add prospects to campaign`}
                </Button>
              </Flex>
            )}
          </Flex>
          {/* <TableHeader searchPlaceholder={"Search Prospect data..."} /> */}
          {/* {!!addProsToCamp && <hr style={{ color: "lightgrey !important", height: "2px", marginTop: "25px", marginBottom: "-5px" }} />} */}
          <br />
          <ul />
          <Input.Search
            className={"search-input"}
            placeholder={"Search for name, title, or company..."}
            allowClear
            // prefix={<SvgIcon type={"search"} />}
            onChange={handleChange}
            enterButton={false}
            style={{ marginBottom: "20px", maxWidth: 376 }}
            value={searchContact}
          />
          <TableHeader
            leftHeaderContent={
              <Flex
                justify="space-between"
                style={{
                  borderTop: !!addProsToCamp ? "1px solid #eaeaea" : "none",
                  width: "100%",
                  height: "100%",
                  padding: 0,

                  paddingTop: "20px",
                  margin: 0,
                }}
              >
                <Row style={{ gap: 16 }} wrap={false}>
                  <StatusTag value={"Total filtered prospects"} color={"default"} count={leadsTotalCnt ?? "-"} />
                  <StatusTag
                    value={"Filters Applied"}
                    color={"processing"}
                    count={filtersSubmited || "0"}
                    removeAction={resetFilters}
                    showRemActionIcon={!!+filtersSubmited}
                  />
                  <StatusTag
                    value={crm ? "Selected contacts" : "Prospects Selected To Add To Campaign"}
                    color={+selectedLength || crm ? "success" : "red"}
                    count={selectedLength?.toLocaleString()}
                    removeAction={resetSelPros}
                    showRemActionIcon={!!+selectedLength}
                  />
                </Row>
                <Flex justify="space-between" align="center">
                  <Button
                    className={"link"}
                    style={{ width: "100%" }}
                    onClick={() => (allIdsPresent ? dispatch(selectAllCurrent("rem")) : dispatch(selectAllCurrent("add")))}
                  >
                    {leadsTotalCnt && allIdsPresent ? "Deselect" : "Select"} All Filtered Prospects
                  </Button>
                  <Button
                    onClick={() => setOpenFilterDrawer(true)}
                    className={addProsToCamp ? "link" : "primary"}
                    style={{ marginLeft: addProsToCamp ? "25px" : "10px", cursor: "pointer" }}
                  >
                    <div>Filter</div>
                    <SvgIcon type={"whiteFilter"} style={{ width: "17px", color: "white", height: "17px" }} />
                  </Button>
                </Flex>
              </Flex>
            }
            marginBottom={5}
          />
          <Table
            quickSkip
            quickDropdownSkip
            loading={loadingLeads}
            columns={columns}
            dataSource={leads}
            currentPage={page}
            setCurrentPage={handlePageChanger}
            totalAmnt={leadsTotalCnt}
            totalPages={leadsTotalCnt ? Math.ceil(leadsTotalCnt / 10) : 1}
            rowSelection={{ ...rowSelection }}
            rowKey={"id"}
            locale={{
              emptyText: (
                <Empty
                  image={<Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />}
                  imageStyle={{
                    height: 180, // Ensure the image height is consistent with its actual height
                    marginTop: "30px",
                  }}
                  description={<span style={{ marginTop: "10px" }}>No Prospects Found</span>} // Add margin to separate text from the image
                />
              ),
            }}
            // divWidth={"13600px"}
            divHeight="fit-content"
            style={{ height: "fit-content" }}
          />
          {openFilterDrawer && (
            <ProspectsFilter
              onClose={() => setOpenFilterDrawer(false)}
              uplNameOptions={uploadNameAvail}
              uploadNameToSearch={filters?.uploadName}
              setUploadNameToSearch={setUploadNameToSearch}
              campaignInName={filters?.campaignInName}
              campaignNames={campaignNames}
              setCampaignInName={setCampaignInName}
              prosStatusArr={filters?.prosStatusArr}
              setProsStatus={setProsStatus}
              crm={!!crm}
              recentlyUpdatedToSearch={filters?.recentlyUpdated}
              setRecentlyUpdatedToSearch={setRecentlyUpdatedToSearch}
              // setExcludeDoNotContact={setExcludeDoNotContact}
              // excludeDoNotContact={filters?.excludeDoNotContact}
              // includeInCampaignToSearch={filters?.includeInCampaign}
              // setIncludeInCampaignToSearch={setIncludeInCampaignToSearch}
              includeContactedAlreadyToSearch={filters?.includeContactedAlready}
              setIncludeContactedAlreadyToSearch={setIncludeContactedAlreadyToSearch}
              submitFilters={submitFilters}
              addProsToCamp={addProsToCamp}
            />
          )}
        </div>
      </Flex>{" "}
      <ExtractionFileModal open={openExtractionFileModal} onCancel={closeExtractionFileModal} setProspectAmountExtracting={setProspectAmountExtracting} />
      <UploadProspectsModal onCancel={closeUploadProspectsModal} open={openUploadProspectsModal} setProspectAmountExtracting={setProspectAmountExtracting} />
    </>
  );
};
