/* eslint-disable react/jsx-fragments */
import { Flex, Typography } from "antd";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "src/shared/icons";
// import { CreateFromSavedModal, CreateFromScratchModal } from "../index";
// import "../../index.less";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredLeadsRst } from "src/store/outreach-campaigns/asyncRequests";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";

// type CreateCampaignMethodSelectProps = {
//   onCancel?: VoidFunction;
//   isModalContent?: boolean;
//   locked?: boolean;
// };

export const Analytics = ({ onCancel, isModalContent = true, locked = false }) => {
  const { aiSettings } = useSelector(outreachCampaignSelector);
  const [openFromSavedModal, setOpenFromSavedModal] = useState(false);
  const [openFromScratchModal, setOpenFromScratchModal] = useState(false);
  const navigate = useNavigate();

  const showFromSavedModal = () => {
    setOpenFromSavedModal(true);
  };
  const showFromScratchModal = () => {
    setOpenFromScratchModal(true);
  };

  const closeModal = () => {
    setOpenFromSavedModal(false);
    setOpenFromScratchModal(false);
  };
  const dispatch = useDispatch();

  const createCampaignItem = [
    // {
    //   icon: <SvgIcon type={"aiGeneratedSequence"} width={!isModalContent ? 28 : 40} height={!isModalContent ? 28 : 40} />,
    //   title: "AI Generated Sequence",
    //   subtitle: isModalContent && "Create an entire outreach sequence drafted by AI.",
    //   onClick: () => {
    //     dispatch(
    //       getFilteredLeadsRst({
    //         uploadName: "All",
    //         recentlyUpdated: false,
    //         includeInCampaign: false,
    //         includeContactedAlready: false,
    //         page: 1,
    //         pageChange: false,
    //         selectedCampaignId: null,
    //       })
    //     );
    //     navigate("/campaign/ai-generated");
    //     onCancel && onCancel();
    //   },
    // },
    {
      icon: <SvgIcon type={"emailAnalytics"} width={28} height={28} />,
      title: "Email analytics",
      subtitle: "See outgoing and incoming email analytics",
      onClick: () => {
        navigate("/email-analytics");
        // onCancel && onCancel();
      },
      // disabled: true,
    },
    {
      // TODOF good one to add quickly and wil lbe salesy good
      icon: <SvgIcon type={"dialerAnalytics"} width={28} height={28} />,
      title: "Dialer analytics",
      subtitle: "See outbound and incoming call details.",
      onClick: () => {
        navigate("/phone-calls");
        // onCancel && onCancel();
      },
    },
  ]?.filter(Boolean);
  //  later add a (continue youre recent campaign that you built. check lenght of the draft sequence of ai vs scratch)

  return (
    <>
      <Flex vertical gap={8}>
        <br />
        {createCampaignItem.map((item, index) => (
          <Flex
            key={`${index}-${item.title}`}
            className={isModalContent ? "modal-item" : "actions-item"}
            align={"center"}
            style={{ pointerEvents: locked ? "none" : "auto" }}
            gap={16}
            onClick={item.onClick}
          >
            {item.icon}
            <Flex gap={2} vertical>
              <Typography.Paragraph className={isModalContent ? "item-title-modal" : "item-title"}>{item.title}</Typography.Paragraph>
              {isModalContent && <Typography.Paragraph className={"item-subtitle"}>{item.subtitle}</Typography.Paragraph>}
            </Flex>
          </Flex>
        ))}
      </Flex>
      {/* <CreateFromSavedModal open={openFromSavedModal} onCancel={closeModal} /> */}
      {/* <CreateFromScratchModal open={openFromScratchModal} onCancel={closeModal} /> */}
    </>
  );
};
