import { Flex, Typography } from "antd";
import React, { FC } from "react";
import { aiChart } from "src/shared/images";
import "./index.less";
import { Loader } from "src/components/ui";

export const LockedAiInitiatedEmail: FC = ({ campCount }) => {
  return (
    <Flex className={"locked-ai-wrapper"} gap={54} align={"center"}>
      <Flex vertical>
        {/* <Typography.Paragraph className={"locked-title"}>Dialer analytics loading</Typography.Paragraph> */}
        {/* <Typography.Paragraph className={"locked-subtitle"}>Dialer analytics loading</Typography.Paragraph> */}
        {campCount !== 0 ? (
          <>
            {" "}
            <Typography.Paragraph className={"locked-title"}>Loading </Typography.Paragraph>
            <Typography.Paragraph className={"locked-subtitle"} style={{ filter: "none !important" }}>
              Getting dialer analytics
            </Typography.Paragraph>
            <br />
            <Loader />
          </>
        ) : (
          <>
            {" "}
            <Typography.Paragraph className={"locked-title"}>No campaigns created yet </Typography.Paragraph>
            <Typography.Paragraph className={"locked-subtitle"} style={{ filter: "none !important" }}>
              Create a campaign with dialer steps to start seeing dialer analytics here.
            </Typography.Paragraph>
          </>
        )}
      </Flex>
      <img src={aiChart} alt={"overview chart"} width={218} />
    </Flex>
  );
};
