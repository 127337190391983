import { Flex, Typography } from "antd";
import React, { FC } from "react";
import { womanWithPhone } from "src/shared/images";

export const AiInitiatedEmailEmptyData: FC = () => {
  return (
    <Flex vertical align={"center"} gap={18} style={{ padding: "76px 0 48px" }}>
      <img src={womanWithPhone} alt={"woman with phone"} width={270} />
      <Typography.Paragraph className={"dashboard-empty-text"} style={{ maxWidth: 300 }}>
        No dialer analytics for this time range. To start dialing go into your campaign with a dialer step and click on "Activate dialer"
      </Typography.Paragraph>
    </Flex>
  );
};
