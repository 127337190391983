import { Form, Input } from "antd";
import { FC, useContext } from "react";
import { DealsContext } from "../../../../app/context/DealsContext";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

interface EditColumnModalProps {
  onClose: () => void;
  currentGroup;
  editColumnTitle;
}

export const EditColumnModal: FC<EditColumnModalProps> = ({ onClose, currentGroup, editColumnTitle }) => {
  // const { dealsGroups, selectedGroupId, editColumn } = useContext(DealsContext)
  const [form] = Form.useForm();
  // const currentGroup = dealsGroups?.find((group) => group.id === selectedGroupId);

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then((values) => {
        // const updatedGroup: any = { ...currentGroup, title: values.name };
        editColumnTitle(values.title);
        onClose();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  return (
    <Modal open={true} title={"Edit Column"} onCancel={onClose} rootClassName={"edit-column-modal"}>
      <Form form={form} layout={"vertical"} requiredMark={false} className={"edit-column-form"}>
        <Form.Item
          name={"title"}
          label={"Name"}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={currentGroup?.title}
        >
          <Input placeholder={"Enter Text"} />
        </Form.Item>
      </Form>
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} className={"confirm-buttons"} handleSubmit={handleSubmitBtnClick} onCancel={onClose} />
    </Modal>
  );
};
