/* eslint-disable react/jsx-fragments */
import { Button, Dropdown, Flex, Input, Menu, MenuProps, Row, Select, Space, TimePicker, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { CustomSelect, StatusTag } from "src/components/ui";
import { Drawer } from "src/components/ui/Drawer";
import { SvgIcon } from "src/shared/icons";
// import Flags from 'react-flags-select';
import { AddNumberModal } from "../AddNumberModal";
import { CheckAddressModal } from "../AddNumberModal/CheckAddressModal";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { outreachPhonesSelector, remOptimisticPhoneNum } from "src/store/outreach-phones";
import { remNumFromTwillio, updatePhoneSettings } from "src/store/outreach-phones/asyncRequests";
import { AppDispatch } from "src/store";
import PhoneInput from "antd-phone-input";
import dayjs from "dayjs";

type AddNumberDrawerProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const AddNumberDrawer: FC<AddNumberDrawerProps> = ({ open, onCancel }) => {
  const [isAddNumberModalOpen, setIsAddNumberModalOpen] = useState(false);
  const [openCheckAddressModal, setOpenCheckAddress] = useState(false);
  const [rangeOfDayjs, setRangeOfDayjs] = useState([dayjs().hour(9).minute(0).second(0), dayjs().hour(4).minute(0).second(0)]);
  const handleRangeChange = (value) => {
    setRangeOfDayjs(value);
  };
  const [recordCall, setRecord] = useState(false);
  const handleRecord = (selected) => {
    // console.log(selected, "selected");
    setRecord(selected);
  };
  const { phone_numbers, phoneNumbersLeft, record_calls, number_forward_to, rangeOfPros } = useSelector(outreachPhonesSelector);

  useEffect(() => {
    if (record_calls) {
      setRecord(!!record_calls);
    }
  }, [rangeOfPros]);
  useEffect(() => {
    if (rangeOfPros?.length) {
      setRangeOfDayjs(rangeOfPros.map((hour) => dayjs().hour(hour).minute(0).second(0)));
    }
    // console.log(rangeOfPros,rangeOfDayjs,'asdfasfasfd');
  }, [rangeOfPros]);

  const dispatch = useDispatch<AppDispatch>();
  const removeNumber = (num) => {
    dispatch(remOptimisticPhoneNum({ phoneId: num?.id }));

    dispatch(remNumFromTwillio({ id: num?.id }));
  };

  const getMenuItems: MenuProps["items"] = (record) => [
    // {
    //   key: "0",
    //   label: "Check Address",
    //   onClick: () => setOpenCheckAddress(true),
    // },
    // {
    //   type: "divider",
    // },
    {
      key: "0",
      label: (
        <>
          {" "}
          <Tooltip
            placement={"bottom"}
            title={"Warning: By erasing your number anyone that texts or calls you back on this number won't be able to reach you."}
          >
            Remove (Not recommended){" "}
          </Tooltip>
        </>
      ),
      danger: true,

      onClick: () => removeNumber(record),
    },
  ];

  const handleAddNumberModalClose = () => {
    setIsAddNumberModalOpen(false);
  };

  const handleAddNumberModalOpen = () => {
    setIsAddNumberModalOpen(true);
  };

  const closeModal = () => {
    onCancel();
  };

  const closeCheckAddressModal = () => {
    setOpenCheckAddress(false);
  };

  const [frdNum, setFrdNum] = useState<any>("");
  // const onSubmitFrwdNum = () => {
  //   dispatch(changeForwardNumber({ frdNum }));
  // };

  const handlePhoneNumChange = (number) => {
    setFrdNum(number);
  };

  useEffect(() => {
    if (number_forward_to) {
      setFrdNum(number_forward_to);
    }
    // console.log(number_forward_to, "number_forward_to");
  }, [number_forward_to]);

  // const countries = {
  //   US: 'United States',
  //   CA: 'Canada',
  //   MX: 'Mexico',
  //   FR: 'France',
  //   DE: 'Germany',
  //   // Add more countries as needed
  // };
  // console.log(phone_numbers, "phone_numbers");
  // const [countryCode, setCountryCode] = useState('');
  // const [countryName, setCountryName] = useState('');

  // const handleSearch = (value) => {
  //   const code = value.toUpperCase();
  //   if (countries[code]) {
  //     setCountryCode(code);
  //     setCountryName(countries[code]);
  //   } else {
  //     setCountryCode('');
  //     setCountryName('Country not found');
  //   }
  // };\

  const handleSubmit = () => {
    let hoursArray = rangeOfDayjs?.map((date) => date?.hour());
    // console.log(hoursArray, "hoursArrayhoursArray");
    // Ensure the first number is not smaller than the second
    if (hoursArray?.length === 2 && hoursArray[0] !== hoursArray[1]) {
      const hourStart = hoursArray[0];
      const hourEnd = hoursArray[1];
      if (hourEnd < hourStart) {
        hoursArray = [hourEnd, hourStart];
      }
    } else {
      hoursArray = [9, 16];
    }
    // this is an oantd phone obj
    let finalNum = frdNum;
    if (finalNum?.areaCode) {
      finalNum = `+${frdNum?.countryCode || 1}${frdNum?.areaCode || ""}${frdNum?.phoneNumber || ""}`;
    }
    // console.log(finalNum, hoursArray, "hoursArray");
    message.info("Updating phone settings");
    finalNum = finalNum?.length > 7 ? finalNum : null; // to avoid emergency calls numbers and bad nums
    dispatch(updatePhoneSettings({ finalNum, hoursArray, recordCall }));
    onCancel();
  };
  const items: TabsProps["items"] = [
    //  emailCampDrawerOpen && {
    //   key: "1",
    //   label: "Campaign",
    //   children: <CampaignTabModal  />,
    //

    // }, causes tabs to shake, antd bug. not good
    {
      key: "1",
      label: "Dialer Settings",
      children: <> </>,
    },
    // {
    //   key: "2",
    //   label: "Manage Balance",
    //   children: (
    //     <>
    //       <Typography.Paragraph className={"balance-title"}>Dialer Balance</Typography.Paragraph>
    //       <Typography.Paragraph className={"balance-amount"}>$100.00</Typography.Paragraph>
    //       <Button type="link" onClick={() => message.info("Add balance functionality not implemented yet")}>
    //         Add Balance
    //       </Button>
    //     </>
    //   ),
    // },
  ]?.filter(Boolean);

  return (
    <Drawer
      title={"Setup Dialer"}
      customClassName={"add-number-drawer"}
      open={open}
      onCancel={closeModal}
      // tabItems={items}
      leftButtonTitle={"Reset all"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={handleSubmit}
    >
      {" "}
      <Typography.Paragraph className={"add-number-notice"}>Get a phone number to start calling.</Typography.Paragraph>
      <Typography.Paragraph className={"default-numbers-title"}>Default Numbers</Typography.Paragraph>
      <div className={"phones-list-wrapper"}>
        {phone_numbers.length && phone_numbers ? (
          // is active shold show. anyways all should be active its only inactive before acount is about to be deleted and if they log back in it should be active.
          phone_numbers
            ?.filter((num) => num.is_active)
            .map((number) => {
              return (
                <Row key={number.id} className={"default-numbers-row"} align={"middle"} justify={"space-between"}>
                  <Typography.Paragraph className={"default-numbers-number"}>
                    {/* <Flags
                countries={[countryCode]}
                customLabels={{ [countryCode]: countryName }}
                showSelectedLabel={true}
                selectedSize={50}
              /> */}
                    {number.phone_number}
                  </Typography.Paragraph>
                  <Row align={"middle"} style={{ gap: 4 }}>
                    <StatusTag value={number.is_active ? "Connected" : <u>Reconnect</u>} color={number.is_active ? "success" : "error"} />
                    <Dropdown
                      menu={{ items: getMenuItems(number) }}
                      //  overlay={<Menu items={getMenuItems(number)} />}
                      trigger={["click"]}
                    >
                      <SvgIcon type={"action"} style={{ cursor: "pointer" }} />
                    </Dropdown>
                  </Row>
                </Row>
              );
            })
        ) : (
          <Typography.Paragraph className={"add-number-notice"}>You don't have a Meetz number yet. Get your number below.</Typography.Paragraph>
        )}
      </div>
      <Button className={"link plus-btn"} icon={<SvgIcon type={"plus"} />} onClick={handleAddNumberModalOpen}>
        Add Phone Number
      </Button>
      <Typography.Paragraph className={"default-numbers-title"}>
        Add Your Own Personal Phone Number To Forward Incoming Calls Going To The Above Numbers
      </Typography.Paragraph>
      <Space.Compact style={{ width: "100%" }}>
        <PhoneInput
          className={"phone-flags-select"}
          disabled={false}
          enableSearch
          style={{ width: "350px" }}
          value={number_forward_to}
          onChange={handlePhoneNumChange}
        />
        {/* <Select defaultValue={"+1"} options={options} style={{ width: "30%" }} />
        <Input defaultValue={"647-234-1245"} /> */}
      </Space.Compact>
      <br />
      <br />
      <Typography.Paragraph className={"default-numbers-title"}>
        Times can call prospects in their timezone{" "}
        <Tooltip
          placement={"bottomRight"}
          title={
            <>
              When you are in the campaigns and will click on "Start Power Dialer" we will automatically make sure the time in your prospects timezone is
              between the below range, otherwise we'll skip over that prospect to not call them at midnight in their time by accident.
              <br />
              <br />
              This is to ensure you don't bother prospects at bad times and to make the outreach easier. <br />
              <br />
              <b> Example:</b> You start the power dialer at 10 AM your time, unknowingly it's 2 AM for one of the prospects in your sequence but luckily the
              set time range is set to be from 9 AM to 5 PM their time. In this case Meetz will simply skip over them and call the rest to try and get a
              prospect to answer your call.{" "}
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
        </Tooltip>
      </Typography.Paragraph>
      <Space.Compact style={{ width: "100%" }}>
        <TimePicker.RangePicker
          needConfirm={false}
          allowClear={false}
          minuteStep={15}
          className={"time-picker"}
          format={"h A"}
          // format={"h:mm A"}
          value={rangeOfDayjs}
          placeholder={["No min time", "No max time"]}
          onChange={handleRangeChange}
        />
      </Space.Compact>{" "}
      <br />
      <br />
      <Typography.Paragraph className={"default-numbers-title"}>
        Record calls{" "}
        <Tooltip
          placement={"top"}
          title={
            <>
              Record calls that you make so that you can review them and recall the conversations. <br />
              Be sure to check local regulations regarding call recording in Meetz.
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 11, cursor: "pointer" }} />
        </Tooltip>
      </Typography.Paragraph>
      {/* </Text> */}
      <CustomSelect
        mode="single"
        style={{ width: "150px" }}
        placeholder="Select"
        onChange={handleRecord}
        options={[
          { value: true, label: "Record" },
          { value: false, label: "Don't record" },
        ]}
        // tagRender={tagRender}
        value={recordCall}
        maxTagCount={0}
        isSearchSelect={false}
      />
      {isAddNumberModalOpen && <AddNumberModal open={isAddNumberModalOpen} onCancel={handleAddNumberModalClose} />}
      <CheckAddressModal open={openCheckAddressModal} onCancel={closeCheckAddressModal} />
    </Drawer>
  );
};
