import React, { FC, useState } from "react";
import { Button, Flex, Form, Input, List, message, Space, Tooltip, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { ConfirmButtons, Loader, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { generateAiHooks } from "src/store/outreach-campaigns/asyncRequests";

interface HookModalProps {
  open: boolean;
  editId?: number;
  closeModal: () => void;
}

export const HookModal: FC<HookModalProps> = ({ open, reopenModal, editId, closeModal, hooks, setHooks }) => {
  const [openHookModal, setOpenHookModal] = useState(false);
  // const [hooks, setHooks] = useState<string[]>(Array(10).fill(""));
  const [form] = Form.useForm();

  const handleHookChange = (value: string, index: number) => {
    const newHooks = hooks.map((hook, i) => (i === index ? value : hook));
    setHooks(newHooks);
  };
  console.log(hooks, "hookshooks");
  const { generatingHooksNow, aiSettings, aiGenProps } = useSelector(outreachCampaignSelector);
  console.log(aiSettings, "aiSettingsaiGenProps", aiGenProps);
  const dispatch = useDispatch();

  const genAiHooks = () => {
    reopenModal();
    setOpenHookModal(false);
    if (!generatingHooksNow) {
      message.info("Generating hooks");
      dispatch(generateAiHooks({ valueProposition: aiGenProps?.valueProposition }));
    } else {
      message.info("Still creating your hooks");
    }
  };

  const handleFormSubmit = () => {
    closeModal();
    form
      .validateFields()
      .then((values) => {
        // message.info(`${editId ? "Updating the" : "Saving your"} hooks...`, 20);
        message.success("Saved hooks");
        // dispatch(valuePropUpd({ action: editId ? "edit" : "save", title: values?.name, hooks, indexToEdit: editId }));
        onCancel();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
        return;
      });
  };
  console.log();

  return (
    <>
      <Modal
        width={500}
        open={openHookModal}
        title={"Generate hooks with AI?"}
        onCancel={() => {
          reopenModal();

          setOpenHookModal(false);
        }}
        style={{ zIndex: 10000 }}
      >
        <Typography.Paragraph>
          <b>Notice:</b> Generating the hooks with Ai will replace all your current hooks.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Meetz Ai will use your value proposition to create the hooks and give examples of what a good hook looks like. It's best to check the hooks generated
          for accuracy and fix numbers that are needed to be fixed. <br />
          <br />
          <i>For example, if the AI makes a hook "We reduce customer support by an average of 30%" you should correct it to the right percentage.</i>
        </Typography.Paragraph>{" "}
        <br />
        <ConfirmButtons rightButtonTitle={"Generate with AI"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={genAiHooks} />
      </Modal>
      <Modal width={600} open={open} title={"Edit Saved Hooks"} onCancel={closeModal} style={{ zIndex: 10 }}>
        {/* <h2>Hooks Configuration</h2> */}
        <div>
          Customize your hooks to make your emails more effective and engaging.{" "}
          <Tooltip
            placement={"bottom"}
            title={
              <>
                <b>Best practices for hooks:</b>
                <br />
                <br />
                <b>1.</b> <u>Make it attention-grabbing:</u> Use real results to peak curiosity and increase responses. <b>The more hooks the better!</b>
                <br />
                <br />
                <b>2.</b> <u>Keep it short:</u> Short hooks are more likely to be read and remembered.
                <br />
                <br />
                <b>3.</b> <u>Increase engagement:</u> Effective hooks can help increase email responses and booked meetings.
                <br />
                <br /> Example Hook: "Boost your social media engagement by 20%"
              </>
            }
          >
            <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
          </Tooltip>
        </div>
        <Flex align="center" style={{ marginTop: "10px" }}>
          <Button
            style={{ marginTop: "2px" }}
            className="link"
            onClick={() => {
              if (!generatingHooksNow) {
                closeModal();
                setOpenHookModal(true);
              } else {
                message.info("Still creating your hooks");
              }
            }}
          >
            Generate hooks with AI
          </Button>
          {!!generatingHooksNow && (
            <Flex style={{ marginLeft: "20px" }} justify="center" align="center">
              <div>Creating hooks now</div>
              <Loader size="small" style={{ marginLeft: "30px" }} />
            </Flex>
          )}
        </Flex>
        <br />
        <br />
        {hooks.map((hook, index) => (
          <Input
            key={index}
            onChange={(event) => handleHookChange(event.target.value, index)}
            style={{ marginBottom: "8px" }}
            placeholder={
              [
                "We increase customer leads by at least 3X",
                "Our SEO increases organic sales by 23%",
                "Our accountants save 5-15% of your yearly taxes",
                "Boost your social media engagement by 50%",
                "Cut your software development time in half",
                "Improve your email open rates by 30%",
                "Reduce customer churn by 20%",
                "Double your website traffic in 3 months",
                "Enhance your brand visibility with our strategies",
                "Maximize your ROI with our marketing solutions",
              ][index % 10]
            }
            value={hook}
          />
        ))}
        <br />
        <br />
        <ConfirmButtons rightButtonTitle={"Save"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={handleFormSubmit} />
      </Modal>
    </>
  );
};
