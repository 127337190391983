import { Flex, Typography } from "antd";
import { Group } from "./ui/Group";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { DealsContext } from "../../app/context/DealsContext";
import { generateRandomDigits } from "../../../helpers";
import "./index.less";
import { outreachCampaignSelector, updateDealsState } from "src/store/outreach-campaigns";
import { virtualLady } from "src/shared/images";
import { useDispatch, useSelector } from "react-redux";
import { updateDealsBackend } from "src/store/outreach-campaigns/asyncRequests";
import { DndProvider, useDragLayer } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface DealsProps {
  isTourStep?: boolean;
  dealsGroup;
  data;
  setData;
}

export const Deals: FC<DealsProps> = ({ isTourStep, dealsGroup, data, setData }) => {
  // const { dealsGroups } = useContext(DealsContext);
  const [tourStepData, setTourStepData] = useState(dealsGroup);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);

  const dispatch = useDispatch();

  const debounceSave = useMemo(() => {
    let timeoutId;
    return (updDeals) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        dispatch(updateDealsState({ updDeals }));
        dispatch(updateDealsBackend({ updDeals }));
      }, 3000);
    };
  }, [dispatch]);

  const [stateAfter3Seconds, setStateAfter3Seconds] = useState(false);

  useEffect(() => {
    if (loadedOnce && stateAfter3Seconds) {
      debounceSave(data);
    }
  }, [data]);

  // useEffect(() => {
  //   const timer = ;

  //   // return () => clearTimeout(timer);
  // }, []);

  const updateData = (dealGroupThatsUpd, isSave) => {
    setLoadedOnce(true);
    const updDeals = data.map((group) => (group.id === dealGroupThatsUpd.id ? dealGroupThatsUpd : group));
    setData(updDeals);
    // console.log(updDeals, "updDeals");
    // if (isSave) {
    //   dispatch(updateDealsState({ updDeals }));
    //   // save everthing in be
    //   dispatch(updateDealsBackend({ updDeals }));
    // }
  };

  const updateDataColumn = (updDeals) => {
    setData(updDeals);
  };

  const CustomDragLayer: React.FC = () => {
    const { isDragging } = useDragLayer((monitor) => ({
      isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
      return null;
    }

    return (
      <div
        style={{
          position: "fixed",
          pointerEvents: "none",
          zIndex: 1000,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  useEffect(() => {
    if (!loadedOnce && dealsGroup?.length) {
      setData(dealsGroup);
      setLoadedOnce(true);
      setTimeout(() => {
        setStateAfter3Seconds(true);
      }, 3000);
    }
  }, [dealsGroup]);

  const [idOfDragging, setIdOfDragging] = useState("");
  const [idOfGroupDragging, setGroupIdOfDragging] = useState("");

  // useEffect(() => {
  //   console.log(idOfDragging, "idOfDragging");
  // }, [idOfDragging]);
  // console.log(data, "dealsGroup22");
  return (
    // <div>
    //   <div>d</div>
    <Flex gap={24} className={"deal-groups-wrapper"}>
      {isTourStep ? (
        memoizedTourStepData?.map((group) => <Group group={group} key={generateRandomDigits(10)} />)
      ) : data?.length ? (
        data?.map((group, index) => (
          // <DndProvider backend={HTML5Backend}>
          //   <CustomDragLayer />
          <Group
            updateData={updateData}
            group={group}
            // updateDataColumn={updateDataColumn}
            key={group?.id}
            dealsGroup={data}
            fixDeals={updateDataColumn}
            idOfDragging={idOfDragging}
            setIdOfDragging={setIdOfDragging}
            groupIndex={group?.order - 1}
            idOfGroupDragging={idOfGroupDragging}
            setGroupIdOfDragging={setGroupIdOfDragging}
          />
          // </DndProvider>
        ))
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "-70px",
            // marginLeft: "auto",
            // marginRight: "auto",
            // margin: "150px 0",
            // background: "#fff",
            // borderRadius: "8px",
            // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            // padding: "20px",
          }}
        >
          <Flex
            vertical
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              margin: "150px 0",
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "50px",
            }}
          >
            <img src={virtualLady} alt={"lady with VR glasses"} width={200} style={{ marginLeft: "auto", marginRight: "auto" }} />
            <Typography.Paragraph style={{ marginTop: 24, color: "#5D6279", textAlign: "center" }}>
              Click "Add column" to add your deals dashboard
            </Typography.Paragraph>
          </Flex>
        </div>
      )}
    </Flex>
    // </div>
  );
};
