import { Dropdown, MenuProps } from "antd";
import React from "react";
import { SvgIcon } from "src/shared/icons";
import "./index.less";

interface DotsMenuDropdownProps {
  menuItems: MenuProps["items"];
  // menuStyle?: any
  onDropdownClick?: () => any;
}

export const DotsMenuDropdown: React.FC<DotsMenuDropdownProps> = ({ menuItems, onDropdownClick, ...props }) => {
  const items: MenuProps["items"] = menuItems;

  return (
    <Dropdown menu={{ items }} trigger={["click"]} rootClassName={"dots-menu-dropdown"} onOpenChange={onDropdownClick} {...props}>
      <SvgIcon type={"action"} style={{ cursor: "pointer" }} />
    </Dropdown>
  );
};
