import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import { SvgIcon } from "src/shared/icons";
import Fuse from "fuse.js";
import moment from "moment";
import "./index.less";

interface Option {
  value: string | number;
  label: string | React.ReactNode;
}

interface CustomSelectProps extends SelectProps<string> {
  customClassName?: string;
  topSearch?: boolean;
  isSearchSelect?: boolean;
  useOnSelectChange?: boolean;
  forceShowAllOptionsAsOptionsArePrefiltered?: boolean;
  options: Option[];
  forceDontHighlight?: string[];
  forceDontHighlightBlocks?: [string, string];
  defaultHandleSearch?: any;
  onSearchInComponentToo?: any;
  openMan?: any;
  setOpenMan?: any;
}

const { Option: AntOption } = Select;

const highlightMatch = (text: string, query: string): string => {
  // Store placeholder positions
  const placeholders: { [key: string]: { original: string; start: number; end: number; startOrig; endOrig } } = {};
  let placeholderIndex = 0;
  // console.log(text, "sanitizedText");
  // Replace placeholders while tracking original positions
  let totalReplace = 0;
  let sanitizedText = text.replace(/{{ignore_start}}([\s\S]*?){{ignore_end}}/g, (match, content, offset) => {
    const placeholderKey = `__PLACEHOLDER_${placeholderIndex}__`;
    placeholders[placeholderKey] = {
      original: match,
      start: offset - totalReplace,
      end: offset - totalReplace + placeholderKey.length,
      startOrig: offset,
      endOrig: offset + match.length,
    };
    totalReplace += match.length - placeholderKey.length;
    placeholderIndex++;
    return placeholderKey; // Replace with a unique placeholder
  });

  //   placeholderIndex++;
  //   return placeholderKey; // Replace with a unique placeholder
  // });
  // console.log(sanitizedText, "sanitizedText123123", placeholders);
  const fuse = new Fuse([{ text: sanitizedText }], { includeMatches: true, threshold: 0.8, keys: ["text"] });
  const result = fuse.search(query.replace(/ /g, "_"));
  // console.log(result, "placeholders2", query, "sanitizedText", sanitizedText);
  if (result.length === 0 || !result[0]?.matches?.length) return text?.replace(/{{ignore_start}}|{{ignore_end}}/g, "");

  let matches = result[0].matches[0].indices;
  let highlightedText = "";
  let lastIndex = 0;
  // console.log(matches, "placeholders", placeholderIndex);
  matches.forEach(([start, end]) => {
    // Check if the match overlaps with a placeholder
    let isInsidePlaceholder = false;
    let adjustedEnd = end;

    Object.values(placeholders).forEach(({ start: pStart, end: pEnd }) => {
      if (start >= pStart && end <= pEnd) {
        // Match fully inside placeholder, ignore it
        // console.log(start, pStart, end, pEnd, "abcdefg1");
        isInsidePlaceholder = true;
      } else if (start < pStart && end >= pStart && end <= pEnd) {
        // Match starts outside but goes into a placeholder, cut at placeholder start
        adjustedEnd = pStart - 1;
      } else if (start >= pStart && start <= pEnd && end > pEnd) {
        // console.log(start, pStart, end, pEnd, "abcdefg2");
        // Match starts inside but exits, ignore until after placeholder
        isInsidePlaceholder = true;
      }
      // else {
      //   // console.log(start, pStart, end, pEnd, "abcdefg3");
      // }
    });
    // console.log(isInsidePlaceholder, "isInsidePlaceholder");
    if (isInsidePlaceholder) return; // Skip this match

    highlightedText += sanitizedText.slice(lastIndex, start);
    highlightedText += `<span style="background-color: #e6f7ff !important; color: #1890ff !important">${sanitizedText.slice(start, adjustedEnd + 1)}</span>`;
    lastIndex = adjustedEnd + 1;
  });

  highlightedText += sanitizedText.slice(lastIndex);

  // Restore placeholders back to their original content
  Object.keys(placeholders).forEach((placeholder) => {
    highlightedText = highlightedText.replace(placeholder, placeholders[placeholder].original);
  });

  // Remove {{Start}} and {{End}} from the final output
  highlightedText = highlightedText.replace(/{{ignore_start}}|{{ignore_end}}/g, "");

  return highlightedText;
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  isSearchSelect = true,
  useOnSelectChange = false,
  topSearch = false,
  customClassName,
  value,
  onChange,
  forceShowAllOptionsAsOptionsArePrefiltered = false,
  forceDontHighlight,
  forceDontHighlightBlocks,
  onSearchInComponentToo,
  openMan,
  setOpenMan,
  ...props
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (value: string, updateOptionsOnly) => {
    setSearchQuery(value);
    if (isSearchSelect) {
      const fuse = new Fuse(options, { keys: ["label"], threshold: forceShowAllOptionsAsOptionsArePrefiltered ? 1 : 0.4 });
      // setFilteredOptions(value ? (fuse.search(value).map((result) => result.item) || forceShowAllOptionsAsOptionsArePrefiltered ? options : []) : options);
      const results = fuse.search(value).map((r) => r.item);

      // If forceShowAllOptionsAsOptionsArePrefiltered is true, maybe just show the original list:
      if (forceShowAllOptionsAsOptionsArePrefiltered) {
        setFilteredOptions(options);
      } else {
        // Otherwise, show the real filtered results:
        setFilteredOptions(results);
      }
      // console.log(forceShowAllOptionsAsOptionsArePrefiltered, "handleSearch2", options, value);
      if (onSearchInComponentToo && !updateOptionsOnly) {
        onSearchInComponentToo(value);
      }
    }
  };

  useEffect(() => {
    if (forceShowAllOptionsAsOptionsArePrefiltered) {
      handleSearch(searchQuery, true);
    }
    // else {
    //   handleSearch(searchQuery, true);
    // }
  }, [options]);

  const handleChange = (value: string) => {
    setSearchQuery("");
    setFilteredOptions(options);
    if (onChange) onChange(value);
  };

  return (
    <Select
      // onDropdownVisibleChange={setOpenMan}
      open={setOpenMan ? openMan : undefined}
      showSearch={isSearchSelect}
      onSearch={isSearchSelect ? handleSearch : undefined}
      filterOption={false} // Disable default filtering to use Fuse.js
      suffixIcon={<SvgIcon type={topSearch ? "search" : "selectArrow"} />}
      className={`custom-select-root custom-select search-select ${customClassName}`}
      value={value}
      onChange={useOnSelectChange ? undefined : handleChange}
      onSelect={useOnSelectChange ? handleChange : undefined}
      aria-label="_noacinput" // randome letters in label stops autocomplete
      {...props}
    >
      {(searchQuery && isSearchSelect ? filteredOptions : options).map((option, i) => {
        const value = typeof option === "object" && option !== null && "value" in option ? option.value : option;
        const label = typeof option === "object" && option !== null && "label" in option ? option.label : option;

        return (
          <AntOption key={i} value={value} style={{ padding: "12px 24px", borderRadius: "unset", fontWeight: 400 }} disabled={option.disabled}>
            {typeof label === "string" ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: highlightMatch(i === 0 || i === 4 ? `${label}` : label, searchQuery) as string,
                }}
              />
            ) : (
              label
            )}
          </AntOption>
        );
      })}
    </Select>
  );
};
