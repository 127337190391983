import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Flex, Form, Image, InputNumber, List, MenuProps, message, Typography } from "antd";
import { DealsContext } from "../../../../app/context/DealsContext";
import EmptyTable from "src/shared/images/manWithDashboard.webp";
import TextField from "src/shared/images/text-field.svg";
import _ from "lodash";
import PlayCardField from "src/shared/images/playing-cards.svg";
import InTheZone from "src/shared/images/in-the-zone.svg";
import Agreement from "src/shared/images/agreement_w6ua.svg";
import { DealCard } from "../DealCard";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { DotsMenuDropdown, Modal } from "../../../../ui";
import "./index.less";
import { DealPopUp } from "./components/DealPopUp";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import { searchForContact } from "src/store/outreach-campaigns/asyncRequests";
import { HTML5Backend } from "react-dnd-html5-backend";
import { EditColumnModal } from "../EditColumnModal";
import { DeleteColumnModal } from "../DeleteColumnModal";
import { DeleteDealModal } from "../DeleteDealModal";
import { outreachCampaignSelector, setDealToOpenId } from "src/store/outreach-campaigns";

export const ItemTypes = {
  CARD: "card",
};

export const Group = ({
  group,
  updateData,
  dealsGroup,
  fixDeals,
  idOfDragging,
  setIdOfDragging,
  groupIndex,
  idOfGroupDragging,
  setGroupIdOfDragging,
}: {
  group;
  updateData;
  dealsGroup;
  fixDeals;
  idOfDragging;
  setIdOfDragging: React.Dispatch<React.SetStateAction<string>>;
  groupIndex;
  idOfGroupDragging;
  setGroupIdOfDragging;
}) => {
  // const { setSelectedGroupId, openEditColumnModal, openDeleteColumnModal, openAddDealModal, dealsGroups, setDealsGroups } = useContext(DealsContext);

  const iconRef = useRef<HTMLDivElement>(null);
  const listItemRef = useRef<HTMLDivElement>(null);
  const [dealsArr, setDealsArr] = useState([]);
  const [{ isDragging, opacity }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id: group?.id, groupIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.8 : 1,
    }),
    // canDrag: () => idOfDragging?.length,
  });
  if (isDragging && group.id !== idOfGroupDragging) {
    setGroupIdOfDragging(group.id);
  }
  useEffect(() => {
    if (isDragging && group.id !== idOfGroupDragging) {
      setGroupIdOfDragging(group.id);
    }
    if (!isDragging) {
      setGroupIdOfDragging("");
    }
  }, [isDragging]);

  const moveGroup = (hov, groupId) => {
    // console.log(hoverInd, groupId, "hoverIndex");
    const groupCop = [...(dealsGroup || [])].sort((a, b) => a.order - b.order);
    // console.log(
    //   groupCop.findIndex((g) => g.id === groupId),
    //   "hoverIndexx",
    //   groupIndex,
    //   groupIndex === groupCop.findIndex((g) => g.id === groupId),
    //   groupCop
    // );
    if (groupIndex === groupCop.findIndex((g) => g.id === groupId) || groupIndex + 1 === groupCop.find((g) => g.id === groupId)?.order) {
      return;
    }
    groupCop.splice(
      groupIndex,
      0,
      groupCop.splice(
        groupCop.findIndex((g) => g.id === groupId),
        1
      )[0]
    );
    groupCop.forEach((grp, index) => {
      const groupCopy = { ...grp };
      groupCopy.order = index + 1;
      groupCop[index] = groupCopy;
    });
    fixDeals(groupCop);
  };
  const [lastHoverIndex, setLastHoverIndex] = useState<number | null>(null);
  const [lastGroupId, setLastGroupId] = useState<string | null>(null);

  const checkIfSameState = (hoverIndex, groupId) => {
    if (hoverIndex === lastHoverIndex && groupId === lastGroupId) {
      return true;
    } else {
      setLastHoverIndex(hoverIndex);
      setLastGroupId(groupId);
      return false;
    }
  };

  const [, drop] = useDrop({
    accept: "card",
    hover(item: any, monitor) {
      // if id of dragging a deal it will mix up columns because of column
      // console.log(idOfGroupDragging, "group.id", item?.id);
      if (!listItemRef.current || idOfGroupDragging !== item?.id) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = groupIndex;
      const groupId = item?.id;
      // console.log(
      //   hoverIndex === lastHoverIndex && groupId === lastGroupId,
      //   "hoverIndex === lastHoverIndex && groupId === lastGroupId",
      //   hoverIndex,
      //   lastHoverIndex,
      //   groupId,
      //   lastGroupId
      // );
      // if (checkIfSameState(hoverIndex, groupId)) {
      //   return;
      // }

      const isOver = monitor.isOver({ shallow: true });
      if (!isOver) {
        return;
      }

      moveGroup(hoverIndex, groupId);
    },
    drop(item: any, monitor) {
      // console.log(item, monitor);
      // const isOrderChanged = !currentData.every((item, index) => item === prevData[index]);
      // setGroupIdOfDragging("");
      // if (isOrderChanged && isEdit && !isDraft) {
      //   showChangeStepModal();
      // } else if (isOrderChanged && isDraft) {
      //   handleChangeStepConfirmed();
      // }
    },
  });
  // console.log(dealsGroup, "dealsArr");

  useEffect(() => {
    setDealsArr(group?.deals || []);
  }, [group?.deals]);

  const dispatch = useDispatch();
  const [dealToEdit, setDealToEdit] = useState(null);
  const [editColumn, setEditColumn] = useState(false);
  const [delColumn, setDelColumn] = useState(false);

  const { dealsToOpenId } = useSelector(outreachCampaignSelector);
  useEffect(() => {
    // only first group open the edit?
    if (dealsToOpenId) {
      const dl = dealsArr?.find((dl) => dl.id === dealsToOpenId);
      console.log(dl, dealsArr, "dealsToOpenId", dealsToOpenId);
      if (dl) {
        // setTimeout(() => {
        setDealToEdit(dl);
        dispatch(setDealToOpenId(null));
        // }, 1000);
      }
    }
  }, [dealsToOpenId, dealsArr]);
  useEffect(() => {
    console.log(dealToEdit, "dealsToOpenId!!");
  }, [dealToEdit]);
  // const [dealToEdit, setSelectedGroupId] = useState(null);

  const calculateTotalPrice = (deals: any[]) => {
    return deals.reduce((total: number, deal: any) => total + deal.price, 0);
  };

  const deleteColumn = () => {
    const groupCop = [...(dealsGroup || [])];
    const groupIndex = groupCop.findIndex((g) => g.id === group?.id);
    if (groupIndex !== -1) {
      groupCop.splice(groupIndex, 1);
    }

    // console.log(groupCop, "groupCop");
    fixDeals(groupCop);
    message.success("Deleted column");

    setEditColumn(false);
  };

  const editColumnTitle = (title) => {
    const groupCop = [...(dealsGroup || [])];
    const groupIndex = groupCop.findIndex((g) => g.id === group?.id);
    // if (groupIndex !== -1) {
    groupCop[groupIndex] = { ...groupCop[groupIndex], title };
    // console.log(groupCop, "groupCop");
    fixDeals(groupCop);
    // }
    // }

    message.success("Updated column name");
    setEditColumn(false);
  };
  const openEditColumnModal = () => {
    setEditColumn(true);
  };
  const openDeleteColumnModal = () => {
    setDelColumn(true);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      onClick: openEditColumnModal,
    },
    {
      key: "2",
      label: "Delete",
      onClick: openDeleteColumnModal,
    },
  ];

  // change all of this to a deal call
  // const debouncedSearch = () => {
  //   dispatch(searchForContact({ search }));
  // };

  const updateDeal = (deal) => {
    // console.log(deal, "updateDeal");
    const dealsUpd = dealsArr?.map((d) => (d.id === deal.id ? { ...deal } : d));

    // console.log(dealsUpd, "updateDeal");
    setDealsArr(dealsUpd || []);
    const groupCopy = { ...group };
    groupCopy.deals = dealsUpd;

    updateData(groupCopy, true);
  };

  // useEffect(() => {
  //   return () => {
  //     debouncedSearch.cancel(); // Cleanup for debounce
  //   };
  // }, [debouncedSearch]);

  // console.log(dealToEdit, "dealToEdit", !!dealToEdit);
  drag(iconRef);
  drop(listItemRef);

  const createNewDealAndAdd = () => {
    let newDeal;

    const copDealGroup = [...(dealsGroup || [])];

    const updatedGroups = copDealGroup.map((grp) => {
      if (grp.id === group.id) {
        newDeal = {
          id: uuidv4(),
          order: grp?.deals?.length + 1,
          title: "",
          descriptions: "",
          deal_activity: [],
          sales_pros_connected: [],
          estimated_money_value: 0,
          estimated_close_time_unix: null,
          how_good_is_it: 0,
        };
        return {
          ...grp,
          deals: [...grp.deals, newDeal],
        };
      }
      return grp;
    });
    setDealToEdit(newDeal);
    fixDeals(updatedGroups);
    message.success("Added deal");
  };
  const [dealId, setDealId] = useState(null);
  const openDeleteDealModal = (dealId) => {
    setDealId(dealId);
  };
  const closeAddDealModal = () => {
    setDealId(null);
  };
  const deleteThisDeal = () => {
    const groupCop = [...(dealsGroup || [])];
    const groupIndex = groupCop.findIndex((g) => g.id === group?.id);
    // if (groupIndex !== -1) {
    const updatedDeals = groupCop[groupIndex].deals.filter((deal: { id: string }) => deal.id !== dealId);
    groupCop[groupIndex] = { ...groupCop[groupIndex], deals: updatedDeals };
    // }
    fixDeals(groupCop);
    message.success("Deleted deal");
    closeAddDealModal();
  };
  return (
    <>
      {!!dealId && <DeleteDealModal deleteThisDeal={deleteThisDeal} onClose={closeAddDealModal} />}
      {!!delColumn && (
        <DeleteColumnModal onClose={() => setDelColumn(false)} currentGroup={group} editColumnTitle={editColumnTitle} deleteColumn={deleteColumn} />
      )}
      {!!editColumn && <EditColumnModal onClose={() => setEditColumn(false)} currentGroup={group} editColumnTitle={editColumnTitle} />}
      {/* <Modal title="Deal" onCancel={() => setDealToEdit(null)} footer={null} open={!!dealToEdit}>
        dsf
      </Modal> */}
      {!!dealToEdit && <DealPopUp dealToEdit={dealToEdit} onCancel={() => setDealToEdit(null)} updateDeal={updateDeal} />}
      <div style={{ marginLeft: groupIndex ? "0" : "10px", marginTop: "10px" }}>
        {/* <CustomDragLayer /> */}
        <Flex
          vertical={true}
          className={"group"}
          key={group?.id}
          style={{
            boxShadow:
              idOfGroupDragging === group.id
                ? "0 0 5px 2px rgba(173, 216, 230, 0.8), 0 0 10px 3px rgba(173, 216, 230, 0.6), 0 0 3px 1px rgba(173, 216, 230, 0.4)"
                : "0 0 5px rgba(211, 211, 211, 0.5)",

            // transform: idOfGroupDragging === group.id ? "scale(1.025)" : "none",
            transition: "transform 0.2s, box-shadow 0.2s",
          }}
          ref={iconRef}
        >
          {" "}
          {/* <DndProvider backend={HTML5Backend}> */}
          {/* <CustomDragLayer /> */}
          <div
            style={{
              // width: "100%",
              cursor: "pointer",
              // position: "absolute",
              // opacity,

              position: idOfGroupDragging === group.id ? "relative" : "static",
              // backgroundColor: "red",
              height: "70px",
              width: "300px",
              marginBottom: "-60px",
              zIndex: 1000,
            }}
            key={group?.id}
            ref={listItemRef}
          >
            <div />
          </div>
          {/* </DndProvider> */}
          <Flex className={"group-header"} justify={"space-between"}>
            <Flex vertical={true} gap={8}>
              <Typography.Title level={2} className={"group-header-title"}>
                <span className={"title"}> {group?.title} </span>
              </Typography.Title>
              <Typography.Paragraph className={"group-header-total-price"}>
                ${group?.totalPrice?.toLocaleString()}
                <span style={{ marginLeft: "4px" }}>
                  - {dealsArr.length} {dealsArr.length === 1 ? "Deal" : "Deals"}
                </span>
              </Typography.Paragraph>
            </Flex>
            <div
              style={{
                zIndex: 1000,
                height: "fit-content",
              }}
            >
              <DotsMenuDropdown
                menuItems={items}
                onDropdownClick={() => {
                  // message.info("sdofian");
                  // setSelectedGroupId(group?.id);
                }}
                style={{
                  zIndex: 2000,
                  // height: "fit-content",
                }}
              />
            </div>
          </Flex>
          {dealsArr.length > 0 ? (
            <List
              dataSource={dealsArr || []}
              renderItem={(item: any, index: number) => (
                <List.Item key={item?.id}>
                  <DealCard
                    deal={item}
                    setDealToEdit={setDealToEdit}
                    index={index}
                    groupId={group.id}
                    dealsGroup={dealsGroup}
                    fixDeals={fixDeals}
                    idOfDragging={idOfDragging}
                    setIdOfDragging={setIdOfDragging}
                    openDeleteDealModal={openDeleteDealModal}
                  />
                </List.Item>
              )}
              className={"deals-list"}
            />
          ) : (
            <Flex justify="center" align="center" className="no-deals-placeholder" vertical={true}>
              {" "}
              <div>
                <br />
                <Image
                  src={[TextField, PlayCardField, InTheZone, Agreement][group?.order % 4]}
                  // alt={"Man with a dashboard"}
                  preview={false}
                  width={173}
                  height={150}
                />
              </div>
              <br />
              <br />
              <Typography.Paragraph type="secondary" italic>
                No deals here.
              </Typography.Paragraph>
              <br />
              <br />
            </Flex>
          )}
          {!!dealsArr.length ? (
            <div
              style={{
                width: "300px",
                height: "30px",
                // backgroundColor: "red",
              }}
            >
              <DealCard
                deal={{}}
                dropSpace
                index={dealsArr.length || 0}
                setDealToEdit={setDealToEdit}
                dealsGroup={dealsGroup}
                groupId={group.id}
                fixDeals={fixDeals}
                idOfDragging={idOfDragging}
                setIdOfDragging={setIdOfDragging}
                openDeleteDealModal={openDeleteDealModal}
              />
            </div>
          ) : (
            // <div
            //   style={{
            //     position: "relative",
            //     // top: "100px",
            //     // width: "300px",
            //     // height: "350px",
            //     backgroundColor: "green",
            //     overflow: "hidden",
            //   }}
            // >
            <div
              style={{
                position: "relative",
                marginTop: "-300px",
                width: "300px",
                height: "330px",
                // backgroundColor: "red",
                // zIndex: 4000,
              }}
            >
              <DealCard
                deal={{}}
                dropSpace
                index={dealsArr.length || 0}
                setDealToEdit={setDealToEdit}
                dealsGroup={dealsGroup}
                groupId={group.id}
                fixDeals={fixDeals}
                idOfDragging={idOfDragging}
                setIdOfDragging={setIdOfDragging}
                openDeleteDealModal={openDeleteDealModal}
              />
            </div>
            // </div>
          )}
          <Button className={"link"} onClick={createNewDealAndAdd}>
            Add Deal
          </Button>
        </Flex>
      </div>
    </>
  );
};
